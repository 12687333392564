import { CoachProfile, FlexBox } from "@joonapp/web-shared"
import { GroupChannel } from "@sendbird/uikit-react/GroupChannel"
import { useCallback, useEffect } from "react"

import useCoachStore from "./useCoachStore"
import { useFamilyQuery } from "../../networking/queries"
import { FamilyQuerySelect } from "../../types"
import useCareTeam from "../care/useCareTeam"

const CoachSB = () => {
  const { setIsFocused } = useCoachStore()
  const { isLoading: isLoadingChildren } = useFamilyQuery(
    FamilyQuerySelect.CHILDREN
  )
  const { selectedProfile } = useCareTeam()
  const { sendbird_channel_url } = (selectedProfile as CoachProfile) || {}

  const inputFieldId = "#sendbird-message-input-text-field"

  const updateFocusState = useCallback(() => {
    const inputField = document.querySelector(inputFieldId)
    setIsFocused(
      inputField instanceof HTMLElement && document.activeElement === inputField
    )
  }, [setIsFocused])

  useEffect(() => {
    let retryCount = 0
    const maxRetries = 5
    const setupListeners = () => {
      const inputField = document.querySelector(inputFieldId)
      if (!inputField) {
        if (retryCount < maxRetries) {
          retryCount++
          setTimeout(setupListeners, 500)
        }
        return
      }
      const handleFocus = () => setIsFocused(true)
      const handleBlur = (e: Event) => {
        const focusEvent = e as FocusEvent
        const relatedTarget = focusEvent.relatedTarget as HTMLElement
        const isActionButton = relatedTarget?.closest(
          '[data-testid="sendbird-message-input-send-button"]'
        )
        if (!isActionButton) {
          setIsFocused(false)
        }
      }
      inputField.addEventListener("focus", handleFocus)
      inputField.addEventListener("blur", handleBlur)
      updateFocusState()
      return () => {
        inputField.removeEventListener("focus", handleFocus)
        inputField.removeEventListener("blur", handleBlur)
      }
    }

    const timeoutId = setTimeout(setupListeners, 100)
    return () => clearTimeout(timeoutId)
  }, [updateFocusState, setIsFocused])

  const scrollToBottom = useCallback(() => {
    const messageContainer = document.querySelector(
      ".sendbird-conversation__messages"
    )
    if (messageContainer) {
      messageContainer.scrollTop = messageContainer.scrollHeight
    }
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(scrollToBottom, 300)
    return () => clearTimeout(timeoutId)
  }, [scrollToBottom, selectedProfile])

  return (
    <FlexBox
      direction="column"
      align="center"
      wrap={false}
      fullWidth
      style={{
        overflow: "hidden",
        opacity: isLoadingChildren ? 0 : 1,
        pointerEvents: isLoadingChildren ? "none" : "auto",
      }}
    >
      <GroupChannel
        key={sendbird_channel_url}
        channelUrl={sendbird_channel_url || ""}
        disableUserProfile={true}
        renderChannelHeader={() => <></>}
        onBeforeSendUserMessage={(e) => {
          setTimeout(scrollToBottom, 100)
          return e
        }}
      />
    </FlexBox>
  )
}

export default CoachSB
