import { createJoonAPIClient } from "./joon-api"

export const getFamilyStripeCheckoutLink = async ({
  familyId,
  campaign,
}: {
  familyId: number
  campaign: string | undefined
}) => {
  const API = createJoonAPIClient()

  const session = await API.get<{ checkout_url: string; has_trial: boolean }>(
    `api/families/${familyId}/checkout/`,
    {
      params: {
        campaign,
        success_url: `${window.location.origin}/me?paid=true`,
        cancel_url: `${window.location.origin}/me?cancelledPayment=true`,
      },
    }
  )

  return session.data
}

export const getFamilyBillingLink = async ({
  familyId,
}: {
  familyId: number
}) => {
  const API = createJoonAPIClient()
  const session = await API.get(`api/families/${familyId}/billing/`, {
    params: { return_url: `${window.location.origin}/` },
  })
  return session.data.billing_url
}
