import {
  Button,
  CalloutBox,
  FlexBox,
  JoonUIColor,
  SPACING,
  Shadow,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import useMobile from "../../hooks/useMobile"
import useOnboardContext, {
  useAccountCreation,
} from "../../hooks/useOnboardContext"
import { signup } from "../../networking/authentication"
import { AlertInterface } from "../../types"
import { localStorageItems } from "../../util/storage"
import {
  createErrorFromSignupResponse,
  isUsingInAppBrowser,
} from "../../util/util"
import GoogleAuth from "../auth/GoogleAuth"
import BackButton from "../buttons/BackButton"

function SignupSection() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [pending, setPending] = useState(false)
  const [formAlert, setFormAlert] = useState<AlertInterface | null>(null)
  const { previousStep } = useOnboardContext()

  const isMobile = useMobile()

  const { uploadOnboardingSecondaryData } = useAccountCreation()

  const redirectLink = localStorage.getItem(localStorageItems.redirectLink)
  const isAllspireCampaign = redirectLink?.includes("Allspire")

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setPending(true)
    try {
      await signup(email, password)
      uploadOnboardingSecondaryData()
    } catch (err: any) {
      const errorMessage = createErrorFromSignupResponse(err)
      if (errorMessage) setFormAlert({ type: "error", message: errorMessage })
    } finally {
      setPending(false)
    }
  }

  return (
    <>
      <BackButton onBack={previousStep} />

      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space2}
        style={{
          textAlign: "center",
          marginBottom: SPACING.space4,
        }}
      >
        <Typography variant="h2" style={{ marginTop: SPACING.space2 }}>
          {localStorage.getItem(localStorageItems.therapistCode)
            ? "Create an account to link to your therapist!"
            : "Save Your Result"}
        </Typography>
        <FlexBox
          style={{
            marginTop: SPACING.space2,
            padding: `${SPACING.space2} ${SPACING.space3}`,
            borderRadius: SPACING.space2,
            boxShadow: Shadow.high,
            border: `1px solid ${JoonUIColor.border.default}`,
            marginBottom: SPACING.space2,
            background: "white",
          }}
          align="center"
          justify="center"
        >
          <FlexBox gap={SPACING.space1} align="center" justify="center">
            <img
              src="/images/icons/review-stars.svg"
              alt="placeholder"
              style={{
                height: "16px",
                marginRight: "5px",
                marginBottom: "2px",
              }}
            />
            <Typography
              variant="bodyXSmall"
              style={{ fontWeight: "bold" }}
              color={JoonUIColor.text.secondary}
            >
              4.7 stars
            </Typography>
          </FlexBox>
          <Typography
            variant={isMobile ? "bodySmall" : "body"}
            color={JoonUIColor.text.primaryAccent}
          >
            Loved by 1 million+ parents and kids
          </Typography>
          {!localStorage.getItem(localStorageItems.therapistCode) && (
            <Typography
              variant={isMobile ? "caption" : "bodyBold"}
              color={JoonUIColor.text.primary}
              style={{ lineHeight: "18px" }}
            >
              Create your account to see your plan!
            </Typography>
          )}
        </FlexBox>
      </FlexBox>
      {formAlert && (
        <FlexBox style={{ margin: `${SPACING.space2} 0` }}>
          <CalloutBox type="error" fullWidth>
            <Typography variant="bodySmall">{formAlert.message}</Typography>
          </CalloutBox>
        </FlexBox>
      )}

      <form onSubmit={handleSignUp}>
        <FlexBox direction="column" gap={SPACING.space1}>
          <TextInput
            type="email"
            icon="email"
            name="email"
            placeholder="Email"
            fullWidth={true}
            onChange={(e: any) => setEmail(e.target.value)}
            value={email}
          />
        </FlexBox>
        <FlexBox
          direction="column"
          gap={SPACING.space1}
          style={{ marginTop: SPACING.space2 }}
        >
          <TextInput
            icon="password"
            type="password"
            placeholder="Password"
            name="pass"
            fullWidth={true}
            onChange={(e: any) => setPassword(e.target.value)}
            value={password}
          />
        </FlexBox>
        <FlexBox
          wrap={false}
          align="center"
          gap={SPACING.space1}
          style={{ marginTop: SPACING.space2 }}
        >
          <Typography variant="bodyXSmall" style={{ margin: "0px" }}>
            By continuing, you agree to Joon's{" "}
            <a
              href="https://www.joonapp.io/terms-of-service"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              Terms of Service
            </a>{" "}
            {isAllspireCampaign ? ", " : "and "}
            <a
              href="https://www.joonapp.io/privacy-policy"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              Privacy Policy
            </a>{" "}
            {isAllspireCampaign && (
              <>
                and{" "}
                <a
                  href="https://www.joonapp.io/gpo-acknowledgement"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  GPO agreement
                </a>
              </>
            )}
          </Typography>
        </FlexBox>
        <Button
          isLoading={pending}
          disabled={pending}
          type="submit"
          text="Sign Up"
          fullWidth
          style={{ marginTop: SPACING.space6 }}
        />
      </form>
      {!isUsingInAppBrowser && (
        <>
          <Typography
            variant="bodySmall"
            textAlign="center"
            style={{ margin: `${SPACING.space4} 0` }}
          >
            OR
          </Typography>
          <GoogleAuth type="signup" />
        </>
      )}
    </>
  )
}

export default SignupSection
