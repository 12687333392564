import { SPACING, FlexBox, Typography, JoonUIColor } from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useEffect, useMemo } from "react"

import { useSessionBookingModalStore } from "./hooks/useSessionBookingModalStore"
import SessionBookingModal from "./SessionBookingModal"
import SessionSlot from "./SessionSlot"
import PageLoader from "../../../components/loading/PageLoader"
import {
  useSessionBillingPeriodsQuery,
  useSessionBookingsQuery,
} from "../../../networking/sessions/queries"
import { localStorageItems } from "../../../util/storage"
import NoSessionsInPlanScreen from "../NoSessionsInPlanScreen"

const SessionsScreen = () => {
  const {
    data: billingPeriods,
    isLoading: isLoadingBillingPeriods,
    refetch: refetchBillingPeriods,
  } = useSessionBillingPeriodsQuery()
  const { data: bookings, isLoading: isLoadingBookings } =
    useSessionBookingsQuery()
  const { isOpen } = useSessionBookingModalStore()

  // When the user comes back to the app, check if they need to refresh the bookings
  useEffect(() => {
    if (isOpen) return
    if (!!localStorage.getItem(localStorageItems.needsToRefreshBookings)) {
      localStorage.removeItem(localStorageItems.needsToRefreshBookings)
      refetchBillingPeriods()
    }
  }, [isOpen, refetchBillingPeriods])

  const pastSessions = useMemo(() => {
    if (!billingPeriods || !bookings) return []
    const currentPeriodsSessionIds = billingPeriods?.flatMap((period) =>
      period.bookings?.map((booking) => booking.id)
    )
    const otherSessions = bookings?.filter(
      (booking) =>
        !currentPeriodsSessionIds?.includes(booking.id) &&
        dayjs(booking.end_datetime).isBefore(dayjs())
    )
    return otherSessions
  }, [billingPeriods, bookings])

  if (isLoadingBillingPeriods || isLoadingBookings) return <PageLoader />

  if (billingPeriods?.length === 0 || bookings?.length === 0)
    return <NoSessionsInPlanScreen />

  return (
    <FlexBox direction="column" gap={SPACING.space4}>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ padding: SPACING.space6 }}
      >
        {billingPeriods?.map((period, i) => (
          <FlexBox direction="column" gap={SPACING.space2} key={i}>
            <Typography variant="caption" color={JoonUIColor.text.secondary}>
              {i === 0 ? "CURRENT CYCLE" : "UPCOMING CYCLE"} (
              {dayjs(period.start_datetime).format("MMM D")}
              {" - "}
              {dayjs(period.end_datetime).format("MMM D")})
            </Typography>
            <FlexBox direction="row" gap={SPACING.space2}>
              {new Array(period.max_bookings).fill(0).map((_, i) => (
                <SessionSlot
                  session={period.bookings?.[i]}
                  key={i}
                  billingPeriod={period}
                />
              ))}
            </FlexBox>
          </FlexBox>
        ))}
        {pastSessions?.length && pastSessions?.length > 0 ? (
          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="caption" color={JoonUIColor.text.secondary}>
              PAST SESSIONS
            </Typography>
            {pastSessions?.map((session) => (
              <SessionSlot session={session} key={session.id} />
            ))}
          </FlexBox>
        ) : null}
      </FlexBox>
      <SessionBookingModal />
    </FlexBox>
  )
}

export default SessionsScreen
