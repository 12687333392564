import { useMutation, useQueryClient } from "@tanstack/react-query"

import { QUERY_KEYS } from "../../constants"
import { deleteQuestTemplate } from "../quests"

export const useDeleteQuestTemplateMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteQuestTemplate,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.QUEST_TEMPLATES],
      })
    },
  })
}
