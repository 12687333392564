import {
  Button,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Modal,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import { create } from "zustand"

import { useRewardsChildToggleHeaderStore } from "./RewardsChildToggleHeader"
import { QUERY_KEYS } from "../../../constants"
import {
  useFamilyQuery,
  usePurchasedRewardsQuery,
} from "../../../networking/queries"
import { redeemCustomReward } from "../../../networking/rewards"
import { FamilyQuerySelect } from "../../../types"
import { RewardPurchase } from "../../../types/rewards"
import { trackAnalyticEvent } from "../../../util/analytics"
import { ANALYTIC_EVENTS } from "../../../util/analytics"
import { queryClient } from "../../../util/queryClient"

interface RewardQuantityModalStore {
  isOpen: boolean
  onOpen: (rewards: RewardPurchase[]) => void
  onClose: () => void
  rewards: RewardPurchase[]
  quantity: number
  setQuantity: (quantity: number) => void
}

export const useRewardQuantityModal = create<RewardQuantityModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (rewards: RewardPurchase[]) =>
      set({ isOpen: true, rewards, quantity: 1 }),
    onClose: () => set({ isOpen: false }),
    rewards: [],
    quantity: 1,
    setQuantity: (quantity: number) => set({ quantity }),
  })
)

export default function RewardQuantityModal() {
  const { isOpen, onClose, rewards, quantity, setQuantity } =
    useRewardQuantityModal()
  const { selectedChildId } = useRewardsChildToggleHeaderStore()
  const { isLoading: isPurchasedRewardsLoading } = usePurchasedRewardsQuery({
    hasRedeemed: false,
  })
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const selectedChild = children?.find(
    (child) => child.user.id === selectedChildId
  )

  const plusDisabled = rewards.length === 0 || quantity >= rewards.length
  const minusDisabled = quantity <= 1

  const displayName = selectedChild?.nickname || selectedChild?.user.name

  const redeemRewardMutation = useMutation({
    mutationFn: redeemCustomReward,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PURCHASED_REWARDS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_LOGS])
    },
  })

  const onRedeemClick = async () => {
    const rewardsToRedeem = rewards.slice(-quantity)
    await Promise.all(
      rewardsToRedeem.map((reward) => {
        trackAnalyticEvent(ANALYTIC_EVENTS.REDEEM_CUSTOM_REWARD)
        return redeemRewardMutation.mutateAsync(reward.id)
      })
    )
    toast.success(`${quantity} rewards redeemed successfully`)
    onClose()
  }

  if (rewards.length === 0) return null

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      displayCloseIcon
      animate
      style={{
        width: "min(100%, 450px)",
        height: "350px",
        padding: `${SPACING.space4} ${SPACING.space6}`,
      }}
    >
      <FlexBox
        direction="column"
        align="flex-start"
        justify="space-between"
        style={{ height: "100%" }}
      >
        <FlexBox
          direction="column"
          align="flex-start"
          justify="flex-start"
          gap={SPACING.space2}
          wrap={false}
        >
          <Typography variant="bodyBold">
            {`How many of "${rewards[0].reward.title}" would you like to redeem for ${displayName}?`}
          </Typography>
          <FlexBox
            direction="row"
            justify="space-between"
            align="center"
            gap={SPACING.space1}
            wrap={false}
            style={{
              width: "40%",
              padding: SPACING.space2,
              borderRadius: SPACING.space2,
              border: `1px solid ${JoonUIColor.border.default}`,
            }}
          >
            <TextButton
              disabled={minusDisabled}
              onClick={() => setQuantity(quantity - 1)}
              style={{ pointerEvents: minusDisabled ? "none" : undefined }}
            >
              <Typography
                variant="h3"
                style={{
                  color: minusDisabled
                    ? JoonColorExpanded.neutral300
                    : JoonUIColor.text.primary,
                }}
              >
                -
              </Typography>
            </TextButton>
            <FlexBox
              direction="column"
              align="center"
              justify="center"
              style={{
                width: "50%",
                backgroundColor: JoonUIColor.background.lightGray,
                padding: `${SPACING.space2} ${SPACING.space4}`,
                borderRadius: SPACING.space2,
              }}
            >
              <Typography variant="bodyBold">{quantity}</Typography>
            </FlexBox>
            <TextButton
              disabled={plusDisabled}
              onClick={() => setQuantity(quantity + 1)}
              style={{ pointerEvents: plusDisabled ? "none" : undefined }}
            >
              <Typography
                variant="h3"
                color={
                  plusDisabled
                    ? JoonColorExpanded.neutral300
                    : JoonUIColor.text.primary
                }
              >
                +
              </Typography>
            </TextButton>
          </FlexBox>
        </FlexBox>
        <Button
          buttonType="primary"
          onClick={onRedeemClick}
          text="Redeem"
          fullWidth
          isLoading={
            redeemRewardMutation.isLoading || isPurchasedRewardsLoading
          }
        />
      </FlexBox>
    </Modal>
  )
}
