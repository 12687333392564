import { useCallback } from "react"
import TiktokPixel from "tiktok-pixel"

import {
  useFamilyQuery,
  useUserNetworkInfo,
  useUserQuery,
} from "../networking/queries"
import {
  FacebookPixelEvent,
  PinterestPixelEvent,
  TiktokPixelEvent,
} from "../util/analytics"

const isProduction = import.meta.env.MODE === "production"

export const usePixelEvent = () => {
  const { user } = useUserQuery()
  const { data: family } = useFamilyQuery()
  const { data: networkInfo } = useUserNetworkInfo()

  const trackFBPixelEvent = useCallback(
    (
      eventName: FacebookPixelEvent,
      eventParams: { [param: string]: any } = {}
    ) => {
      if (!isProduction) return

      // @ts-ignore - fbq is window event defined in index.html
      fbq(
        "init",
        import.meta.env.VITE_FB_PIXEL_ID as string,
        user
          ? { em: user?.email, country: networkInfo?.country_code ?? "" }
          : { country: networkInfo?.country_code ?? "" }
      )

      // @ts-ignore - fbq is window event defined in index.html
      fbq(
        "track",
        eventName,
        eventParams,
        family ? { eventID: family?.legacy_family_id ?? family?.id } : undefined
      )
    },
    [user, family] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const trackTikTokPixelEvent = useCallback(
    (
      eventName: TiktokPixelEvent,
      eventParams: { [param: string]: any } = {}
    ) => {
      if (!isProduction) return
      const advancedMatching = { email: user?.email }
      const options = { debug: false }
      TiktokPixel.init("CA2GLQRC77UB6IBI0QA0", advancedMatching, options)

      const trackingOptions = {
        event_id: family
          ? `${family?.legacy_family_id ?? family?.id}`
          : undefined,
      }

      TiktokPixel.track(eventName, eventParams, trackingOptions)
    },
    [user, family]
  )

  const trackPinterestEvent = useCallback(
    (
      eventName: PinterestPixelEvent,
      eventParams: { [param: string]: any } = {}
    ) => {
      if (!isProduction) return

      // @ts-ignore - pintrk is window event defined in index.html
      if (window.pintrk) {
        setTimeout(() => {
          // Add a delay to allow the pintrk library to initialize
          // @ts-ignore - pintrk is window event defined in index.html
          window.pintrk("track", eventName, {
            em: user?.email,
            ...eventParams,
          })
        }, 300)
      } else {
        console.error("Pinterest tracker not initialized")
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return { trackFBPixelEvent, trackTikTokPixelEvent, trackPinterestEvent }
}
