import { ChevronRightIcon } from "@joonapp/web-shared"
import { JoonColorExpanded, JoonUIColor } from "@joonapp/web-shared"
import { SPACING, Typography } from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"
import { useState } from "react"

import useQuestBoardStore from "./useQuestBoardStore"
import Shine from "../../components/coaching/Shine"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { GRADIENTS } from "../../constants"
import useMobile from "../../hooks/useMobile"
import { localStorageItems } from "../../util/storage"

const WelcomeQuests = () => {
  const [isQuestsPreviewLoaded, setIsQuestsPreviewLoaded] = useState(false)
  const [isChildAppPreviewLoaded, setIsChildAppPreviewLoaded] = useState(false)
  const { setShowWelcome } = useQuestBoardStore()
  const isMobile = useMobile()

  const onAddQuestClick = () => {
    localStorage.removeItem(localStorageItems.needsToSeeWelcomeQuests)
    setShowWelcome(false)
  }

  return (
    <PageWrapper style={{ background: GRADIENTS.gradient100 }}>
      <PageContentWrapper mobileGutter>
        <FlexBox
          direction="column"
          align="center"
          gap={SPACING.space6}
          style={{
            marginTop: SPACING.space9,
            paddingLeft: SPACING.space2,
            paddingRight: SPACING.space2,
          }}
        >
          <Typography variant="h2" textAlign="center">
            Welcome to Joon Quests!
          </Typography>
          <FlexBox
            direction="column"
            align="center"
            gap={SPACING.space4}
            wrap={false}
            style={{
              width: "min(100%, 500px)",
              borderRadius: SPACING.space2,
              padding: SPACING.space4,
              background: JoonUIColor.background.primaryNeutral,
            }}
          >
            <FlexBox direction="column" wrap={false}>
              <Typography
                variant={isMobile ? "bodyXSmall" : "caption"}
                textAlign="center"
                style={{ fontWeight: 700 }}
              >
                For parents:
              </Typography>
              <Typography
                variant={isMobile ? "caption" : "bodyBold"}
                textAlign="center"
                style={{ whiteSpace: "pre-line" }}
              >
                Easily manage your child's routine
              </Typography>
            </FlexBox>
            <img
              src="/images/previews/quests_preview.svg"
              alt=""
              onLoad={() => setIsQuestsPreviewLoaded(true)}
              style={{
                width: "max(200px, 60%)",
                opacity: isQuestsPreviewLoaded ? 1 : 0,
                transition: "opacity 0.3s",
              }}
            />
            <FlexBox direction="column" wrap={false}>
              <Typography
                variant={isMobile ? "bodyXSmall" : "caption"}
                textAlign="center"
                style={{ fontWeight: 700 }}
              >
                For your children:
              </Typography>
              <Typography
                variant={isMobile ? "caption" : "bodyBold"}
                textAlign="center"
                style={{ whiteSpace: "pre-line" }}
              >
                {`A fun and safe way to stay accountable with${isMobile ? " " : "\n"}their routines in the Joon Kid App game!`}
              </Typography>
            </FlexBox>
            <img
              src="/images/previews/child_app_preview.svg"
              alt=""
              onLoad={() => setIsChildAppPreviewLoaded(true)}
              style={{
                width: "max(250px, 88%)",
                opacity: isChildAppPreviewLoaded ? 1 : 0,
                transition: "opacity 0.3s",
              }}
            />
            <button
              style={{
                position: "sticky",
                bottom: isMobile ? "80px" : "12px",
                width: "min(96%, 400px)",
                marginTop: SPACING.space4,
              }}
              onClick={onAddQuestClick}
            >
              <FlexBox
                direction="row"
                align="center"
                justify="space-between"
                wrap={false}
                style={{
                  position: "relative",
                  background: JoonColorExpanded.indigo400,
                  borderRadius: SPACING.space2,
                  padding: `${SPACING.space2} ${SPACING.space1} ${SPACING.space2} ${SPACING.space3}`,
                  boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.1)",
                  overflow: "hidden",
                }}
              >
                <Typography
                  variant={isMobile ? "caption" : "bodyBold"}
                  color={JoonUIColor.text.inverted}
                  style={{ lineHeight: "1.4", whiteSpace: "nowrap" }}
                >
                  Add a Quest to get started
                </Typography>

                <div style={{ minWidth: "30px" }}>
                  <ChevronRightIcon
                    color={JoonUIColor.icon.inverted}
                    size={24}
                  />
                </div>
                <Shine />
              </FlexBox>
            </button>
          </FlexBox>
        </FlexBox>
      </PageContentWrapper>
    </PageWrapper>
  )
}

export default WelcomeQuests
