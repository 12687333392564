import {
  FlexBox,
  Modal,
  ModalContent,
  ModalHeader,
  SPACING,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useEffect } from "react"
import { useState } from "react"
import { create } from "zustand"

import { useRewardsChildToggleHeaderStore } from "./RewardsChildToggleHeader"
import Card from "../../../components/card/Card"
import { usePurchasedRewardsQuery } from "../../../networking/queries"
import { RewardPurchase } from "../../../types/rewards"
import { useRouter } from "../../../util/router"

interface RedeemedRewardsModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useRedeemedRewardsModal = create<RedeemedRewardsModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

const RedeemedRewardsModal = () => {
  const [childRedeemedRewards, setChildRedeemedRewards] = useState<
    RewardPurchase[]
  >([])
  const { selectedChildId } = useRewardsChildToggleHeaderStore()
  const { isOpen, onClose } = useRedeemedRewardsModal()
  const { data: redeemedRewards } = usePurchasedRewardsQuery({
    hasRedeemed: true,
  })
  const router = useRouter()

  useEffect(() => {
    const redeemedRewardsFiltered = () => {
      if (selectedChildId === null || redeemedRewards === undefined) return []
      return redeemedRewards.filter(
        (redeemedReward) => redeemedReward.user_id === selectedChildId
      )
    }

    setChildRedeemedRewards(redeemedRewardsFiltered())
  }, [selectedChildId, redeemedRewards])

  return (
    <Modal isOpen={isOpen} onClose={onClose} animate>
      <ModalHeader title="Redeemed Rewards" onClose={onClose} />
      <ModalContent
        style={{
          padding: `${SPACING.space0} ${SPACING.space6} ${SPACING.space6} ${SPACING.space6}`,
          width: "min(500px, 95vw)",
        }}
      >
        <FlexBox direction="column" gap={SPACING.space1}>
          {childRedeemedRewards?.map((redeemedReward) => (
            <Card
              title={redeemedReward.reward.title}
              subtitle={`Redeemed on ${dayjs(redeemedReward.redeem_date).format("MMM D, YYYY")}`}
              coinAmount={redeemedReward.reward.cost}
              key={redeemedReward.id}
              childIds={[redeemedReward.user_id]}
              onClick={() => {
                onClose()
                router.push(`/rewards/details/${redeemedReward.reward.id}`)
              }}
            />
          ))}
        </FlexBox>
      </ModalContent>
    </Modal>
  )
}

export default RedeemedRewardsModal
