import {
  Button,
  CalloutBox,
  ChildUser,
  Checkbox,
  Modal,
  Family,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useMemo, useState } from "react"
import { create } from "zustand"

import therapistDisclaimer from "./therapistDisclaimer"
import { QUERY_KEYS } from "../../../constants"
import { useFamilyQuery } from "../../../networking/queries"
import { TherapistProfileWithPatients } from "../../../types"
import { createJoonAPIClient } from "../../../util/joon-api"
import { getUserFromFamily } from "../../../util/util"

interface ManageTherapistModalStore {
  isOpen: boolean
  onOpen: (therapist: TherapistProfileWithPatients) => void
  onClose: () => void
  therapist: TherapistProfileWithPatients | null
}

export const useManageTherapistModal = create<ManageTherapistModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (therapist) => set({ isOpen: true, therapist }),
    onClose: () => set({ isOpen: false, therapist: null }),
    therapist: null,
  })
)

export const ManageTherapistModal = () => {
  const { isOpen, onClose, therapist } = useManageTherapistModal()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedChildren, setSelectedChildren] = useState<number[]>([])
  const { data: family } = useFamilyQuery()
  const queryClient = useQueryClient()

  const children = useMemo(
    () =>
      therapist?.patients.map((patient) =>
        getUserFromFamily(family as Family, Number(patient))
      ) as ChildUser[],
    [therapist, family]
  )

  const unselectedChildren = useMemo(
    () =>
      children?.filter(
        (child) => !selectedChildren.includes(child?.id as number)
      ),
    [children, selectedChildren]
  )

  const allChildrenAreUnselected = useMemo(
    () => unselectedChildren?.length === children?.length,
    [unselectedChildren, children]
  )

  const selectChild = (id: number) => {
    if (selectedChildren.includes(id))
      setSelectedChildren(selectedChildren.filter((childId) => childId !== id))
    else setSelectedChildren([...selectedChildren, id])
  }

  // automatically have all children selected
  useEffect(() => {
    setSelectedChildren(children?.map((child) => child?.id) as number[])
  }, [children])

  const unlinkChildren = async () => {
    const API = createJoonAPIClient()
    const unlinkChildrenPromises = unselectedChildren.map((child) =>
      API.post(`api/users/${child?.id}/unlink-therapist/`, {
        therapist_id: therapist?.user.id,
      })
    )
    try {
      setIsLoading(true)
      await Promise.all(unlinkChildrenPromises)
      onClose()
      queryClient.invalidateQueries([QUERY_KEYS.THERAPISTS])
      queryClient.invalidateQueries([QUERY_KEYS.CARE_TEAM])
    } catch (error) {
      throw new Error("Failed to unlink children")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      <div className="link-therapist-modal">
        <div className="modal-title">Manage Therapist</div>
        <div className="modal-body">
          <div>
            <div className="font-body mb-8">Currently linked with:</div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {children?.map((child, i) => (
                <Checkbox
                  name="test"
                  key={i}
                  label={child?.name}
                  onChange={() => selectChild(child?.id)}
                  selected={selectedChildren.includes(child.id)}
                />
              ))}
            </div>
          </div>
          {unselectedChildren?.length > 0 ? (
            <CalloutBox fullWidth type="error">
              <div className="font-footnote">
                This action will remove {therapist?.user.name}’s access to{" "}
                {unselectedChildren?.map((child) => child.name).join("'s and ")}
                's data
                {allChildrenAreUnselected
                  ? `, and remove ${therapist?.user.name} from your therapist list`
                  : ""}
                .
              </div>
            </CalloutBox>
          ) : (
            <CalloutBox fullWidth>{therapistDisclaimer}</CalloutBox>
          )}
        </div>
        {unselectedChildren?.length > 0 && (
          <div style={{ display: "flex", gap: "8px" }}>
            <Button
              text="Cancel"
              buttonType="secondary"
              style={{ flex: 0.3 }}
              onClick={onClose}
            />
            <Button
              style={{ flex: 0.7 }}
              text={
                allChildrenAreUnselected ? "Remove access" : "Confirm changes"
              }
              isLoading={isLoading}
              onClick={unlinkChildren}
              buttonType={allChildrenAreUnselected ? "redPrimary" : "primary"}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}
