import { SessionBillingPeriod } from "@joonapp/web-shared"
import dayjs from "dayjs"

import { useSessionBookingModalStore } from "./useSessionBookingModalStore"
import { SessionBookingLinkType } from "../../../../types/sessions"
import { SessionBooking } from "../../../../types/sessions"
import { localStorageItems } from "../../../../util/storage"

const useSession = (session: SessionBooking | undefined) => {
  const { onOpen } = useSessionBookingModalStore()

  const handleReschedule = () => {
    if (!session?.reschedule_link) return
    onOpen(session?.reschedule_link, SessionBookingLinkType.RESCHEDULE)
    localStorage.setItem(localStorageItems.needsToRefreshBookings, "true")
  }

  const handleSchedule = (billingPeriod: SessionBillingPeriod) => {
    if (!billingPeriod?.booking_link) return
    onOpen(billingPeriod.booking_link, SessionBookingLinkType.SCHEDULE)
    localStorage.setItem(localStorageItems.needsToRefreshBookings, "true")
  }

  const handleJoinSession = () => {
    if (!session?.meeting_link) return
    window.open(session?.meeting_link, "_blank")
  }

  const handleCancelSession = () => {
    if (!session?.cancel_link) return
    onOpen(session?.cancel_link, SessionBookingLinkType.CANCEL)
    localStorage.setItem(localStorageItems.needsToRefreshBookings, "true")
  }

  const sessionIsWithin24Hours =
    dayjs(session?.start_datetime).isBefore(dayjs().add(24, "hours")) &&
    dayjs(session?.start_datetime).isAfter(dayjs())
  const sessionIsWithin48Hours =
    dayjs(session?.start_datetime).isBefore(dayjs().add(48, "hours")) &&
    dayjs(session?.start_datetime).isAfter(dayjs())
  const sessionIsPast = dayjs(session?.start_datetime).isBefore(dayjs())

  return {
    handleReschedule,
    handleSchedule,
    handleJoinSession,
    handleCancelSession,
    sessionIsWithin24Hours,
    sessionIsWithin48Hours,
    sessionIsPast,
  }
}

export default useSession
