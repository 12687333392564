import { useQuery } from "@tanstack/react-query"

import { QUERY_KEYS } from "../../../../constants"
import { loadRecommendedCoaches } from "../../../../networking/user"

export const useRecommendedCoachesQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.COACH_PROFILES],
    queryFn: () => loadRecommendedCoaches(),
  })
}
