import {
  FlexBox,
  JoonColorExpanded,
  QuestRoutine,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

type Props = {
  title: string
  time?: string | null
  date?: string | null
  routine: QuestRoutine
  isSelfInitiated?: boolean
}

const TimeOfDayCard = ({
  title,
  time,
  routine,
  date,
  isSelfInitiated = false,
}: Props) => {
  const routineIcon = `images/icons/${routine}-routine-icon.png`
  const cardColor = () => {
    switch (routine) {
      case QuestRoutine.MORNING:
        return JoonColorExpanded.yellow100
      case QuestRoutine.AFTERNOON:
        return JoonColorExpanded.orange100
      case QuestRoutine.NIGHT:
        return JoonColorExpanded.indigo100
      case QuestRoutine.ANYTIME:
        return JoonColorExpanded.green100
    }
  }

  return (
    <div
      style={{
        background: cardColor(),
        width: "100%",
        borderRadius: SPACING.space2,
        padding: SPACING.space2,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      <FlexBox justify="space-between">
        {date && (
          <Typography variant="bodySmall">
            Due on: {dayjs(date).format("MMM DD")}
          </Typography>
        )}
        {isSelfInitiated ? (
          <Typography variant="bodySmall">
            <img
              style={{ height: "16px" }}
              src="/images/icons/shooting-star.svg"
              alt=""
            />
            Self-initiated
          </Typography>
        ) : (
          <Typography variant="bodySmall">
            <img style={{ height: "16px" }} src={routineIcon} alt="" />
            {time}
          </Typography>
        )}
      </FlexBox>
      <Typography variant="bodyBold">{title}</Typography>
    </div>
  )
}

export default TimeOfDayCard
