import {
  Button,
  Checkbox,
  CloseIcon,
  CloseCircleIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextArea,
  Typography,
} from "@joonapp/web-shared"
import toast from "react-hot-toast"

import SelectGiftModal from "./SelectGiftModal"
import { useSelectGiftModalStore } from "./useSelectGiftModalStore"
import useSendMailMutation from "./useSendMailMutation"
import { useSendMessageModalStore } from "./useSendMessageModalStore"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import { Toasts } from "../../../components/toaster/Toaster"
import { GRADIENTS } from "../../../constants"
import {
  useFamilyQuery,
  useInventoryGiftsQuery,
  useUserQuery,
} from "../../../networking/queries"
import { FamilyQuerySelect } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { getShopItemText } from "../../../util/util"

const SendMessageModal = () => {
  const {
    isOpen,
    onClose,
    selectedChildId,
    setSelectedChildId,
    message,
    setMessage,
    selectedGift,
    setSelectedGift,
  } = useSendMessageModalStore()

  const { user } = useUserQuery()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)

  const { onOpen: onOpenSelectGiftModal } = useSelectGiftModalStore()
  const { data: gifts } = useInventoryGiftsQuery()
  const sendMailMutation = useSendMailMutation()

  const onSelectGift = () => {
    if (gifts?.length === 0) {
      toast.dismiss()
      toast(Toasts.noItemsToGift, { duration: 3000 })
    } else {
      onOpenSelectGiftModal()
    }
  }

  const onSendMailClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.SEND_FAMILY_MAIL)
    sendMailMutation.mutate()
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      showCard={true}
      size="fit-content"
      backgroundType="overlay"
    >
      <button
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          padding: SPACING.space2,
        }}
        onClick={onClose}
      >
        <CloseIcon color={JoonUIColor.icon.neutral} />
      </button>
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        wrap={false}
        style={{
          padding: SPACING.space6,
          background: GRADIENTS.gradient100,
          height: "60vh",
        }}
      >
        <Typography variant="h3">Send a new mail</Typography>

        <Typography variant="bodyBold">To:</Typography>
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          wrap={true}
          style={{ textWrap: "nowrap" }}
        >
          {children?.map(
            (child) =>
              child.user.active_doter.name && (
                <Checkbox
                  key={child.user.id}
                  name={"Select Child to Send Mail"}
                  label={child.user.name}
                  selected={selectedChildId === child.user.id}
                  onChange={() => setSelectedChildId(child.user.id)}
                  inputType="radio"
                  hideBorder
                  style={{
                    padding: `${SPACING.space1} ${SPACING.space2}`,
                    border: `1px solid ${selectedChildId === child.user.id ? JoonUIColor.border.accent : JoonUIColor.border.default}`,
                    boxShadow: "4px 6px 6px 0px rgba(0, 0, 0, 0.08)",
                  }}
                />
              )
          )}
        </FlexBox>
        <Typography
          variant="bodyBold"
          style={{ textAlign: "center", marginTop: SPACING.space2 }}
        >
          Message
        </Typography>
        <TextArea
          name="Message"
          placeholder="Enter your message..."
          value={message}
          onChange={(e: any) => setMessage(e.target.value)}
          maxLength={150}
          showCounter={true}
          fullWidth
          style={{ lineHeight: "1.25rem" }}
        />
        {user?.enable_developer_mode && (
          <Typography variant="bodyBold">Add a gift</Typography>
        )}
        {!!selectedGift?.shop_item.name.length ? (
          <FlexBox
            direction="row"
            align="center"
            justify="flex-start"
            wrap={false}
            gap={SPACING.space1}
            onClick={() => setSelectedGift(null)}
            style={{
              backgroundColor: JoonUIColor.background.primaryNeutral,
              padding: SPACING.space2,
              borderRadius: SPACING.space2,
              cursor: "pointer",
            }}
          >
            <img
              src={selectedGift?.shop_item.image_url}
              style={{
                width: "15vw",
                maxWidth: "50px",
                height: "auto",
              }}
              alt="Gift"
            />
            <FlexBox
              direction="column"
              gap={SPACING.space0}
              align="flex-start"
              justify="flex-start"
            >
              <Typography variant="caption" style={{ lineHeight: "1.25rem" }}>
                {selectedGift?.shop_item.name}
              </Typography>
              <Typography variant="bodySmall" style={{ lineHeight: "1.25rem" }}>
                {selectedGift?.shop_item.description + " "}
                {getShopItemText(selectedGift?.shop_item)}
              </Typography>
            </FlexBox>
            <CloseCircleIcon size={24} color={JoonUIColor.icon.neutral} />
          </FlexBox>
        ) : (
          user?.enable_developer_mode && (
            <Button
              text="Select a gift"
              onClick={onSelectGift}
              buttonType="secondary"
              fullWidth
            />
          )
        )}
      </FlexBox>
      <FlexBox
        direction="row"
        justify="center"
        style={{
          background:
            "linear-gradient(rgba(255, 255, 255, 0.8) 2.83%, rgba(255, 255, 255, 0) 51.53%, rgba(255, 255, 255, 0.8) 99.81%), rgb(233, 234, 250)",
          padding: SPACING.space6,
        }}
      >
        <Button
          isLoading={sendMailMutation.isLoading}
          text="Send mail"
          fullWidth
          color={JoonUIColor.semantic.primary}
          onClick={onSendMailClick}
          disabled={!selectedChildId || !message}
        />
      </FlexBox>
      <SelectGiftModal />
    </SlideUpModal>
  )
}

export default SendMessageModal
