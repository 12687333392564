import {
  CircleIcon,
  CoachTagCategory,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Modal,
  SPACING,
  StarIcon,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useEffect, useRef, useState } from "react"
import Skeleton from "react-loading-skeleton"

import useCoachStore from "./useCoachStore"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import CareTeamAvatar from "../care/CareTeamAvatar"
import useCareTeam from "../care/useCareTeam"

const CoachInfoModal = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [overflowActive, setOverflowActive] = useState(false)
  const { isInfoModalOpen, onCloseInfoModal } = useCoachStore()
  const { selectedProfile } = useCareTeam()
  const router = useRouter()
  const bioRef = useRef<HTMLDivElement>(null)

  const onReadMoreClick = () => {
    setIsExpanded(!isExpanded)
    if (router.query.from === "preview")
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_READ_MORE_CLICK)
    else trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_READ_MORE_CLICK)
  }

  useEffect(() => {
    const checkOverflow = () => {
      if (bioRef.current) {
        const lineHeight = 1.25 * 16
        const maxHeight = lineHeight * 4
        const hasOverflow = bioRef.current.scrollHeight > maxHeight
        setOverflowActive(hasOverflow)
      }
    }
    if (isInfoModalOpen) setTimeout(checkOverflow, 100)
    window.addEventListener("resize", checkOverflow)
    return () => window.removeEventListener("resize", checkOverflow)
  }, [isInfoModalOpen])

  if (!selectedProfile) return <Skeleton />
  if (selectedProfile.role !== UserRole.COACH) return null

  const {
    nickname,
    bio,
    user: { name, last_name },
    tags,
    rating,
    num_ratings,
  } = selectedProfile

  const displayName = nickname ? nickname : `${name} ${last_name || ""}`
  const firstOrNickname = nickname ? nickname : name

  const specialties = tags
    .filter((tag) => tag.category === CoachTagCategory.SPECIALTIES)
    .map((tag) => tag.name)

  const approaches = tags
    .filter((tag) => tag.category === CoachTagCategory.APPROACHES)
    .map((tag) => tag.name)

  const credentials = tags
    .filter((tag) => tag.category === CoachTagCategory.CREDENTIALS)
    .map((tag) => tag.name)

  return (
    <Modal
      isOpen={isInfoModalOpen}
      onClose={onCloseInfoModal}
      displayCloseIcon={true}
      animate
      style={{
        width: "min(450px, 100%)",
        padding: SPACING.space0,
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <FlexBox
        direction="column"
        fullWidth
        style={{
          background: JoonUIColor.background.primaryNeutral,
          boxShadow: `${SPACING.space1} ${SPACING.space1} ${SPACING.space1} #F0F3F8`,
          borderRadius: SPACING.space3,
        }}
      >
        <FlexBox
          direction="row"
          align="center"
          style={{
            padding: SPACING.space6,
            background: JoonColorExpanded.yellow100,
            borderTopLeftRadius: SPACING.space3,
            borderTopRightRadius: SPACING.space3,
          }}
          gap={SPACING.space4}
          wrap={false}
        >
          <CareTeamAvatar profile={selectedProfile} size="large" />
          <FlexBox
            direction="column"
            gap={SPACING.space2}
            align="center"
            wrap={false}
          >
            <FlexBox
              direction="row"
              justify="space-between"
              align="center"
              fullWidth
              wrap={false}
            >
              <Typography variant="h3">{displayName}</Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: SPACING.space1,
                }}
              >
                <div style={{ width: 18, height: 21 }}>
                  <StarIcon size={18} color={JoonColorExpanded.yellow400} />
                </div>
                <Typography variant="caption">
                  {rating.toFixed(1)} ({num_ratings})
                </Typography>
              </div>
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space1}>
              {credentials.map((credential, index) => (
                <FlexBox
                  key={index}
                  direction="row"
                  align="center"
                  gap={SPACING.space1}
                  wrap={false}
                >
                  <CircleIcon size={4} color={JoonColorExpanded.yellow400} />
                  <Typography
                    variant="bodyXSmall"
                    color={JoonColorExpanded.purple600}
                    textAlign="left"
                  >
                    {credential}
                  </Typography>
                </FlexBox>
              ))}
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox
          direction="column"
          gap={SPACING.space4}
          style={{
            padding: `${SPACING.space4} ${SPACING.space6} ${SPACING.space6} ${SPACING.space6}`,
          }}
        >
          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyBold">
              Read more about {firstOrNickname}
            </Typography>
            <FlexBox direction="column">
              <div
                ref={bioRef}
                style={{
                  color: JoonUIColor.text.primary,
                  fontSize: "0.875rem",
                  lineHeight: "1.25rem",
                  fontWeight: 500,
                  textAlign: "left",
                  display: "-webkit-box",
                  WebkitLineClamp: isExpanded ? "unset" : 4,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {bio}
              </div>
              {overflowActive && (
                <button onClick={onReadMoreClick}>
                  <Typography
                    variant="bodySmall"
                    color={JoonUIColor.text.primaryAccent}
                  >
                    {isExpanded ? "Read less" : "Read more..."}
                  </Typography>
                </button>
              )}
            </FlexBox>
          </FlexBox>

          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyBold">Specialties</Typography>
            <FlexBox gap={SPACING.space1} wrap={true}>
              {specialties.map((specialty, index) => (
                <FlexBox
                  key={index}
                  style={{
                    width: "fit-content",
                    padding: `${SPACING.space1} ${SPACING.space2}`,
                    background: JoonColorExpanded.yellow100,
                    borderRadius: SPACING.space1,
                  }}
                >
                  <Typography
                    variant="bodySmall"
                    color={JoonUIColor.text.secondary}
                  >
                    {specialty}
                  </Typography>
                </FlexBox>
              ))}
            </FlexBox>
          </FlexBox>

          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyBold">Approaches</Typography>
            <FlexBox gap={SPACING.space1} wrap={true}>
              {approaches.map((approach, index) => (
                <FlexBox
                  key={index}
                  style={{
                    width: "fit-content",
                    padding: `${SPACING.space1} ${SPACING.space2}`,
                    background: JoonColorExpanded.yellow100,
                    borderRadius: SPACING.space1,
                  }}
                >
                  <Typography
                    variant="bodySmall"
                    color={JoonUIColor.text.secondary}
                  >
                    {approach}
                  </Typography>
                </FlexBox>
              ))}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default CoachInfoModal
