import {
  ArrowRightIcon,
  Button,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

interface TextArrowButtonProps {
  text: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  isLoading?: boolean
  style?: React.CSSProperties
}

const TextArrowButton = ({
  text,
  onClick,
  disabled = false,
  isLoading = false,
  style,
}: TextArrowButtonProps) => {
  return (
    <Button
      fullWidth
      disabled={disabled}
      isLoading={isLoading}
      onClick={onClick}
      style={{
        width: "min(450px, calc(100% - 40px))",
        ...style,
      }}
      text={
        <FlexBox
          align="center"
          justify="center"
          gap={SPACING.space2}
          style={{
            position: "absolute",
            right: 0,
            top: "50%",
            width: "100%",
            transform: "translateY(-50%)",
          }}
        >
          <Typography variant="bodyBold" color={JoonUIColor.text.inverted}>
            {text}
          </Typography>
          <ArrowRightIcon size={20} color={JoonUIColor.icon.inverted} />
        </FlexBox>
      }
    />
  )
}

export default TextArrowButton
