import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  TextArea,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"
import { create } from "zustand"

import { CoachingQuestionText } from "../../constants"
import doterGroupImage from "../../images/doter-group.svg"
import { useFamilyQuery } from "../../networking/queries"
import { getCoachingStripeBillingUrl } from "../../networking/subscriptions"
import {
  CancellationSurveyQuestion,
  CancellationSurveyStep,
  CancellationSurveyType,
} from "../../types"
import { ANALYTIC_EVENTS } from "../../util/analytics"
import { trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import { getFamilyBillingLink } from "../../util/stripe"

interface CancellationSurveyModalStore {
  isOpen: boolean
  onOpen: (surveyType: CancellationSurveyType) => void
  onClose: () => void
  step: CancellationSurveyStep
  setStep: (step: CancellationSurveyStep) => void
  surveyType: CancellationSurveyType | null
}

const initialSurveyState = {
  isOpen: false,
  surveyType: null,
  step: CancellationSurveyStep.CUSTOM_REWARDS,
}

export const useCancellationSurveyModalStore =
  create<CancellationSurveyModalStore>()((set) => ({
    ...initialSurveyState,
    onOpen: (surveyType: CancellationSurveyType) =>
      set({
        isOpen: true,
        surveyType,
        step:
          surveyType === CancellationSurveyType.FAMILY
            ? CancellationSurveyStep.CUSTOM_REWARDS
            : CancellationSurveyStep.SURVEY,
      }),
    onClose: () => set(initialSurveyState),
    setStep: (step: CancellationSurveyStep) => set({ step }),
  }))

const CancellationSurveyModal = () => {
  const {
    isOpen,
    onClose,
    step: surveyStep,
  } = useCancellationSurveyModalStore()

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon>
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        style={{ padding: SPACING.space6 }}
      >
        {surveyStep === CancellationSurveyStep.CUSTOM_REWARDS && (
          <CustomRewardsStep />
        )}
        {surveyStep === CancellationSurveyStep.SURVEY && <SurveyStep />}
      </FlexBox>
    </Modal>
  )
}

const CustomRewardsStep = () => {
  const router = useRouter()
  const { onClose, setStep } = useCancellationSurveyModalStore()
  const handleRouteToRewards = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.CANCEL_SUB_WONT_CANCEL)
    router.push("/rewards")
    onClose()
  }

  const handleContinue = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.CANCEL_SUB_WILL_CANCEL)
    setStep(CancellationSurveyStep.SURVEY)
  }

  return (
    <FlexBox
      direction="column"
      style={{ width: "min(95vw, 300px)" }}
      wrap={false}
      gap={SPACING.space2}
      justify="center"
    >
      <img
        src={doterGroupImage}
        alt="Custom Rewards"
        style={{ width: "200px", margin: "0 auto" }}
      />
      <Typography
        variant="h3"
        color={JoonUIColor.text.primaryAccent}
        style={{ marginTop: SPACING.space4 }}
        textAlign="center"
      >
        Are you sure you want to cancel?
      </Typography>
      <Typography
        variant="bodySmall"
        textAlign="center"
        style={{ lineHeight: "1.2" }}
      >
        You can always create custom real-life rewards to increase motivation!
        Some examples include:
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: SPACING.space1,
        }}
      >
        <Typography variant="bodySmall" style={{ lineHeight: "1" }}>
          • Weekly allowances
        </Typography>
        <Typography variant="bodySmall" style={{ lineHeight: "1" }}>
          • 15 minutes of screen time
        </Typography>
        <Typography variant="bodySmall" style={{ lineHeight: "1" }}>
          • Activities with mom/dad
        </Typography>
        <Typography variant="bodySmall" style={{ lineHeight: "1" }}>
          • And many more!
        </Typography>
      </div>
      <Button
        text="View Custom Rewards"
        onClick={handleRouteToRewards}
        fullWidth
        style={{ marginTop: SPACING.space2 }}
      />
      <TextButton onClick={handleContinue} style={{ width: "100%" }}>
        <Typography variant="caption" color={JoonUIColor.semantic.destructive}>
          Continue cancellation
        </Typography>
      </TextButton>
    </FlexBox>
  )
}

const SurveyStep = () => {
  const { onClose, surveyType } = useCancellationSurveyModalStore()
  const [surveyAnswer, setSurveyAnswer] = useState("")
  const [isLoadingBilling, setIsLoadingBilling] = useState(false)
  const [question, setQuestion] = useState(
    CancellationSurveyQuestion.WHY_LEAVING
  )
  const { data: family } = useFamilyQuery()
  const questionText =
    CoachingQuestionText[surveyType as CancellationSurveyType][question]

  const redirectToBilling = async () => {
    if (isLoadingBilling) return
    setIsLoadingBilling(true)
    if (surveyType === CancellationSurveyType.COACH) {
      const billingUrl = await getCoachingStripeBillingUrl()
      window.open(billingUrl, "_self")
    } else {
      if (!family) return
      const billingUrl = await getFamilyBillingLink({ familyId: family.id })
      window.open(billingUrl, "_self")
    }
    setIsLoadingBilling(false)
    onClose()
  }

  const handleSubmit = () => {
    if (question === CancellationSurveyQuestion.WHY_LEAVING) {
      setQuestion(CancellationSurveyQuestion.ALTERNATIVE_SOLUTIONS)
      trackAnalyticEvent(ANALYTIC_EVENTS.SUB_CANCEL_SUBMIT_ANSWER, {
        question:
          surveyType === CancellationSurveyType.COACH
            ? "cancel_reason_coaching"
            : "cancel_reason",
        answer: surveyAnswer,
      })
      setSurveyAnswer("")
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.SUB_CANCEL_SUBMIT_ANSWER, {
        question:
          surveyType === CancellationSurveyType.COACH
            ? "alternative_coaching"
            : "alternative",
        answer: surveyAnswer,
      })
      setSurveyAnswer("")
      redirectToBilling()
    }
  }

  return (
    <FlexBox
      direction="column"
      style={{ width: "min(95vw, 300px)" }}
      wrap={false}
      gap={SPACING.space2}
      justify="center"
    >
      <Typography variant="bodyBold">{questionText}</Typography>
      <TextArea
        placeholder=""
        value={surveyAnswer}
        onChange={(e) => setSurveyAnswer(e.target.value)}
        name="survey"
        fullWidth
      />
      <Button
        text="Continue"
        onClick={handleSubmit}
        fullWidth
        isLoading={isLoadingBilling}
      />
    </FlexBox>
  )
}

export default CancellationSurveyModal
