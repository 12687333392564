import {
  CheckIcon,
  ChevronRightIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import PlanUpgradeRequestModal, {
  usePlanUpgradeRequestModalStore,
} from "./PlanUpgradeRequestModal"
import { localStorageItems } from "../../util/storage"

const NoSessionsInPlanScreen = () => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { isOpen, onOpen } = usePlanUpgradeRequestModalStore()

  const requestSubmitted =
    !isOpen &&
    !!localStorage.getItem(localStorageItems.hasSubmittedCoachUpgradeRequest)

  return (
    <FlexBox
      direction="column"
      align="center"
      gap={SPACING.space4}
      style={{
        maxWidth: "500px",
        margin: "0 auto",
        textAlign: "center",
        padding: SPACING.space6,
      }}
    >
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{
          background: JoonColorExpanded.orange100,
          borderRadius: SPACING.space2,
          padding: SPACING.space4,
          width: "fit-content",
        }}
      >
        <Typography variant="bodyBold" style={{ maxWidth: "300px" }}>
          Your current coaching plan does not include any live coaching
          sessions.
        </Typography>
      </FlexBox>
      <Typography
        variant="bodyBold"
        style={{ maxWidth: "300px", textAlign: "center" }}
      >
        Get more out of your coach with 1:1 personalized live coaching sessions.
      </Typography>
      <Typography variant="bodySmall">
        Upgrade today to virtually meet your coach, anytime, anywhere
      </Typography>
      <div style={{ minHeight: "170px" }}>
        <img
          src={"/images/sessions_empty.svg"}
          alt="sessions"
          loading="lazy"
          style={{
            opacity: imageLoaded ? 1 : 0,
            transition: "opacity 0.1s ease-in-out",
          }}
          onLoad={() => setImageLoaded(true)}
        />
      </div>
      <Typography variant="bodySmall">
        All you need is a little space and an open mind to get started
      </Typography>

      {requestSubmitted ? (
        <FlexBox
          direction="column"
          gap={SPACING.space1}
          style={{
            padding: SPACING.space4,
            borderRadius: SPACING.space2,
            background: JoonUIColor.background.lightGray,
          }}
        >
          <FlexBox direction="row" gap={SPACING.space1} align="center">
            <CheckIcon color={JoonUIColor.semantic.success} size={20} />
            <Typography variant="caption">Request submitted!</Typography>
          </FlexBox>
          <Typography variant="bodySmall">
            Our team member will reach out with details soon!
          </Typography>
        </FlexBox>
      ) : (
        <button
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: JoonColorExpanded.indigo400,
            borderRadius: SPACING.space2,
            padding: `${SPACING.space2} ${SPACING.space2} ${SPACING.space2} ${SPACING.space4}`,
            marginTop: SPACING.space4,
            boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.1)",
            width: "100%",
          }}
          onClick={onOpen}
        >
          <Typography
            variant="bodyBold"
            color={JoonUIColor.text.inverted}
            style={{ fontWeight: "600" }}
          >
            Upgrade coaching plan
          </Typography>

          <div
            style={{
              minWidth: "30px",
              height: "28px",
              padding: SPACING.space1,
              placeContent: "center",
            }}
          >
            <ChevronRightIcon color={JoonUIColor.icon.inverted} size={20} />
          </div>
        </button>
      )}
      <PlanUpgradeRequestModal />
    </FlexBox>
  )
}

export default NoSessionsInPlanScreen
