import {
  Button,
  ChatIcon,
  FlexBox,
  HandHoldingHeartIcon,
  InfoIcon,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
  VideoIcon,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"
import { isAndroid, isChrome, isMobile } from "react-device-detect"

import { useCoachingStore } from "./useCoachingStore"
import ListCard from "../../components/coaching/ListCard"
import PageLoader from "../../components/loading/PageLoader"
import { useAddPhoneNumberModalStore } from "../../components/modals/addPhoneNumberModal/useAddPhoneNumberModalStore"
import { useAddToHomescreenModal } from "../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../components/modals/IOSModal"
import { COACH_PLANS, QUERY_KEYS } from "../../constants"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { queryClient } from "../../util/queryClient"
import { useRouter } from "../../util/router"
import useCareTeam from "../care/useCareTeam"

const ScheduledPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { onOpen: openAddPhoneNumberModal } = useAddPhoneNumberModalStore()
  const { planIndex } = useCoachingStore()
  const { careTeam } = useCareTeam()
  const router = useRouter()
  const { onOpen: openIOSModal } = useIOSModal()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const { plan, frequency } = COACH_PLANS[planIndex]

  const isOnMobileChrome = isChrome && isMobile && isAndroid

  const onFinishClick = () => {
    router.push("/care")
    openAddPhoneNumberModal()
    if (router.query.from === "preview") return
    trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_ONBOARDING_COMPLETE)
  }

  const onReturnHomeClick = () => {
    router.push("/me")
    if (router.query.from === "preview") return
    trackAnalyticEvent(ANALYTIC_EVENTS.VIRTUAL_COACH_ONBOARDING_COMPLETE)
    if (isOnMobileChrome) openAddToHomescreenModal()
    else openIOSModal()
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.CARE_TEAM])
      setIsLoading(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      {isLoading && <PageLoader />}

      <FlexBox
        justify="flex-start"
        align="center"
        direction="column"
        fullWidth
        gap={SPACING.space4}
        style={{
          minHeight: "70vh",
          paddingTop: "20px",
          opacity: isLoading ? 0 : 1,
          pointerEvents: isLoading ? "none" : "auto",
          transition: "opacity 0.3s ease-in-out",
        }}
      >
        <FlexBox
          direction="column"
          align="center"
          justify="center"
          gap={SPACING.space4}
          style={{ marginTop: SPACING.space6 }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: SPACING.space4,
              backgroundColor: JoonColorExpanded.viridian100,
              width: "62px",
              height: "62px",
              transform: "rotate(15deg)",
              paddingBottom: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "rotate(-15deg)",
              }}
            >
              <HandHoldingHeartIcon
                size={48}
                color={JoonUIColor.semantic.success}
              />
            </div>
          </div>
          <Typography variant="h2" color={JoonUIColor.text.primary}>
            Coaching membership started!
          </Typography>
        </FlexBox>

        <Typography variant="body" color={JoonUIColor.text.primary}>
          Membership summary:
        </Typography>
        <FlexBox
          direction="column"
          justify="center"
          align="center"
          style={{
            position: "relative",
            width: "min(450px, 100%)",
            background: "#F9FAFC",
            border: `2px solid ${JoonUIColor.text.primaryAccent}`,
            borderRadius: SPACING.space2,
            marginBottom: SPACING.space2,
            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
            textAlign: "left",
            padding: SPACING.space2,
          }}
        >
          <FlexBox
            justify="left"
            align="center"
            gap={SPACING.space1}
            style={{
              marginTop: SPACING.space2,
            }}
          >
            <Typography variant="h3" color={JoonUIColor.text.primary}>
              Joon
            </Typography>
            <Typography
              variant="h3"
              color={JoonUIColor.text.primaryAccent}
              style={{ fontFamily: "Caveat" }}
            >
              {plan}
            </Typography>
            <Typography variant="h3" color={JoonUIColor.text.primary}>
              Coach
            </Typography>
          </FlexBox>

          <FlexBox justify="center" align="center" gap={SPACING.space6}>
            <FlexBox direction="column" align="flex-start" gap={SPACING.space0}>
              <FlexBox
                direction="row"
                justify="flex-start"
                align="center"
                gap={SPACING.space2}
                wrap={false}
                style={{ padding: SPACING.space2 }}
              >
                <div
                  style={{
                    minWidth: "20px",
                    maxWidth: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <HandHoldingHeartIcon
                    size={20}
                    color={JoonUIColor.icon.accent}
                  />
                </div>
                <Typography
                  variant="bodyXSmall"
                  color={JoonUIColor.text.primary}
                  style={{ fontWeight: "700" }}
                >
                  A personally matched Coach to help you set up your Joon app
                  properly for your family
                </Typography>
              </FlexBox>

              <FlexBox
                direction="row"
                justify="flex-start"
                align="center"
                gap={SPACING.space2}
                wrap={false}
                style={{ padding: SPACING.space2 }}
              >
                <div
                  style={{
                    minWidth: "20px",
                    maxWidth: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ChatIcon size={20} color={JoonUIColor.icon.accent} />
                </div>
                <Typography
                  variant="bodyXSmall"
                  color={JoonUIColor.text.primary}
                  style={{ fontWeight: "700" }}
                >
                  Anytime direct messaging with your coach
                </Typography>
              </FlexBox>

              <FlexBox
                direction="row"
                justify="flex-start"
                align="center"
                gap={SPACING.space2}
                wrap={false}
                style={{
                  padding: SPACING.space2,
                  paddingRight: SPACING.space0,
                  borderRadius: SPACING.space2,
                }}
              >
                <div
                  style={{
                    minWidth: "20px",
                    maxWidth: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <VideoIcon size={20} color={JoonUIColor.icon.accent} />
                </div>
                <Typography
                  variant="bodyXSmall"
                  color={JoonUIColor.text.primary}
                  style={{ fontWeight: "700" }}
                >
                  {frequency}
                </Typography>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <ListCard
          icon={InfoIcon}
          text={`You're all set - ${careTeam?.[0]?.user.name || "your coach"} is excited to meet you, start a chat below!`}
          backgroundColor={JoonUIColor.background.lightAccent}
          gap={SPACING.space2}
          iconColor={JoonUIColor.border.accent}
        />

        <Button
          fullWidth
          onClick={onFinishClick}
          text={`Say hi to ${careTeam?.[0]?.user.name || "your coach"}`}
        />
        <FlexBox direction="column" justify="center" align="center">
          <TextButton onClick={onReturnHomeClick}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Return home
            </Typography>
          </TextButton>
        </FlexBox>
      </FlexBox>
    </>
  )
}

export default ScheduledPage
