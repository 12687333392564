import { CalloutBox, Typography } from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import PageLoader from "../../components/loading/PageLoader"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import {
  useFamilyQuery,
  useSubscriberInfo,
  useUserQuery,
} from "../../networking/queries"
import { AlertInterface } from "../../types"
import { requireAuth } from "../../util/auth"
import { useCheckoutRedirect } from "../paywall/useCheckoutRedirect"

function BillingPage() {
  useDocumentTitle("Joon - Billing")
  const { user } = useUserQuery()
  const { data: family } = useFamilyQuery()
  const { data: subscriberInfo } = useSubscriberInfo()
  const [formAlert, setFormAlert] = useState<AlertInterface>()
  const { redirectToFamilyCheckout, redirectToFamilyBilling } =
    useCheckoutRedirect()
  const setRedirectError = () =>
    setFormAlert({ type: "error", message: "Failed to redirect to billing." })

  useEffect(() => {
    if (!subscriberInfo || !user || !family) return
    if (subscriberInfo.plan_is_active)
      redirectToFamilyBilling().catch(setRedirectError)
    else redirectToFamilyCheckout().catch(setRedirectError)
  }, [user, family, subscriberInfo]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageLoader>
      {formAlert && (
        <div style={{ maxWidth: "500px" }}>
          <CalloutBox type="error" fullWidth>
            <Typography variant="bodySmall">{formAlert.message}</Typography>
          </CalloutBox>
        </div>
      )}
    </PageLoader>
  )
}

const BillingPageWithAuth = requireAuth(BillingPage)
export default BillingPageWithAuth
