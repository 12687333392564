import {
  ChevronRightIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import React, { useState } from "react"

import RewardRedeemButton from "./RewardRedeemButton"
import { RewardPurchase } from "../../../types/rewards"
import { useRouter } from "../../../util/router"

type Props = {
  purchaseCount?: number
  rewards: RewardPurchase[]
  title: string
  style?: React.CSSProperties
}

const RewardRedeemCard = ({
  purchaseCount = 0,
  rewards,
  title,
  style,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isPressed, setIsPressed] = useState(false)
  const router = useRouter()

  if (rewards.length === 0) return null

  return (
    <FlexBox
      justify="space-between"
      direction="row"
      align="center"
      wrap={false}
      gap={SPACING.space2}
      onClick={() => router.push(`/rewards/details/${rewards[0].reward.id}`)}
      style={{
        width: "100%",
        padding: `${SPACING.space3} ${SPACING.space4}`,
        border: "1px solid JoonUIColor.border.default",
        borderRadius: SPACING.space2,
        background: isHovered
          ? JoonUIColor.background.xlightGray
          : JoonUIColor.background.primaryNeutral,
        cursor: isHovered ? "pointer" : "default",
        transition: "background 0.1s ease-in-out",
        transform: isPressed ? "translateY(1px)" : "translateY(0px)",
        ...style,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false)
        setIsPressed(false)
      }}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
    >
      <FlexBox
        direction="row"
        align="center"
        justify="flex-start"
        wrap={false}
        gap={SPACING.space1}
        style={{ width: "60%" }}
      >
        <Typography
          variant="caption"
          style={{
            backgroundColor:
              purchaseCount === 0
                ? JoonUIColor.icon.light
                : JoonColorExpanded.green200,
            padding: `${SPACING.space05} ${SPACING.space1}`,
            borderRadius: SPACING.space1,
          }}
        >
          {purchaseCount}
        </Typography>
        <Typography
          variant="caption"
          textAlign="left"
          style={{
            textOverflow: "ellipsis",
            width: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </Typography>
      </FlexBox>
      <FlexBox
        direction="row"
        align="center"
        justify="flex-end"
        gap={SPACING.space2}
        wrap={false}
        style={{ width: "40%" }}
      >
        <RewardRedeemButton rewards={rewards} />
        <ChevronRightIcon size={24} color={JoonUIColor.icon.neutral} />
      </FlexBox>
    </FlexBox>
  )
}

export default RewardRedeemCard
