import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { localStorageItems } from "../../util/storage"

interface AddToHomescreenModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useAddToHomescreenModal = create<AddToHomescreenModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => {
      set({ isOpen: false })
      localStorage.removeItem(localStorageItems.showDownloadAppBanner)
    },
  })
)

const AddToHomescreenModal = () => {
  const { isOpen, onClose } = useAddToHomescreenModal()

  return (
    <>
      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            display: "flex",
            background: "#272727cc",
            color: "white",
            zIndex: 1500,
            padding: "8px",
            fontWeight: "600",
            alignItems: "flex-end",
            fontSize: "17px",
            borderRadius: "8px",
            marginRight: "2.5vw",
          }}
        >
          <div>Tap on your browser menu</div>
          <svg
            style={{ marginBottom: "8px", marginLeft: "8px" }}
            width="56"
            height="36"
            viewBox="0 0 56 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.51636 32.6651C0.687977 32.6562 0.0092293 33.3205 0.000330687 34.1489C-0.00856793 34.9773 0.655752 35.6561 1.48413 35.665L1.51636 32.6651ZM51.419 1.57625C51.1504 0.792554 50.2974 0.374951 49.5137 0.643502L36.7428 5.01979C35.9591 5.28834 35.5415 6.14135 35.81 6.92504C36.0786 7.70874 36.9316 8.12634 37.7153 7.85779L49.0673 3.96775L52.9573 15.3197C53.2258 16.1034 54.0789 16.521 54.8625 16.2525C55.6462 15.9839 56.0638 15.1309 55.7953 14.3472L51.419 1.57625ZM1.48413 35.665C10.4782 35.7616 17.203 35.6576 22.4243 34.9642C27.6723 34.2671 31.529 32.9601 34.7143 30.5644C37.8784 28.1845 40.237 24.8352 42.6842 20.346C45.1184 15.8809 47.7533 10.0632 51.3472 2.72205L48.6528 1.40295C44.9967 8.87088 42.4441 14.5189 40.0502 18.9101C37.6694 23.2773 35.5592 26.175 32.911 28.1668C30.2838 30.1428 26.9843 31.3322 22.0293 31.9903C17.0475 32.6519 10.5223 32.7619 1.51636 32.6651L1.48413 35.665Z"
              fill="#7B86F6"
            />
          </svg>
        </div>
      )}
      <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
        <div
          style={{
            width: "min(400px, 95vw)",
            display: "flex",
            flexDirection: "column",
            padding: "30px",
            gap: "16px",
          }}
        >
          <div
            style={{ textAlign: "center", fontSize: "22px", fontWeight: "600" }}
          >
            Add Joon to your Homescreen
          </div>
          <FlexBox direction="column">
            <Typography variant="bodyBold">Step 1</Typography>
            <Typography variant="body">
              Tap on your browser menu, follow the purple arrow above.
            </Typography>
          </FlexBox>
          <FlexBox align="center" gap={SPACING.space4} wrap={false}>
            <FlexBox direction="column">
              <Typography variant="bodyBold">Step 2</Typography>
              <Typography variant="body">
                Tap on{" "}
                <Typography
                  variant="bodyBold"
                  color={JoonUIColor.semantic.primary}
                >
                  "Install App"
                </Typography>{" "}
                Scroll down if you don't see it immediately.
              </Typography>
            </FlexBox>
            <img src="/images/add_to_homescreen.png" alt="add to homescreen" />
          </FlexBox>
          <Button
            text="Done!"
            onClick={onClose}
            style={{ marginTop: SPACING.space4 }}
          />
        </div>
      </Modal>
    </>
  )
}

export default AddToHomescreenModal
