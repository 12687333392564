import {
  ChevronRightIcon,
  CircleIcon,
  CloseCircleIcon,
  CoachTagCategory,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Modal,
  SPACING,
  StarIcon,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useRef, useState } from "react"
import Skeleton from "react-loading-skeleton"

import { useRecommendedCoachModalStore } from "./useRecommendedCoachModalStore"
import { CoachingStep } from "../../../../constants"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { useRouter } from "../../../../util/router"
import CareTeamAvatar from "../../../care/CareTeamAvatar"
import { useCoachingStore } from "../../useCoachingStore"

const RecommendedCoachModal = () => {
  const {
    selectedCoach,
    selectedCoachIndex,
    setStep,
    playVideo,
    setPlayVideo,
  } = useCoachingStore()
  const { isOpen, onClose } = useRecommendedCoachModalStore()
  const [isExpanded, setIsExpanded] = useState(false)
  const [overflowActive, setOverflowActive] = useState(false)
  const router = useRouter()
  const bioRef = useRef<HTMLDivElement>(null)

  const onSelectCoachClick = () => {
    if (router.query.from === "preview") {
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_SELECT, {
        coach_id: selectedCoach?.user.id,
      })
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_CONTINUE_CLICK, {
        step: CoachingStep.RECOMMENDED_COACHES,
      })
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_SELECT, {
        coach_id: selectedCoach?.user.id,
      })
      trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_CONTINUE_CLICK, {
        step: CoachingStep.RECOMMENDED_COACHES,
      })
    }
    setStep(CoachingStep.PLAN_SELECT)
    onCloseModal()
  }

  const onCloseModal = () => {
    setPlayVideo(false)
    onClose()
  }

  useEffect(() => {
    const checkOverflow = () => {
      if (bioRef.current) {
        const lineHeight = 1.25 * 16
        const maxHeight = lineHeight * 4
        const hasOverflow = bioRef.current.scrollHeight > maxHeight
        setOverflowActive(hasOverflow)
      }
    }
    if (isOpen) setTimeout(checkOverflow, 100)
    window.addEventListener("resize", checkOverflow)
    return () => window.removeEventListener("resize", checkOverflow)
  }, [isOpen])

  if (!selectedCoach) return <Skeleton />

  const {
    nickname,
    bio,
    user: { name, last_name },
    tags,
    rating,
    num_ratings,
    num_open_slots,
    percent_match,
  } = selectedCoach

  const displayName = nickname ? nickname : `${name} ${last_name || ""}`
  const firstOrNickname = nickname ? nickname : name

  const specialties = tags
    .filter((tag) => tag.category === CoachTagCategory.SPECIALTIES)
    .map((tag) => tag.name)

  const approaches = tags
    .filter((tag) => tag.category === CoachTagCategory.APPROACHES)
    .map((tag) => tag.name)

  const credentials = tags
    .filter((tag) => tag.category === CoachTagCategory.CREDENTIALS)
    .map((tag) => tag.name)

  const formattedPercentMatch = ((percent_match || 0) * 100).toFixed(0)

  if (playVideo) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onCloseModal}
        displayCloseIcon={false}
        animate
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background: "transparent",
          width: "min(450px, 90%)",
          padding: SPACING.space0,
          zIndex: 1,
        }}
      >
        <button
          onClick={onCloseModal}
          style={{
            position: "absolute",
            top: SPACING.space2,
            right: SPACING.space2,
            zIndex: 1000,
          }}
        >
          <CloseCircleIcon color={JoonUIColor.icon.inverted} size={24} />
        </button>
        <FlexBox
          fullWidth
          style={{ height: "564px", overflow: "hidden", pointerEvents: "auto" }}
        >
          <iframe
            width="100%"
            height="110%"
            src={selectedCoach.profile_intro_video_url || ""}
            title="YouTube Video Player"
            allow="autoplay; encrypted-media"
            loading="lazy"
            style={{
              borderRadius: SPACING.space4,
              border: `2px solid transparent`,
            }}
          ></iframe>
        </FlexBox>

        <button
          onClick={onSelectCoachClick}
          style={{
            width: "100%",
            marginTop: SPACING.space2,
          }}
        >
          <FlexBox
            justify="space-between"
            fullWidth
            style={{
              background: JoonColorExpanded.indigo400,
              boxShadow: `${SPACING.space1} ${SPACING.space2} ${SPACING.space2} rgba(0, 0, 0, 0.08)`,
              padding: SPACING.space4,
            }}
          >
            <Typography variant="bodyBold" color={JoonUIColor.text.inverted}>
              Continue with {selectedCoach.user.name || "this coach"}
            </Typography>
            <ChevronRightIcon size={24} color={JoonUIColor.text.inverted} />
          </FlexBox>
        </button>
      </Modal>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      displayCloseIcon={true}
      animate
      style={{
        position: "relative",
        margin: 0,
        border:
          selectedCoachIndex === 0
            ? `2px solid ${JoonUIColor.border.accent}`
            : "transparent",
        width: "min(450px, 90%)",
        padding: SPACING.space0,
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
        overflow: "visible",
      }}
    >
      <FlexBox direction="column" fullWidth>
        {selectedCoachIndex === 0 && (
          <FlexBox
            direction="row"
            justify="center"
            align="center"
            gap={SPACING.space1}
            style={{
              position: "absolute",
              padding: `${SPACING.space1} ${SPACING.space2}`,
              top: 0,
              left: 0,
              backgroundColor: JoonUIColor.background.accent,
              borderTopLeftRadius: SPACING.space2,
              borderBottomRightRadius: SPACING.space2,
              width: "fit-content",
            }}
          >
            <div style={{ width: 16, height: 18 }}>
              <StarIcon size={16} color={JoonUIColor.icon.inverted} />{" "}
            </div>
            <Typography variant="bodySmall" color={JoonUIColor.text.inverted}>
              {formattedPercentMatch}% Top Match!
            </Typography>
          </FlexBox>
        )}

        <FlexBox
          direction="row"
          align="center"
          style={{
            padding: SPACING.space6,
            paddingTop:
              selectedCoachIndex === 0 ? SPACING.space8 : SPACING.space6,
            background: JoonColorExpanded.yellow100,
            borderTopLeftRadius: SPACING.space3,
            borderTopRightRadius: SPACING.space3,
          }}
          gap={SPACING.space4}
          wrap={false}
        >
          <CareTeamAvatar profile={selectedCoach} size="large" />
          <FlexBox
            direction="column"
            gap={SPACING.space2}
            align="center"
            wrap={false}
          >
            <FlexBox
              direction="row"
              justify="space-between"
              align="center"
              fullWidth
              wrap={false}
            >
              <Typography variant="h3">{displayName}</Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: SPACING.space1,
                }}
              >
                <div style={{ width: 18, height: 21 }}>
                  <StarIcon size={18} color={JoonColorExpanded.yellow400} />
                </div>
                <Typography variant="caption">
                  {rating.toFixed(1)} ({num_ratings})
                </Typography>
              </div>
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space1}>
              {credentials.map((credential, index) => (
                <FlexBox
                  key={index}
                  direction="row"
                  align="center"
                  gap={SPACING.space1}
                  wrap={false}
                >
                  <CircleIcon size={4} color={JoonColorExpanded.yellow400} />
                  <Typography
                    variant="bodyXSmall"
                    color={JoonColorExpanded.purple600}
                    textAlign="left"
                  >
                    {credential}
                  </Typography>
                </FlexBox>
              ))}
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox
          direction="column"
          gap={SPACING.space4}
          wrap={false}
          style={{
            padding: `${SPACING.space4} ${SPACING.space6} ${SPACING.space2} ${SPACING.space6}`,
            maxHeight: "400px",
            overflowY: "scroll",
          }}
        >
          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyBold">
              Read more about {firstOrNickname}
            </Typography>
            <FlexBox direction="column">
              <div
                ref={bioRef}
                style={{
                  color: JoonUIColor.text.primary,
                  fontSize: "0.875rem",
                  lineHeight: "1.25rem",
                  fontWeight: 500,
                  textAlign: "left",
                  display: "-webkit-box",
                  WebkitLineClamp: isExpanded ? "unset" : 4,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {bio}
              </div>
              {overflowActive && (
                <button onClick={() => setIsExpanded(!isExpanded)}>
                  <Typography
                    variant="bodySmall"
                    color={JoonUIColor.text.primaryAccent}
                  >
                    {isExpanded ? "Read less" : "Read more..."}
                  </Typography>
                </button>
              )}
            </FlexBox>
          </FlexBox>

          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyBold">Specialties</Typography>
            <FlexBox gap={SPACING.space1} wrap={true}>
              {specialties.map((specialty, index) => (
                <FlexBox
                  key={index}
                  style={{
                    width: "fit-content",
                    padding: `${SPACING.space1} ${SPACING.space2}`,
                    background: JoonColorExpanded.yellow100,
                    borderRadius: SPACING.space1,
                  }}
                >
                  <Typography
                    variant="bodySmall"
                    color={JoonUIColor.text.secondary}
                  >
                    {specialty}
                  </Typography>
                </FlexBox>
              ))}
            </FlexBox>
          </FlexBox>

          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyBold">Approaches</Typography>
            <FlexBox gap={SPACING.space1} wrap={true}>
              {approaches.map((approach, index) => (
                <FlexBox
                  key={index}
                  style={{
                    width: "fit-content",
                    padding: `${SPACING.space1} ${SPACING.space2}`,
                    background: JoonColorExpanded.yellow100,
                    borderRadius: SPACING.space1,
                  }}
                >
                  <Typography
                    variant="bodySmall"
                    color={JoonUIColor.text.secondary}
                  >
                    {approach}
                  </Typography>
                </FlexBox>
              ))}
            </FlexBox>
          </FlexBox>

          <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
            {`${num_open_slots} client slot${num_open_slots === 1 ? "" : "s"} remaining`}
          </Typography>
        </FlexBox>

        <button
          onClick={onSelectCoachClick}
          style={{
            width: "100%",
            marginTop: SPACING.space2,
          }}
        >
          <FlexBox
            justify="space-between"
            fullWidth
            style={{
              background: JoonUIColor.background.accent,
              boxShadow: `${SPACING.space1} ${SPACING.space2} ${SPACING.space2} rgba(0, 0, 0, 0.08)`,
              padding: SPACING.space4,
              borderBottomLeftRadius: SPACING.space2,
              borderBottomRightRadius: SPACING.space2,
            }}
          >
            <Typography variant="bodyBold" color={JoonUIColor.text.inverted}>
              Continue with {selectedCoach.user.name || "this coach"}!
            </Typography>
            <ChevronRightIcon size={24} color={JoonUIColor.text.inverted} />
          </FlexBox>
        </button>
      </FlexBox>
    </Modal>
  )
}

export default RecommendedCoachModal
