import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"
import { useState } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import { Toasts } from "../../components/toaster/Toaster"
import { requestSubscriptionUpgrade } from "../../networking/subscriptions"
import { localStorageItems } from "../../util/storage"

interface PlanUpgradeRequestModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

export const usePlanUpgradeRequestModalStore =
  create<PlanUpgradeRequestModalStore>()((set) => ({
    isOpen: false,
    onClose: () => set({ isOpen: false }),
    onOpen: () => set({ isOpen: true }),
  }))

const PlanUpgradeRequestModal = () => {
  const { isOpen, onClose } = usePlanUpgradeRequestModalStore()
  const [frequency, setFrequency] = useState(1)

  const requestSubUpgradeMutation = useMutation(requestSubscriptionUpgrade)

  localStorage.removeItem(localStorageItems.hasSubmittedCoachUpgradeRequest)

  const handleSendRequest = async () => {
    await requestSubUpgradeMutation.mutateAsync(frequency)
    localStorage.setItem(
      localStorageItems.hasSubmittedCoachUpgradeRequest,
      "true"
    )
    onClose()
    toast(Toasts.planUpgradeRequestSent)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader
        title="Request a live session"
        onClose={onClose}
        showBorderBottom
      />
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        style={{
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
        }}
      >
        <div>
          <Typography variant="bodySmall" textAlign="left">
            Upgrade to Joon Plus Coach Pro subscription to unlock live sessions.
          </Typography>
          <Typography variant="bodySmall" textAlign="left">
            Plans start at $120/month for one 45 minutes session per month.
          </Typography>
        </div>
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="bodySmall" textAlign="left">
            How frequently would you like to meet your coach per month?
          </Typography>
          <input
            name="frequency"
            placeholder="ie: 1x, 2x, etc"
            type="number"
            maxLength={1}
            min={1}
            max={9}
            value={frequency}
            onChange={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength)
              setFrequency(parseInt(e.target.value))
            }}
            style={{
              height: "40px",
              borderRadius: "4px",
              border: `1px solid ${JoonUIColor.border.default}`,
              padding: SPACING.space2,
              fontSize: "16px",
              fontWeight: "500",
              color: JoonUIColor.text.primary,
              width: "200px",
            }}
          />
        </FlexBox>
        <FlexBox direction="row" gap={SPACING.space4} justify="flex-end">
          <TextButton onClick={onClose}>
            <Typography variant="bodyBold" color={JoonUIColor.border.accent}>
              Cancel
            </Typography>
          </TextButton>
          <Button
            text="Send request"
            onClick={handleSendRequest}
            isLoading={requestSubUpgradeMutation.isLoading}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default PlanUpgradeRequestModal
