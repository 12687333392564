import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  Switch,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import ManageRoutineTimesModal from "./ManageRoutineTimesModal"
import { useManageGameplay } from "./useManageGameplay"
import { useManageGameplayModalStore } from "./useManageGameplayModalStore"
import { useManageRoutineTimesModalStore } from "./useManageRoutineTimesModalStore"
import { PREFERENCE_KEY } from "../../../constants"

const ManageGameplayModal = () => {
  const {
    child,
    hasAnyRoutineTimeLimits,
    toggleRoutineTimeLimitsMutation,
    updatePreference,
    togglePauseMutation,
  } = useManageGameplay()
  const {
    isOpen: isOpenManageGameplayModal,
    onClose: onCloseManageGameplayModal,
  } = useManageGameplayModalStore()
  const {
    isOpen: isOpenManageRoutineTimesModal,
    onOpen: onOpenManageRoutineTimesModal,
    setTempAfternoonStartTime,
    setTempNightStartTime,
  } = useManageRoutineTimesModalStore()

  // resets temp times when manage routine times modal is opened
  useEffect(() => {
    if (isOpenManageRoutineTimesModal) {
      setTempAfternoonStartTime(child?.preferences?.afternoon_start_time)
      setTempNightStartTime(child?.preferences?.night_start_time)
    }
  }, [
    child,
    isOpenManageRoutineTimesModal,
    setTempAfternoonStartTime,
    setTempNightStartTime,
  ])

  if (!child) return <></>

  return (
    <>
      <Modal
        isOpen={isOpenManageGameplayModal}
        onClose={onCloseManageGameplayModal}
        displayCloseIcon
        animate
      >
        <div className="quest-modal">
          <div className="quest-modal-content">
            <FlexBox wrap={false} gap={SPACING.space4} direction="column">
              <FlexBox justify="center">
                <Typography variant="h2">Manage Gameplay Limits</Typography>
              </FlexBox>
              <Typography variant="body">
                Change these settings if you want your child to explore Joon
                only after completing Quests each day.
              </Typography>
              <FlexBox
                justify="space-between"
                align="center"
                gap={SPACING.space6}
              >
                <Typography variant="h2" color={JoonUIColor.text.primaryAccent}>
                  {child.name}
                </Typography>
                <Button
                  text="Manage Routine Times"
                  onClick={onOpenManageRoutineTimesModal}
                />
              </FlexBox>
              <FlexBox
                justify="space-between"
                align="center"
                wrap={false}
                gap={SPACING.space6}
              >
                <FlexBox direction="column">
                  <Typography variant="bodyBold">
                    Limit by Routine Time
                  </Typography>
                  {hasAnyRoutineTimeLimits ? (
                    <Typography variant="bodySmall">
                      Play time limits are active!
                    </Typography>
                  ) : (
                    <Typography variant="bodySmall">
                      No limits on play-time! Choose this to limit play-time
                      based on routine completion.
                    </Typography>
                  )}
                </FlexBox>
                <Switch
                  checked={hasAnyRoutineTimeLimits}
                  name="hasAnyRoutineTimeLimits"
                  onChange={() =>
                    toggleRoutineTimeLimitsMutation.mutate({
                      childId: child.id,
                      value: !hasAnyRoutineTimeLimits,
                    })
                  }
                />
              </FlexBox>
              <hr />
              {hasAnyRoutineTimeLimits && (
                <>
                  <FlexBox
                    style={{ padding: `${SPACING.space0} ${SPACING.space4}` }}
                    gap={SPACING.space4}
                  >
                    <FlexBox
                      justify="space-between"
                      align="center"
                      wrap={false}
                      gap={SPACING.space6}
                    >
                      <FlexBox direction="column">
                        <Typography variant="bodyBold">
                          Morning Routine
                        </Typography>
                        {child.preferences.morning_limit ? (
                          <div>
                            <Typography
                              variant="bodySmall"
                              color={JoonUIColor.semantic.primary}
                            >
                              {child.name} must complete all morning Quests,{" "}
                            </Typography>
                            <Typography variant="bodySmall">
                              and will be able to play before{" "}
                              {child.preferences.afternoon_start_time} if so.
                            </Typography>
                          </div>
                        ) : (
                          <Typography variant="bodySmall">
                            {child.name} will be able to play before{" "}
                            {child.preferences.afternoon_start_time}, whether or
                            not they do their morning Quests.
                          </Typography>
                        )}
                      </FlexBox>
                      <Switch
                        checked={child.preferences.morning_limit}
                        name="morningLimit"
                        onChange={() =>
                          updatePreference(
                            PREFERENCE_KEY.morningLimit,
                            !child.preferences.morning_limit
                          )
                        }
                      />
                    </FlexBox>
                    <hr />
                    <FlexBox
                      justify="space-between"
                      align="center"
                      wrap={false}
                      gap={SPACING.space6}
                    >
                      <FlexBox direction="column">
                        <Typography variant="bodyBold">
                          Afternoon Routine
                        </Typography>
                        {child.preferences.afternoon_limit ? (
                          <div>
                            <Typography
                              variant="bodySmall"
                              color={JoonUIColor.semantic.primary}
                            >
                              {child.name} must complete all afternoon Quests,{" "}
                            </Typography>
                            <Typography variant="bodySmall">
                              and will be able to play between{" "}
                              {child.preferences.afternoon_start_time} and{" "}
                              {child.preferences.night_start_time} if so.
                            </Typography>
                          </div>
                        ) : (
                          <Typography variant="bodySmall">
                            {child.name} will be able to play between{" "}
                            {child.preferences.afternoon_start_time} and{" "}
                            {child.preferences.night_start_time}, whether or not
                            they do their afternoon Quests.
                          </Typography>
                        )}
                      </FlexBox>
                      <Switch
                        checked={child.preferences.afternoon_limit}
                        name="afternoonLimit"
                        onChange={() =>
                          updatePreference(
                            PREFERENCE_KEY.afternoonLimit,
                            !child.preferences.afternoon_limit
                          )
                        }
                      />
                    </FlexBox>
                    <hr />
                    <FlexBox
                      justify="space-between"
                      align="center"
                      wrap={false}
                      gap={SPACING.space6}
                    >
                      <FlexBox direction="column">
                        <Typography variant="bodyBold">
                          Night Routine
                        </Typography>
                        {child.preferences.night_limit ? (
                          <div>
                            <Typography
                              variant="bodySmall"
                              color={JoonUIColor.semantic.primary}
                            >
                              {child.name} must complete all night Quests,{" "}
                            </Typography>
                            <Typography variant="bodySmall">
                              and will be able to play after{" "}
                              {child.preferences.night_start_time} if so.
                            </Typography>
                          </div>
                        ) : (
                          <Typography variant="bodySmall">
                            {child.name} will be able to play after{" "}
                            {child.preferences.night_start_time}, whether or not
                            they do their night Quests.
                          </Typography>
                        )}
                      </FlexBox>
                      <Switch
                        checked={child.preferences.night_limit}
                        name="nightLimit"
                        onChange={() =>
                          updatePreference(
                            PREFERENCE_KEY.nightLimit,
                            !child.preferences.night_limit
                          )
                        }
                      />
                    </FlexBox>
                  </FlexBox>
                  <hr />
                </>
              )}
              <FlexBox
                justify="space-between"
                align="center"
                wrap={false}
                gap={SPACING.space6}
              >
                <FlexBox direction="column">
                  <Typography variant="bodyBold">
                    Hide Quests Based on Routine Time
                  </Typography>
                  {child.preferences.hides_future_routines ? (
                    <Typography
                      variant="bodySmall"
                      color={JoonUIColor.semantic.primary}
                    >
                      {child.name} will not see Quests that are past the time of
                      the active routine.
                    </Typography>
                  ) : (
                    <Typography variant="bodySmall">
                      All Quests currently shown. Select this to hide Quests
                      that are past the current time of day. i.e. {child.name}{" "}
                      will not see night Quests in the afternoon.
                    </Typography>
                  )}
                </FlexBox>
                <Switch
                  name="hidesFutureRoutines"
                  checked={child.preferences.hides_future_routines}
                  onChange={() =>
                    updatePreference(
                      PREFERENCE_KEY.hidesFutureRoutines,
                      !child.preferences.hides_future_routines
                    )
                  }
                />
              </FlexBox>
              <hr />
              <FlexBox
                justify="space-between"
                align="center"
                wrap={false}
                gap={SPACING.space6}
              >
                <FlexBox direction="column">
                  <Typography variant="bodyBold">Pause Joon</Typography>
                  {child.active_doter.is_paused ? (
                    <FlexBox direction="column">
                      <Typography
                        variant="caption"
                        color={JoonUIColor.semantic.warning}
                      >
                        Joon is paused for {child.name}!
                      </Typography>
                      <Typography variant="bodySmall">
                        Their Doters don't need to be fed.
                      </Typography>
                    </FlexBox>
                  ) : (
                    <Typography variant="body">
                      Turning this on will make it so {child.name} won't need to
                      feed their Doters.
                    </Typography>
                  )}
                </FlexBox>
                <Switch
                  name="pauseJoon"
                  checked={child.active_doter.is_paused}
                  onChange={() =>
                    togglePauseMutation.mutate({
                      doterId: child.active_doter.id,
                      isPaused: child.active_doter.is_paused,
                    })
                  }
                />
              </FlexBox>
              <hr />
              <FlexBox
                justify="space-between"
                align="center"
                wrap={false}
                gap={SPACING.space6}
              >
                <FlexBox direction="column">
                  <Typography variant="bodyBold">
                    Enable Self Initiated Quests
                  </Typography>
                  {child.preferences.enable_doter_wishes ? (
                    <FlexBox direction="column">
                      <Typography variant="bodySmall">
                        Your child will get up to 3 quests they can choose to
                        initiate (called doter wishes in the game). These are
                        low effort, low reward tasks that encourage reflection,
                        gratitude, and kindness.
                      </Typography>
                    </FlexBox>
                  ) : (
                    <Typography variant="bodySmall">
                      Your child will not receive self initiated quests (doter
                      wishes). Any previously accepted self initiated quests
                      will continue to show up.
                    </Typography>
                  )}
                </FlexBox>
                <Switch
                  name="enableDoterWishes"
                  checked={child.preferences.enable_doter_wishes}
                  onChange={() =>
                    updatePreference(
                      PREFERENCE_KEY.enableDoterWishes,
                      !child.preferences.enable_doter_wishes
                    )
                  }
                />
              </FlexBox>
            </FlexBox>
          </div>
        </div>
      </Modal>
      <ManageRoutineTimesModal />
    </>
  )
}

export default ManageGameplayModal
