import {
  DropdownOption,
  JoonUIColor,
  SegmentedControl2,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import { create } from "zustand"

import { AddRewardButton } from "./AddRewardButton"
import RewardsRedeem from "./RewardsRedeem"
import RewardsShop from "./RewardsShop"
import WelcomeCustomRewards from "./WelcomeCustomRewards"
import ChildAuthCode from "../../components/childAuthCode/ChildAuthCode"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageHeader from "../../components/pageHeader/PageHeader"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { GRADIENTS } from "../../constants"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import useMobile from "../../hooks/useMobile"
import { requireAuth } from "../../util/auth"
import { localStorageItems } from "../../util/storage"

export enum RewardTab {
  SHOP,
  REDEEM,
}

const rewardTabOptions = [
  {
    label: "Available",
    value: RewardTab.SHOP,
  },
  {
    label: "Purchased",
    value: RewardTab.REDEEM,
  },
]

interface RewardTabStore {
  rewardTab: RewardTab
  setRewardTab: (tab: RewardTab) => void
  showWelcome: boolean
  setShowWelcome: (showWelcome: boolean) => void
}
export const useRewardTabStore = create<RewardTabStore>((set) => ({
  rewardTab: RewardTab.SHOP,
  setRewardTab: (rewardTab) => set({ rewardTab }),
  showWelcome: false,
  setShowWelcome: (showWelcome) => set({ showWelcome }),
}))

const CustomRewards = () => {
  useDocumentTitle("Joon - Rewards")
  const { showWelcome, setShowWelcome } = useRewardTabStore()
  const isMobile = useMobile()
  const { rewardTab, setRewardTab } = useRewardTabStore()

  const viewShopTab = () => setRewardTab(RewardTab.SHOP)
  const viewRedeemTab = () => setRewardTab(RewardTab.REDEEM)

  useEffect(() => {
    setShowWelcome(
      !!localStorage.getItem(localStorageItems.needsToSeeWelcomeRewards)
    )
  }, [setShowWelcome])

  if (showWelcome) return <WelcomeCustomRewards />

  return (
    <>
      <PageWrapper style={{ background: GRADIENTS.gradient100 }}>
        <PageHeader title="Rewards" rightComponent={<AddRewardButton />} />
        <ChildAuthCode />
        <PageContentWrapper mobileGutter>
          <SegmentedControl2
            options={rewardTabOptions}
            value={
              rewardTabOptions.find(
                (tab) => tab.value === rewardTab
              ) as DropdownOption
            }
            setValue={(tab) => {
              if (tab.value === RewardTab.SHOP) viewShopTab()
              else viewRedeemTab()
            }}
            fullWidth
            style={{ border: `1px solid ${JoonUIColor.border.default}` }}
          />
          {rewardTab === RewardTab.SHOP ? <RewardsShop /> : <RewardsRedeem />}
        </PageContentWrapper>
      </PageWrapper>
      {isMobile && <AddRewardButton />}
    </>
  )
}

const CustomRewardsWithAuth = requireAuth(CustomRewards)
export default CustomRewardsWithAuth
