import ForgotPassword from "../components/auth/ForgotPassword"
import SignIn from "../components/auth/SignIn"
import PageWrapper from "../components/pageWrapper/PageWrapper"
import useDocumentTitle from "../hooks/useDocumentTitle"
import { useRouter } from "../util/router"

function AuthPage() {
  useDocumentTitle("Joon - Sign In")
  const router = useRouter()

  const authType = router.query.type

  return (
    <PageWrapper style={{ width: "100%", background: "#f0f1fb" }}>
      <div className="onboard-wrapper">
        <div className="onboard-section-centered">
          {authType === "forgotpass" ? <ForgotPassword /> : <SignIn />}
        </div>
      </div>
    </PageWrapper>
  )
}

export default AuthPage
