import {
  Button,
  ChildUser,
  CloseIcon,
  DateInput,
  FlexBox,
  Gender,
  JoonUIColor,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import dayjs, { Dayjs } from "dayjs"
import { useState } from "react"

import QuestionnaireDisclaimer from "./onboarding/questionnaires/QuestionnaireDisclaimer"
import QuestionnaireSection from "./onboarding/questionnaires/QuestionnaireSection"
import { QUERY_KEYS } from "../constants"
import { submitQuestionnaire } from "../networking/authentication"
import { useFamilyQuery, useUserQuery } from "../networking/queries"
import {
  addChild,
  editChild,
  loadFamilies,
  loadQuestionnaires,
  removeChild,
} from "../networking/user"
import { getChildQuestions } from "../util/questionnaires"
import { separateFamily } from "../util/util"

interface Props {
  child?: ChildUser
  type?: "new" | "edit"
  closeModal: () => void
  setLoading?: (bool: boolean) => void
}

function EditChild({ child, type = "edit", closeModal, setLoading }: Props) {
  const [name, setName] = useState(child ? child.name : "")
  const [birthday, setBirthday] = useState<Dayjs | null>(
    child?.birthdate ? dayjs(child.birthdate) : null
  )
  const [warningText, setWarningText] = useState("")
  const [step, setStep] = useState<"info" | "disclaimer" | "questions">("info")

  const queryClient = useQueryClient()
  const { data: family } = useFamilyQuery()
  const { user } = useUserQuery()

  const { data: childQuestionnaires } = useQuery({
    queryKey: [QUERY_KEYS.QUESTIONNAIRES],
    queryFn: loadQuestionnaires,
    select: getChildQuestions,
  })

  const addChildMutation = useMutation({
    mutationFn: addChild,
    onSuccess: () =>
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY]).then(closeModal),
  })

  const editChildMutation = useMutation({
    mutationFn: editChild,
    onSuccess: () =>
      queryClient
        .invalidateQueries([QUERY_KEYS.FAMILY])
        .then(() => setLoading && setLoading(false)),
    onMutate: () => {
      setLoading && setLoading(true)
      closeModal()
    },
  })

  const removeChildMutation = useMutation({
    mutationFn: removeChild,
    onSuccess: () => {
      queryClient
        .invalidateQueries([QUERY_KEYS.FAMILY])
        .then(() => setLoading && setLoading(false))
    },
    onMutate: () => {
      setLoading && setLoading(true)
      closeModal()
    },
  })

  if (!user) return <></>

  const onInfoDone = () => {
    if (name == null || name.length === 0)
      return setWarningText("Please enter a name")
    if (type === "edit") return submitEditChild()
    else setStep("disclaimer")
  }

  const submitEditChild = async () => {
    if (!child) return
    const newInfo = {
      name: name,
      birthdate: birthday?.format("YYYY-MM-DD") || undefined,
      gender: Gender.OTHER,
    }

    editChildMutation.mutate({ childInfo: newInfo, childId: child.id })
  }

  const submitAddChild = async ({ questionAnswerMapping }: any) => {
    if (!family) return
    const newInfo = {
      name: name,
      birthdate: birthday?.format("YYYY-MM-DD") || undefined,
      gender: Gender.OTHER,
    }
    await addChildMutation.mutateAsync({
      childInfo: newInfo,
      familyId: family?.id,
    })

    // Submit questionnaires
    const families = await loadFamilies()
    const { children } = separateFamily(user.id, families)
    const newChild = children.filter((child) => child.user.name === name)[0]

    // For each type of child questionnaire,
    // submit the answers for that questionnaire, as found in questionAnswerMapping
    childQuestionnaires?.questionIdMap?.forEach((questionnaire: any) => {
      const answers = questionnaire.questions.map((question_id: any) => ({
        question_id,
        answer_ids: questionAnswerMapping[question_id],
      }))
      submitQuestionnaire(questionnaire.id, newChild.user.id, answers)
    })
  }

  if (step === "disclaimer")
    return (
      <div
        className="quest-modal"
        style={{ height: "100%", background: "#F0F1FB" }}
      >
        <div className="quest-modal-content">
          <QuestionnaireDisclaimer
            childName={name}
            onConfirm={() => setStep("questions")}
          />
        </div>
      </div>
    )
  else if (step === "questions") {
    return (
      <div
        className="quest-modal"
        style={{ height: "100%", background: "#F0F1FB" }}
      >
        <div className="quest-modal-content">
          <QuestionnaireSection
            questions={childQuestionnaires?.allQuestions}
            previousStep={() => setStep("disclaimer")}
            onConfirm={submitAddChild}
            fixedProgressBar={false}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="quest-modal">
      <div className="quest-modal-content">
        <Typography variant="h3" textAlign="center">
          {type === "new" ? "Add Child" : "Edit Child"}
        </Typography>
        <div style={{ marginTop: SPACING.space4 }} />
        <Typography variant="bodyBold">Child's Name</Typography>
        <TextInput
          name="firstName"
          placeholder="First name"
          onChange={(event) => setName(event.target.value)}
          value={name}
          fullWidth
        />
        <div style={{ marginTop: SPACING.space4 }} />
        <Typography variant="bodyBold">Child's Birthday</Typography>
        <FlexBox align="center" wrap={false}>
          <DateInput
            name="birthday"
            modalTitle="Child's Birthday"
            date={birthday ? dayjs(birthday).toDate() : null}
            setDate={(date) => setBirthday(dayjs(date))}
            maxDate={dayjs().toDate()}
            fullWidth
          />
          {birthday && (
            <button onClick={() => setBirthday(null)}>
              <CloseIcon color={JoonUIColor.text.primary} />
            </button>
          )}
        </FlexBox>

        {warningText.length > 0 && (
          <Typography
            variant="caption"
            color={JoonUIColor.semantic.alert}
            textAlign="center"
            style={{ marginTop: SPACING.space4 }}
          >
            {warningText}
          </Typography>
        )}
        <div style={{ marginTop: SPACING.space6 }} />
        <FlexBox wrap={false} align="center" gap={SPACING.space2}>
          {child && (
            <Button
              text="Delete"
              onClick={() => {
                if (!family) return
                removeChildMutation.mutate({
                  childId: child.id,
                  familyId: family.id,
                })
              }}
              buttonType="redPrimary"
              fullWidth
            />
          )}
          <Button
            onClick={onInfoDone}
            fullWidth
            text={type === "new" ? "Continue" : "Save"}
            style={{ minWidth: "50%" }}
          />
        </FlexBox>
      </div>
    </div>
  )
}

export default EditChild
