import {
  FlexBox,
  IconProps,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

const PlanPaywallListCard = ({
  icon: Icon,
  title,
  text,
}: {
  icon: React.FC<IconProps>
  title: string
  text: string | string[]
}) => {
  return (
    <FlexBox
      direction="column"
      align="flex-start"
      justify="flex-start"
      wrap={false}
      gap={SPACING.space1}
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
        padding: `${SPACING.space3} ${SPACING.space4}`,
        backgroundColor: JoonUIColor.background.primaryNeutral,
      }}
    >
      <FlexBox
        direction="row"
        align="center"
        justify="left"
        gap={SPACING.space2}
        wrap={false}
      >
        <div
          style={{
            minWidth: "16px",
            maxWidth: "16px",
            minHeight: "16px",
            maxHeight: "16px",
          }}
        >
          <Icon color={JoonColorExpanded.purple500} size={16} />
        </div>
        <Typography
          variant="bodyXSmall"
          color={JoonColorExpanded.purple600}
          textAlign="left"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </Typography>
      </FlexBox>
      {Array.isArray(text) ? (
        text.map((t) => (
          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.primary}
            textAlign="left"
          >
            {t}
          </Typography>
        ))
      ) : (
        <Typography
          variant="bodySmall"
          color={JoonUIColor.text.primary}
          textAlign="left"
        >
          {text}
        </Typography>
      )}
    </FlexBox>
  )
}

export default PlanPaywallListCard
