import { JoonUIColor, SPACING } from "@joonapp/web-shared"
import { useEffect, useRef } from "react"

const CustomTextArea = ({
  value,
  setValue,
  onBlur,
  disabled,
  placeholder,
  maxLength,
}: {
  value: string
  setValue: (value: string) => void
  onBlur?: () => void
  disabled?: boolean
  placeholder?: string
  maxLength?: number
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  // Add this new effect to adjust textarea heights when content loads
  useEffect(() => {
    // Adjust session summary textarea
    const textArea = textareaRef.current
    if (textArea instanceof HTMLTextAreaElement) {
      textArea.style.height = "auto"
      textArea.style.height = `${textArea.scrollHeight}px`
    }
  }, [value]) // Run when content changes

  return (
    <textarea
      name="session-summary"
      value={value}
      placeholder={placeholder}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
      maxLength={maxLength}
      disabled={disabled}
      ref={textareaRef}
      style={{
        display: "block",
        overflow: "hidden",
        resize: "none",
        color: JoonUIColor.text.primary,
        fontSize: "14px",
        width: "100%",
        background: JoonUIColor.background.primaryNeutral,
        padding: SPACING.space3,
        borderRadius: SPACING.space2,
        border: `1px solid ${JoonUIColor.border.default}`,
        transition: "background 0.1s ease-in-out",
      }}
    />
  )
}

export default CustomTextArea
