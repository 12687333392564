import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import { useRouter } from "../../../util/router"

export const PhoneNumberReminder = () => {
  const router = useRouter()
  const onCareTab =
    router.pathname.includes("/care") || router.pathname.includes("/coach")

  return (
    <Typography
      variant="bodySmall"
      color={JoonUIColor.text.secondary}
      style={{
        textAlign: "left",
        borderRadius: SPACING.space2,
        padding: SPACING.space4,
        background: JoonUIColor.background.xlightGray,
        lineHeight: 1.25,
      }}
    >
      <strong>Note: </strong>
      {onCareTab
        ? "We send an SMS message when your coach has messaged you to help make sure you know a message was received. Your number will not be shared with any third parties. Reply STOP any time to cancel, or HELP for more info."
        : "We send text messages to keep you updated on things care team members have shared with you. Your number will not be shared with any third party. Reply STOP any time to cancel, or HELP for more info. Only US numbers are currently supported."}
    </Typography>
  )
}
