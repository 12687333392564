import {
  CustomReward,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import RewardRedeemButton from "./RewardRedeemButton"
import {
  useFamilyQuery,
  usePurchasedRewardsQuery,
} from "../../../networking/queries"
import { FamilyQuerySelect } from "../../../types"

type Props = {
  childId: number
  reward: CustomReward
}

const RewardChildRedeemCard = ({ childId, reward }: Props) => {
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const { data: purchasedRewards } = usePurchasedRewardsQuery({
    hasRedeemed: false,
  })

  const child = children?.find((child) => child.user.id === childId)

  const childPurchasedRewards = purchasedRewards?.filter(
    (purchase) =>
      purchase.user_id === childId && purchase.reward.id === reward.id
  )

  return (
    <FlexBox
      justify="space-between"
      direction="row"
      align="center"
      wrap={false}
      gap={SPACING.space2}
      style={{
        width: "100%",
        padding: `${SPACING.space3} ${SPACING.space4}`,
        border: "1px solid JoonUIColor.border.default",
        borderRadius: SPACING.space2,
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      <FlexBox
        direction="row"
        align="center"
        justify="flex-start"
        wrap={false}
        gap={SPACING.space1}
        style={{
          width: "60%",
        }}
      >
        <Typography
          variant="caption"
          style={{
            backgroundColor:
              childPurchasedRewards?.length === 0
                ? JoonUIColor.icon.light
                : JoonColorExpanded.green200,
            padding: `${SPACING.space05} ${SPACING.space1}`,
            borderRadius: SPACING.space1,
          }}
        >
          {childPurchasedRewards?.length}
        </Typography>
        <Typography
          variant="caption"
          textAlign="left"
          style={{
            textOverflow: "ellipsis",
            width: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {child?.nickname ?? child?.user.name}
        </Typography>
      </FlexBox>
      <FlexBox
        direction="row"
        align="center"
        justify="flex-end"
        gap={SPACING.space1}
        wrap={false}
        style={{
          width: "40%",
        }}
      >
        {childPurchasedRewards?.length && childPurchasedRewards?.length > 0 ? (
          <RewardRedeemButton
            rewards={childPurchasedRewards || []}
            childId={childId}
          />
        ) : (
          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.secondary}
            style={{
              textAlign: "right",
              fontStyle: "italic",
            }}
          >
            Nothing to redeem!
          </Typography>
        )}
      </FlexBox>
    </FlexBox>
  )
}

export default RewardChildRedeemCard
