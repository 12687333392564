import { Button, CalloutBox, Typography } from "@joonapp/web-shared"

import { useEnterTherapistCodeModal } from "./EnterTherapistCodeModal"
import { useInviteTherapistViaEmailModal } from "./InviteTherapistViaEmailModal"
import TherapistInviteItem from "./TherapistInviteItem"
import TherapistItem from "./TherapistItem"
import {
  useTherapistInvitesQuery,
  useTherapistsQuery,
} from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const TherapistsTab = () => {
  const { data: therapists } = useTherapistsQuery()
  const { data: therapistInvites } = useTherapistInvitesQuery()

  const showTherapistView =
    (therapists && therapists.length > 0) ||
    (therapistInvites && therapistInvites.length > 0)

  return (
    <div className="therapist-tab">
      {showTherapistView ? <TherapistsLinkedView /> : <TherapistsEmptyView />}
    </div>
  )
}

const TherapistsLinkedView = () => {
  const { onOpen: openInviteModal } = useInviteTherapistViaEmailModal()
  const { onOpen: openEnterCodeModal } = useEnterTherapistCodeModal()

  const { data: therapists } = useTherapistsQuery()
  const { data: therapistInvites } = useTherapistInvitesQuery()

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        paddingTop: "12px",
      }}
    >
      {therapists?.map((therapist) => (
        <TherapistItem key={therapist.user.id} therapist={therapist} />
      ))}
      {therapistInvites?.map((invitationGroup, i) => (
        <TherapistInviteItem key={i} invitationGroup={invitationGroup} />
      ))}
      <CalloutBox fullWidth>
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <div className="font-body" style={{ textAlign: "center" }}>
            Invite more therapists
          </div>
          <div
            style={{
              display: "flex",
              gap: "8px",
              padding: "0 12px",
              justifyContent: "center",
            }}
          >
            <Button
              size="small"
              text="Enter Code"
              buttonType="secondary"
              onClick={openEnterCodeModal}
            />
            <Button
              size="small"
              text="Invite via email"
              onClick={() => {
                trackAnalyticEvent(
                  ANALYTIC_EVENTS.ADD_THERAPIST_PROMPT_INVITE,
                  { source: "manage_therapist" }
                )
                openInviteModal()
              }}
            />
          </div>
        </div>
      </CalloutBox>
    </div>
  )
}

const TherapistsEmptyView = () => {
  const { onOpen: openInviteModal } = useInviteTherapistViaEmailModal()
  const { onOpen: openEnterCodeModal } = useEnterTherapistCodeModal()

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          marginTop: "32px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ fontSize: "41px" }}>😢</div>
        </div>
        <div className="no-linked-therapist-text">
          No therapists linked to your account yet!
        </div>
      </div>
      <div className="horizontal-separator" />
      <div style={{ display: "flex", flexDirection: "column", gap: "21px" }}>
        <Typography variant="body" style={{ fontSize: "17px" }}>
          Invite your therapist to join your family's journey in Joon and get{" "}
          <Typography variant="bodyBold">full free access!</Typography>
        </Typography>
        <CalloutBox fullWidth>
          <div className="font-subheadline">
            Once linked, your child's therapist will be able to: monitor quest
            completion, add quests, and see how quests completions are changing
            over time.
          </div>
        </CalloutBox>
        <div style={{ display: "flex", gap: "8px", justifyContent: "center" }}>
          <Button
            buttonType="secondary"
            text="Enter Code"
            onClick={openEnterCodeModal}
          />
          <Button
            buttonType="primary"
            onClick={openInviteModal}
            text="Invite via email"
          />
        </div>
      </div>
    </>
  )
}

export default TherapistsTab
