import {
  JoonUIColor,
  JoonColorExpanded,
  SPACING,
  IconProps,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

const SessionButton = ({
  text,
  Icon,
  onClick,
  fullWidth,
}: {
  text: string
  Icon: React.FC<IconProps>
  onClick: () => void
  fullWidth?: boolean
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isPressed, setIsPressed] = useState(false)

  return (
    <button
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsPressed(false)
        setIsHovered(false)
      }}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      style={{
        background:
          isHovered || isPressed
            ? JoonUIColor.background.xlightGray
            : JoonUIColor.background.primaryNeutral,
        transform: isPressed ? "translateY(1px)" : "translateY(0px)",
        borderRadius: SPACING.space2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: SPACING.space2,
        padding: `${SPACING.space2} ${SPACING.space3}`,
        transition: "all 0.2s ease-in-out",
        width: fullWidth ? "100%" : "auto",
      }}
      onClick={onClick}
    >
      <Typography variant="caption">{text}</Typography>
      <div
        style={{
          minWidth: "32px",
          minHeight: "32px",
          placeContent: "center",
          borderRadius: "50%",
          background: JoonUIColor.background.xlightGray,
        }}
      >
        <Icon size={16} color={JoonColorExpanded.purple600} />
      </div>
    </button>
  )
}

export default SessionButton
