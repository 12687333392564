import { IconProps, JoonUIColor, SPACING } from "@joonapp/web-shared"
import { Typography } from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"
import { ButtonHTMLAttributes } from "react"

// NOTE: This is temporary until we add new
// variants for button in the shared library

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string | React.ReactNode
  icon?: React.FC<IconProps>
  buttonType: "primary" | "secondary"
}

const Button = ({ text, icon, buttonType = "primary", ...props }: Props) => {
  const Icon = icon

  const isPrimary = buttonType === "primary"

  return (
    <button
      {...props}
      style={{
        padding: `${SPACING.space1} ${SPACING.space3}`,
        borderRadius: SPACING.space2,
        border: `1px solid ${JoonUIColor.border.accent}`,
        backgroundColor: isPrimary ? JoonUIColor.background.accent : "white",
        color: isPrimary ? "white" : "black",
      }}
    >
      <FlexBox gap={SPACING.space1} align="center" wrap={false}>
        {Icon && (
          <Icon
            size={14}
            color={
              isPrimary ? JoonUIColor.icon.inverted : JoonUIColor.icon.accent
            }
          />
        )}
        <Typography
          variant="caption"
          color={
            isPrimary
              ? JoonUIColor.text.inverted
              : JoonUIColor.text.primaryAccent
          }
          style={{ lineHeight: "22px", fontWeight: 600, whiteSpace: "nowrap" }}
        >
          {text}
        </Typography>
      </FlexBox>
    </button>
  )
}

export default Button
