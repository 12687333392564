import { UserRole } from "@joonapp/web-shared"

import { CoachingGroup } from "../../types/coachingGroups"
import { createJoonAPIClient } from "../../util/joon-api"

export const getCoachingGroups = async () => {
  const API = createJoonAPIClient()
  return API.get<{ results: CoachingGroup[] }>("/api/coaching-groups/", {
    params: { role: UserRole.COACH },
  }).then((response) => response.data.results)
}
