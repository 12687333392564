import {
  Button,
  CloseIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { usePurchaseRewardModal } from "./PurchaseReward"
import SlideUpModal from "../../components/slideUpModal/SlideUpModal"
import { useCustomRewardsQuery } from "../../networking/queries"
import { useRouter } from "../../util/router"

interface RewardCardModalStore {
  isOpen: boolean
  onOpen: (rewarId: number) => void
  onClose: () => void

  rewardId: number | null
}

export const useRewardCardModalStore = create<RewardCardModalStore>((set) => ({
  isOpen: false,
  onOpen: (rewardId) => set({ isOpen: true, rewardId }),
  onClose: () => set({ isOpen: false }),

  rewardId: null,
}))

const RewardCardModal = () => {
  const { isOpen, onClose, rewardId } = useRewardCardModalStore()
  const { onOpen: openPurchaseRewardModal } = usePurchaseRewardModal()
  const { data: rewards } = useCustomRewardsQuery()
  const router = useRouter()
  const reward = rewards?.find((reward) => reward.id === rewardId)

  if (!reward) return <></>

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      showCard={true}
      size="fit-content"
      backgroundType="overlay"
    >
      <button
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          padding: SPACING.space2,
        }}
        onClick={onClose}
      >
        <CloseIcon color={JoonUIColor.icon.neutral} />
      </button>
      <FlexBox
        style={{ padding: SPACING.space6, width: "min(450px, 100%)" }}
        direction="column"
        wrap={false}
        gap={SPACING.space2}
      >
        <Typography
          variant="h3"
          textAlign="center"
          style={{ marginTop: SPACING.space2, marginBottom: SPACING.space2 }}
        >
          Manage Reward
        </Typography>
        <Typography
          variant="body"
          textAlign="center"
          style={{ marginBottom: SPACING.space3 }}
        >
          {reward.emoji} {reward.title}
        </Typography>
        <hr />
        <Button
          onClick={() => {
            openPurchaseRewardModal(reward)
            onClose()
          }}
          text="Purchase"
          fullWidth
          style={{ marginTop: SPACING.space3 }}
        >
          Purchase
        </Button>
        <TextButton
          onClick={() => {
            router.push(`/rewards/details/${reward.id}`)
            onClose()
          }}
          style={{ width: "100%" }}
        >
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            Details
          </Typography>
        </TextButton>
      </FlexBox>
    </SlideUpModal>
  )
}

export default RewardCardModal
