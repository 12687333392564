import {
  Button,
  DateInput,
  FlexBox,
  Modal,
  ModalHeader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

interface CustomDateSelectorModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  startDate: Date
  setStartDate: (startDate: Date) => void
  endDate: Date
  setEndDate: (endDate: Date) => void
}

export const useCustomDateSelectorModalStore =
  create<CustomDateSelectorModalStore>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
    startDate: new Date(),
    setStartDate: (startDate) => set({ startDate }),
    endDate: new Date(),
    setEndDate: (endDate) => set({ endDate }),
  }))

type Props = {
  onSubmit: (startDate: Date, endDate: Date) => void
}

const CustomDateSelectorModal = ({ onSubmit }: Props) => {
  const { isOpen, onClose, startDate, setStartDate, endDate, setEndDate } =
    useCustomDateSelectorModalStore()

  const onCloseModal = () => {
    onClose()
    onSubmit(startDate, endDate)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} animate>
      <ModalHeader title="Custom Date Selector" onClose={onClose} />
      <FlexBox
        justify="center"
        align="center"
        style={{
          padding: `${SPACING.space2} ${SPACING.space6} ${SPACING.space6} ${SPACING.space6}`,
          width: "min(350px, 95vw)",
        }}
        gap={SPACING.space4}
      >
        <Typography variant="h3" textAlign="center">
          Select a Date Range
        </Typography>
        <DateInput
          label="Start Date"
          date={startDate}
          setDate={setStartDate}
          name="startDate"
          modalTitle="Start Date"
          maxDate={new Date()}
          fullWidth
        />
        <DateInput
          label="End Date"
          date={endDate}
          setDate={setEndDate}
          name="startDate"
          modalTitle="Start Date"
          maxDate={new Date()}
          fullWidth
        />
        <Button onClick={onCloseModal} fullWidth text="Done" />
      </FlexBox>
    </Modal>
  )
}

export default CustomDateSelectorModal
