import { useFreeTrialFirstVerifyModalStore } from "../components/modals/FreeTrialFirstVerifyModal"
import { useFreeTrialLimitModal } from "../components/modals/FreeTrialLimitModal"
import { useSubscriberInfo, useUserQuery } from "../networking/queries"
import { sessionManager } from "../util/storage"

export const useSubscription = () => {
  const { user } = useUserQuery()
  const { onOpen: openFreeTrialModal } = useFreeTrialFirstVerifyModalStore()
  const { onOpen: openFreeTrialLimitModal } = useFreeTrialLimitModal()
  const { data: subscriberInfo } = useSubscriberInfo()

  const checkRemainingFreeQuests = (numVerifying?: number) => {
    const hasActivePlan = subscriberInfo?.plan_is_active
    const remainingFreeQuests =
      (user?.max_free_verified ?? 0) -
      (user?.num_quests_verified ?? 0) -
      (numVerifying ?? 1)

    if (hasActivePlan) return

    if (remainingFreeQuests < 0) {
      throw openFreeTrialLimitModal(remainingFreeQuests)
    } else if (sessionManager.needsToSeeVerifyFreemiumPopup()) {
      throw openFreeTrialModal()
    }
  }

  return { checkRemainingFreeQuests }
}
