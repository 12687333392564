import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared/"

import useMobile from "../../../../hooks/useMobile"

interface ChatBubbleDownProps {
  text: string
  width?: string
}

const ChatBubbleDown = ({ text, width }: ChatBubbleDownProps) => {
  const isMobile = useMobile()

  return (
    <FlexBox
      direction="column"
      align="center"
      wrap={false}
      style={{
        position: "absolute",
        width: width || "80%",
        padding: SPACING.space2,
        background: "#8677A7",
        borderRadius: SPACING.space2,
        top: "108px",
        left: "4px",
      }}
    >
      <Typography
        variant={isMobile ? "bodyXSmall" : "bodySmall"}
        color={JoonUIColor.text.inverted}
        style={{ textAlign: "left" }}
      >
        {text}
      </Typography>
      <div style={{ position: "absolute", top: "-12px", left: "5%" }}>
        <img
          src="/images/guidePhotos/chat_bubble_tail.svg"
          alt="chat bubble tail"
          style={{ transform: "rotate(180deg) scaleX(-1)" }}
        />
      </div>
    </FlexBox>
  )
}

export default ChatBubbleDown
