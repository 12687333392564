import { useQuery } from "@tanstack/react-query"

import {
  getSessionBillingPeriods,
  getSessionBooking,
  getSessionBookings,
} from "./api"
import { QUERY_KEYS } from "../../constants"
import { useRouter } from "../../util/router"
import { useCoachingGroupsQuery } from "../coachingGroups/queries"

export const useSessionBillingPeriodsQuery = () => {
  const { data: coachingGroups } = useCoachingGroupsQuery()
  const groupId = coachingGroups?.[0].id
  return useQuery({
    queryKey: [QUERY_KEYS.SESSION_BILLING_PERIODS, groupId],
    queryFn: () => getSessionBillingPeriods(groupId!),
    enabled: !!groupId,
  })
}

export const useSessionBookingsQuery = () => {
  const { data: coachingGroups } = useCoachingGroupsQuery()
  const groupId = coachingGroups?.[0].id
  return useQuery({
    queryKey: [QUERY_KEYS.SESSION_BOOKINGS, groupId],
    queryFn: () => getSessionBookings(groupId!),
    enabled: !!groupId,
  })
}

export const useSessionBookingQuery = () => {
  const router = useRouter()
  const sessionId = router.query.sessionId
  return useQuery({
    queryKey: [QUERY_KEYS.SESSION_BOOKING, sessionId],
    queryFn: () => getSessionBooking(Number(sessionId)),
    enabled: !!sessionId,
  })
}
