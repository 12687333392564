import {
  Button,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useInviteCodeStore } from "./useInviteCodeStore"
import BackButton from "../../components/buttons/BackButton"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"

function InviteContent() {
  const router = useRouter()
  const { setIsInvited } = useInviteCodeStore()

  return (
    <PageWrapper style={{ background: "#F0F1FB", textAlign: "center" }}>
      <div className="onboard-wrapper" style={{ width: "min(450px, 100%)" }}>
        <BackButton onBack={() => router.push("/welcome")} />
        <div
          className="onboard-section-centered"
          style={{ gap: "30px", minHeight: "70vh" }}
        >
          <img
            src="/images/logos/logo_white.svg"
            alt="Joon"
            style={{ width: "96px" }}
          />
          <Typography variant="h1">
            Were you given an invite code by a therapist?
          </Typography>
          <FlexBox direction="column" align="center" gap={SPACING.space3}>
            <Button
              buttonType="primary"
              fullWidth
              onClick={() => {
                trackAnalyticEvent(ANALYTIC_EVENTS.NOT_INVITED_BY_THERAPIST)
                router.push("/onboard")
              }}
              text="No"
            />
            <Button
              buttonType="secondary"
              fullWidth
              onClick={() => {
                trackAnalyticEvent(ANALYTIC_EVENTS.INVITED_BY_THERAPIST)
                setIsInvited(true)
              }}
              text="Yes"
            />
          </FlexBox>
          <hr
            style={{
              backgroundColor: JoonUIColor.border.accent,
              width: "50%",
            }}
          />
          <Typography variant="body" color={JoonUIColor.text.secondary}>
            <span>Already have an account? </span>
            <button
              onClick={() => {
                trackAnalyticEvent(ANALYTIC_EVENTS.AUTH_HAS_ACCOUNT)
                router.push("/auth/signin")
              }}
            >
              <Typography
                variant="bodyBold"
                color={JoonUIColor.semantic.primary}
              >
                Sign in
              </Typography>
            </button>
          </Typography>
        </div>
      </div>
    </PageWrapper>
  )
}

export default InviteContent
