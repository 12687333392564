import { useMutation, useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useEffect } from "react"
import { RRule } from "rrule"
import { useShallow } from "zustand/react/shallow"

import { useAddEditQuestStore } from "./useAddEditQuestStore"
import {
  QUERY_KEYS,
  QuestRepeatFrequency,
  REPETITIONS,
} from "../../../constants"
import { useFamilyQuery } from "../../../networking/queries"
import { addQuest, editQuest } from "../../../networking/quests"
import { FamilyQuerySelect } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import {
  convertToByWeekday,
  getRepetitionArrayFromRrule,
  getReptitionIntervalFromRrule,
} from "../../../util/util"
import useQuestBoardStore from "../useQuestBoardStore"

export const useAddEditQuestModal = () => {
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)

  const queryClient = useQueryClient()

  const { questBoardRef } = useQuestBoardStore()

  const {
    questName,
    description,
    coinReward,
    category,
    routines,
    routine,
    repetition,
    repeatsFrequency,
    startDate,
    reminderTime,
    timerLength,
    assignedChildren,
    mandatory,
    reqReview,
    reqNotes,
    reqPhoto,
    saveTemplate,
    templateId,
    customTemplateId,

    setQuestName,
    setDescription,
    setQuestDifficulty,
    setCoinReward,
    setCategory,
    setRoutine,
    setRepetition,
    setRepeatsFrequency,
    setStartDate,
    setReminderTime,
    setTimerLength,
    setMandatory,
    setReqReview,
    setSaveTemplate,
    toggleChild,
    setReqNotes,
    setReqPhoto,
    resetQuestDetails,

    onClose,
    setErrors,

    modalContentRef,
    additionalOptionsRef,
    isOpenAdditionalOptions,
    questRepeatSelection,
    setQuestRepeatSelection,

    selectedQuest,
  } = useAddEditQuestStore(
    useShallow((state) => ({
      questName: state.questName,
      description: state.description,
      coinReward: state.coinReward,
      category: state.category,
      routines: state.routines,
      routine: state.routine,
      repetition: state.repetition,
      repeatsFrequency: state.repeatsFrequency,
      startDate: state.startDate,
      reminderTime: state.reminderTime,
      timerLength: state.timerLength,
      assignedChildren: state.assignedChildren,
      mandatory: state.mandatory,
      reqReview: state.reqReview,
      reqNotes: state.reqNotes,
      reqPhoto: state.reqPhoto,
      saveTemplate: state.saveTemplate,
      templateId: state.templateId,
      customTemplateId: state.customTemplateId,
      setQuestName: state.setQuestName,
      setDescription: state.setDescription,
      setQuestDifficulty: state.setQuestDifficulty,
      setCoinReward: state.setCoinReward,
      setCategory: state.setCategory,
      setRoutine: state.setRoutine,
      setRepetition: state.setRepetition,
      setRepeatsFrequency: state.setRepeatsFrequency,
      setStartDate: state.setStartDate,
      setReminderTime: state.setReminderTime,
      setTimerLength: state.setTimerLength,
      setMandatory: state.setMandatory,
      setReqReview: state.setReqReview,
      setSaveTemplate: state.setSaveTemplate,
      toggleChild: state.toggleChild,
      setReqNotes: state.setReqNotes,
      setReqPhoto: state.setReqPhoto,
      resetQuestDetails: state.resetQuestDetails,
      onClose: state.onClose,
      setErrors: state.setErrors,
      modalContentRef: state.modalContentRef,
      additionalOptionsRef: state.additionalOptionsRef,
      isOpenAdditionalOptions: state.isOpenAdditionalOptions,
      questRepeatSelection: state.questRepeatSelection,
      setQuestRepeatSelection: state.setQuestRepeatSelection,
      selectedQuest: state.selectedQuest,
    }))
  )

  // On initial load, make sure the quest details are set
  // Maybe a better way to do this?
  useEffect(() => {
    if (!selectedQuest) return
    const questData = selectedQuest.series
    const repeatFreq = getReptitionIntervalFromRrule(questData.recurrence)
    const repetitionArray = getRepetitionArrayFromRrule(questData.recurrence)
    const questRepeats = repetitionArray.some((day) => !!day)

    setQuestName(questData.title)
    questData.description && setDescription(questData.description)
    setQuestDifficulty(questData.difficulty)
    setCoinReward(questData.redeemable_reward)
    questData.skill && setCategory(questData.skill)
    setRoutine(questData.routine)
    setRepetition(repetitionArray)
    setRepeatsFrequency(repeatFreq)
    if (questRepeats) {
      setQuestRepeatSelection(QuestRepeatFrequency.REPEAT)
    } else {
      setQuestRepeatSelection(QuestRepeatFrequency.NO_REPEAT)
    }
    questData.start_date && setStartDate(questData.start_date)
    questData.reminder_time && setReminderTime(questData.reminder_time)
    questData.timer_length && setTimerLength(questData.timer_length)
    toggleChild(selectedQuest.user_id)
    setMandatory(!!questData.mandatory)
    setReqReview(!!questData.requires_review)
    setReqNotes(!!questData.requires_completion_notes)
    setReqPhoto(!!questData.requires_completion_photo)
    setSaveTemplate(!!questData.template_id)
    //eslint-disable-next-line
  }, [selectedQuest])

  // Ensure that when the quest repeat selection is non repeating,
  // the repetition and repeats frequency are set to null
  useEffect(() => {
    if (questRepeatSelection === QuestRepeatFrequency.NO_REPEAT) {
      setRepetition(REPETITIONS.noDays)
      setRepeatsFrequency(null)
    }
  }, [
    repetition,
    questRepeatSelection,
    repeatsFrequency,
    setRepeatsFrequency,
    setRepetition,
    setStartDate,
  ])

  const checkForErrors = () => {
    const tempErrors = {} as any
    if (questName.length === 0) {
      tempErrors.questName = "Please enter a Quest Name"
      modalContentRef.current?.scrollTo({ top: 0, behavior: "smooth" })
    }
    if (
      questRepeatSelection === QuestRepeatFrequency.REPEAT &&
      repetition?.every((day) => !day)
    ) {
      tempErrors.repetition =
        "Please select the day(s) this Quest should repeat on."
    }
    setErrors(tempErrors)
    if (Object.keys(tempErrors).length > 0)
      throw new Error("Invalid quest details")
  }

  const addQuestAction = () => {
    checkForErrors()

    const isOneOffQuest = !repeatsFrequency && !startDate
    const isDatedQuest = !repeatsFrequency && startDate

    const recurrence = new RRule({
      freq: isOneOffQuest || isDatedQuest ? RRule.DAILY : RRule.WEEKLY,
      byweekday: repetition?.some((day) => !!day)
        ? convertToByWeekday(repetition)
        : undefined,
      interval: repeatsFrequency ?? undefined,
    })

    // For each child, for each routine, create a quest promise
    const addQuestPromises = [] as Promise<any>[]
    assignedChildren.forEach((childId, index) => {
      routines.forEach((questRoutine) => {
        addQuestPromises.push(
          addQuest(childId, {
            user_id: childId,
            title: questName,
            requires_review: reqReview,
            requires_completion_notes: reqNotes,
            requires_completion_photo: reqPhoto,
            routine: questRoutine,
            mandatory: mandatory,
            redeemable_reward: coinReward,
            description,
            skill: category,
            timer_length: timerLength,
            reminder_time: reminderTime,
            occurrence_limit: isOneOffQuest || isDatedQuest ? 1 : null,
            template_id: templateId,
            custom_template_id: customTemplateId,
            recurrence: recurrence.toString(),
            start_date:
              isOneOffQuest || !startDate
                ? dayjs().format("YYYY-MM-DD")
                : dayjs(startDate).format("YYYY-MM-DD"),
            end_date: isDatedQuest
              ? dayjs(startDate).format("YYYY-MM-DD")
              : null,
            ...(index === 0 ? { save_as_template: saveTemplate } : {}),
          })
        )
      })
    })

    // Wait for all the API calls to complete before continuing
    return Promise.all(addQuestPromises)
  }

  const addQuestMutation = useMutation({
    mutationFn: addQuestAction,
    onError: (err: any) => {
      const error = err.response.data
      if (error.title) alert("Please enter a Quest Name")
      else
        alert(Object.entries(error).map(([key, value]) => `${key}: ${value}`))
    },
    onSuccess: () => {
      resetQuestDetails()
      trackAnalyticEvent(ANALYTIC_EVENTS.ADD_QUEST)
      onClose()
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      if (questBoardRef?.current) {
        questBoardRef.current.scrollTo({ top: 0, behavior: "smooth" })
      }
    },
  })

  // Default select first child and always have at least one child selected
  useEffect(() => {
    if (
      !selectedQuest &&
      children &&
      children.length > 0 &&
      assignedChildren.length === 0
    )
      toggleChild(children[0].user.id)
    // eslint-disable-next-line
  }, [children])

  useEffect(() => {
    if (!reqReview) {
      reqNotes && setReqNotes(false)
      reqPhoto && setReqPhoto(false)
    }
    // eslint-disable-next-line
  }, [reqReview])

  // Scroll to the additional options section when it opens
  useEffect(() => {
    if (isOpenAdditionalOptions && additionalOptionsRef.current) {
      modalContentRef.current?.scrollTo({
        top: additionalOptionsRef.current.offsetTop,
        behavior: "smooth",
      })
    }
  }, [isOpenAdditionalOptions, additionalOptionsRef, modalContentRef])

  const editQuestAction = () => {
    if (!selectedQuest) throw new Error("No quest selected")
    checkForErrors()

    const isOneOffQuest = !repeatsFrequency && !startDate
    const isDatedQuest = !repeatsFrequency && startDate
    // const isRepeatingQuest = !!repeatsFrequency

    const recurrence = new RRule({
      freq: isOneOffQuest || isDatedQuest ? RRule.DAILY : RRule.WEEKLY,
      byweekday: repetition?.some((day) => !!day)
        ? convertToByWeekday(repetition)
        : undefined,
      interval: repeatsFrequency ?? undefined,
    })

    const newQuestInfo = {
      title: questName,
      requires_review: reqReview,
      requires_completion_notes: reqNotes,
      requires_completion_photo: reqPhoto,
      routine: routine,
      save_as_template: saveTemplate,
      mandatory: mandatory,
      redeemable_reward: coinReward,
      description,
      occurrence_limit: isOneOffQuest || isDatedQuest ? 1 : null,
      skill: category,
      timer_length: timerLength,
      reminder_time: reminderTime,
      recurrence: recurrence.toString(),
      start_date:
        isOneOffQuest || !startDate
          ? dayjs().format("YYYY-MM-DD")
          : dayjs(startDate).format("YYYY-MM-DD"),
      end_date: isDatedQuest ? dayjs(startDate).format("YYYY-MM-DD") : null,
    }

    return editQuest(
      selectedQuest.series.id || 1,
      selectedQuest.user_id,
      newQuestInfo
    )
  }

  const editQuestMutation = useMutation({
    mutationFn: editQuestAction,
    onError: (err: any) => {
      const error = err.response.data
      if (error.title) alert("Please enter a Quest Name")
      else
        alert(Object.entries(error).map(([key, value]) => `${key}: ${value}`))
    },
    onSuccess: () => {
      onClose()
      resetQuestDetails()
      trackAnalyticEvent(ANALYTIC_EVENTS.EDIT_QUEST)
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
    },
  })

  return { addQuestMutation, editQuestMutation }
}
