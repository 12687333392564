import {
  CalendarSolidIcon,
  GiftIcon,
  HandHoldingHeartIcon,
  JoonUIColor,
  SPACING,
  UserIcon,
  UsersIcon,
} from "@joonapp/web-shared"

import MobileNavLink from "./MobileNavLink"
import useCoachStore from "../../pages/coach/useCoachStore"

const MobileNav = () => {
  const { isFocused } = useCoachStore()
  const userIsTypingMessageToCoach = isFocused

  if (userIsTypingMessageToCoach) return <></>

  return (
    <div
      style={{
        background: JoonUIColor.background.primaryNeutral,
        position: "fixed",
        bottom: 0,
        width: "100vw",
        padding: `0 ${SPACING.space4}`,
        height: "70px",
        borderTop: `1px solid ${JoonUIColor.border.default}`,
        zIndex: "500",
        display: "flex",
        justifyContent: "space-around",
        boxShadow: "2px 0px 30px 0px rgba(36, 23, 185, 0.25)",
      }}
    >
      <MobileNavLink to="/me" name="Me" Icon={UserIcon} />
      <MobileNavLink to="/care" name="Care" Icon={HandHoldingHeartIcon} />
      <MobileNavLink to="/quests" name="Quests" Icon={CalendarSolidIcon} />
      <MobileNavLink to="/rewards" name="Rewards" Icon={GiftIcon} />
      <MobileNavLink to="/family" name="Family" Icon={UsersIcon} />
    </div>
  )
}

export default MobileNav
