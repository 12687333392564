import {
  ArrowRightIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import { isAndroid } from "react-device-detect"
import { isChrome, isMobile } from "react-device-detect"

import ChatBubble from "./planComponents/ChatBubble"
import ChatBubbleDown from "./planComponents/ChatBubbleDown"
import PlanCarouselCard from "./planComponents/PlanCarouselCard"
import PlanPaywallCard from "./planComponents/PlanPaywallCard"
import PlanPaywallList from "./planComponents/PlanPaywallList"
import TimerBanner from "./planComponents/TimerBanner"
import { useRecommendedCoachModalStore } from "./recommendedCoaches/useRecommendedCoachModalStore"
import PageLoader from "../../../components/loading/PageLoader"
import { useAddToHomescreenModal } from "../../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../../components/modals/IOSModal"
import { CoachingStep } from "../../../constants"
import useMobile from "../../../hooks/useMobile"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../../util/experiments"
import { useRouter } from "../../../util/router"
import SmallCoachCard from "../SmallCoachCard"
import { useCoachingStore } from "../useCoachingStore"

const PlanSelect = () => {
  const { selectedCoach, setTimeLeft, planIndex, setStep, setPlayVideo } =
    useCoachingStore()
  const { onClose } = useRecommendedCoachModalStore()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const { onOpen: openIOSModal } = useIOSModal()
  const variant = useGetExperimentCohort(
    experimentNames.coachPersonalizedPaywall
  )
  const isMobileMediaQuery = useMobile()
  const router = useRouter()

  const coachName = selectedCoach?.user.name

  const isOnMobileChrome = isChrome && isMobile && isAndroid

  const onSkipClick = () => {
    router.push("/me")
    if (router.query.from === "preview") {
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_SKIP_CLICK, {
        step: CoachingStep.PLAN_SELECT,
      })
      return
    }
    trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_SKIP_CLICK, {
      step: CoachingStep.PLAN_SELECT,
    })
    if (isOnMobileChrome) openAddToHomescreenModal()
    else openIOSModal()
  }

  const onBackClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_CHOOSE_ANOTHER_COACH)
    onClose()
    setPlayVideo(false)
    setStep(CoachingStep.RECOMMENDED_COACHES)
  }
  useEffect(() => {
    setTimeLeft(10 * 60)
  }, [setTimeLeft])

  if (!selectedCoach || !coachName) {
    return <PageLoader />
  }

  return (
    <FlexBox direction="column" align="center" gap={SPACING.space4}>
      <TimerBanner coachName={coachName} />

      <button
        onClick={onBackClick}
        style={{
          width: "100%",
          padding: "0",
          marginRight: "auto",
          marginTop: isMobileMediaQuery ? SPACING.space3 : SPACING.space6,
        }}
      >
        <FlexBox
          direction="row"
          justify="flex-start"
          align="center"
          wrap={false}
        >
          <FlexBox
            direction="row"
            justify="center"
            align="center"
            wrap={false}
            style={{
              marginRight: SPACING.space2,
              transform: "rotate(180deg)",
              width: "fit-content",
            }}
          >
            <ArrowRightIcon size={20} color={JoonUIColor.text.secondary} />
          </FlexBox>
          <Typography
            variant="caption"
            color={JoonUIColor.text.secondary}
            style={{ whiteSpace: "nowrap" }}
          >
            Return to all coaches
          </Typography>
        </FlexBox>
      </button>

      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space4}
        style={{
          position: "relative",
          ...(variant === cohorts.control
            ? { marginTop: "52px", marginBottom: "0px" }
            : {
                marginTop: "0px",
                marginBottom: isMobileMediaQuery ? "40px" : "52px",
              }),
        }}
      >
        {variant === cohorts.control && (
          <ChatBubble
            text={`Hi! I'm ${coachName} and I can't wait to get started working with you!`}
          />
        )}
        {variant === cohorts.variantA && (
          <ChatBubbleDown
            text={`Hi! I'm ${coachName} and here is a preview of your personalized Coaching Plan made just for you:`}
            width="96%"
          />
        )}
        <SmallCoachCard
          coachCardContent={selectedCoach}
          backgroundColor={JoonUIColor.background.primaryNeutral}
        />
      </FlexBox>

      {variant === cohorts.control && (
        <Typography variant="h3" color={JoonUIColor.text.primary}>
          Coaching plan details:
        </Typography>
      )}

      {variant === cohorts.control && <PlanCarouselCard index={planIndex} />}
      {variant === cohorts.variantA && (
        <>
          <PlanPaywallList />
          <PlanPaywallCard index={planIndex} onSkipClick={onSkipClick} />
        </>
      )}

      {variant === cohorts.control && (
        <TextButton onClick={onSkipClick}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            No thanks, I don't want a coach after all
          </Typography>
        </TextButton>
      )}
    </FlexBox>
  )
}

export default PlanSelect
