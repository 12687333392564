import { create } from "zustand"

import { SessionBookingLinkType } from "../../../../types/sessions"

interface SessionBookingModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: (link: string, linkType: SessionBookingLinkType) => void
  link: string
  linkType: SessionBookingLinkType
}

export const useSessionBookingModalStore = create<SessionBookingModalStore>()(
  (set) => ({
    isOpen: false,
    onClose: () => set({ isOpen: false }),
    onOpen: (link, linkType) => set({ isOpen: true, link, linkType }),
    link: "",
    linkType: SessionBookingLinkType.RESCHEDULE,
  })
)
