import { createJoonAPIClient } from "../util/joon-api"

export const getCoinTransactions = async ({
  userId,
  startDate,
  endDate,
  page,
}: {
  userId: number
  startDate: string | null
  endDate: string
  page: number
}) => {
  const API = createJoonAPIClient()
  return API.get(`api/coin-transactions/`, {
    params: {
      user_id: userId,
      start_date: startDate,
      end_date: endDate,
      page,
    },
  })
}

export const getCoinCumulativeStats = async ({
  userId,
  startDate,
  endDate,
}: {
  userId: number
  startDate: string | null
  endDate: string
}) => {
  const API = createJoonAPIClient()
  return API.get(`api/coin-transactions/cumulative-stats/`, {
    params: {
      user_id: userId,
      start_date: startDate,
      end_date: endDate,
    },
  }).then((response) => response.data)
}
