import {
  ArrowRightIcon,
  EditIcon,
  FilterIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import FilterItem from "./FilterItem"
import { useFilterRewardLogsModalStore } from "./FilterRewardLogsModal"
import RewardChildRedeemCard from "./RewardChildRedeemCard"
import { RewardLogRow, RewardLogTableLabel } from "./RewardLogRow"
import ChildBadge from "../../../components/badges/childBadge/ChildBadge"
import PageContentWrapper from "../../../components/pageContentWrapper/PageContentWrapper"
import PageWrapper from "../../../components/pageWrapper/PageWrapper"
import { GRADIENTS } from "../../../constants"
import {
  useCustomRewardLogsQuery,
  useCustomRewardLogsStatsQuery,
  useCustomRewardsQuery,
  usePurchasedRewardsQuery,
} from "../../../networking/queries"
import { useRouter } from "../../../util/router"
import { useAddEditRewardModal } from "../AddEditRewardModal"

export default function RewardLogDetailsPage() {
  const [showMore, setShowMore] = useState(false)
  const { onOpen: openAddEditRewardModal } = useAddEditRewardModal()
  const {
    onOpen: openFilterRewardLogsModal,
    selectedUser,
    setSelectedUser,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useFilterRewardLogsModalStore()
  const router = useRouter()
  const { rewardId } = router.params
  const { data: rewards, status: rewardsStatus } = useCustomRewardsQuery()
  const { data: purchasedRewards, status: purchasedRewardsStatus } =
    usePurchasedRewardsQuery({ hasRedeemed: false })
  const { data: redeemedRewards, status: redeemedRewardsStatus } =
    usePurchasedRewardsQuery({ hasRedeemed: true })
  const { data: customRewardLogs, isLoading: isCustomRewardLogsLoading } =
    useCustomRewardLogsQuery({
      rewardId: Number(rewardId),
    })
  const {
    data: customRewardLogsStats,
    isLoading: isCustomRewardLogsStatsLoading,
  } = useCustomRewardLogsStatsQuery({
    rewardId: Number(rewardId),
  })

  const isLoading =
    rewardsStatus === "loading" ||
    purchasedRewardsStatus === "loading" ||
    redeemedRewardsStatus === "loading" ||
    isCustomRewardLogsLoading ||
    isCustomRewardLogsStatsLoading
  const hasFilters = selectedUser || startDate || endDate
  const rewardDetails = [
    ...(rewards ?? []),
    ...(purchasedRewards?.map((pr) => pr.reward) ?? []),
    ...(redeemedRewards?.map((rr) => rr.reward) ?? []),
  ].find((reward) => reward.id === Number(rewardId))

  const onFilterClick = () => {
    if (!customRewardLogs?.length) return
    openFilterRewardLogsModal(Number(rewardId))
  }

  const onClearClick = () => {
    setSelectedUser(null)
    setStartDate(null)
    setEndDate(null)
  }

  return (
    <PageWrapper style={{ background: GRADIENTS.gradient100 }}>
      <PageContentWrapper mobileGutter>
        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          wrap={false}
          style={{ paddingTop: SPACING.space4, paddingBottom: SPACING.space4 }}
        >
          <TextButton
            onClick={() => {
              router.push("/rewards")
              onClearClick()
            }}
            style={{ transform: "rotate(180deg)", padding: SPACING.space1 }}
          >
            <ArrowRightIcon size={20} color={JoonUIColor.icon.neutral} />
          </TextButton>
          <Typography
            variant="h3"
            textAlign="center"
            color={JoonColorExpanded.indigo700}
          >
            {isLoading ? (
              <Typography variant="bodyXSmall">Loading...</Typography>
            ) : rewardDetails ? (
              `${rewardDetails?.emoji} ${rewardDetails?.title}`
            ) : (
              <Typography variant="bodyXSmall">No reward found</Typography>
            )}
          </Typography>
          {!rewardDetails?.date_deleted ? (
            <TextButton onClick={() => openAddEditRewardModal(rewardDetails)}>
              <EditIcon size={16} color={JoonUIColor.icon.neutral} />
            </TextButton>
          ) : (
            <TextButton
              onClick={() => {}}
              style={{ pointerEvents: "none", opacity: 0 }}
            >
              <div style={{ width: 16, height: 16 }}></div>
            </TextButton>
          )}
        </FlexBox>

        <FlexBox direction="column" gap={SPACING.space6} wrap={false}>
          <FlexBox
            direction="column"
            justify="flex-start"
            align="flex-start"
            gap={SPACING.space4}
            wrap={false}
            style={{
              border: `1px solid ${JoonUIColor.border.default}`,
              borderRadius: SPACING.space2,
              padding: SPACING.space3,
              background: JoonUIColor.background.primaryNeutral,
            }}
          >
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography
                variant="bodyXSmall"
                color={JoonUIColor.text.secondary}
                style={{ fontWeight: 600 }}
              >
                Price
              </Typography>
              <FlexBox
                gap={SPACING.space1}
                direction="row"
                wrap={false}
                align="center"
              >
                <img
                  style={{ height: SPACING.space3 }}
                  src="/images/icons/coin-icon.png"
                  alt="coin"
                />
                <Typography variant="bodySmall">
                  {rewardDetails?.cost}
                </Typography>
              </FlexBox>
            </FlexBox>

            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography
                variant="bodyXSmall"
                color={JoonUIColor.text.secondary}
                style={{ fontWeight: 600 }}
              >
                Available for
              </Typography>
              <FlexBox direction="row" gap={SPACING.space1}>
                {rewardDetails?.user_ids
                  .slice(0, showMore ? rewardDetails?.user_ids.length : 2)
                  .map((childId, i) => (
                    <ChildBadge childId={childId} key={i} />
                  ))}
                {rewardDetails?.user_ids.length &&
                  rewardDetails?.user_ids.length > 2 &&
                  !showMore && (
                    <button
                      onClick={() => setShowMore(true)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: SPACING.space1,
                        background: JoonColorExpanded.yellow200,
                        width: "fit-content",
                        padding: `${SPACING.space0} ${SPACING.space2} ${SPACING.space0} ${SPACING.space2}`,
                        borderRadius: SPACING.space4,
                      }}
                    >
                      <Typography variant="bodySmall">
                        +{rewardDetails?.user_ids.length - 2} more...
                      </Typography>
                    </button>
                  )}
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyBold">Current balance</Typography>
            {rewardDetails?.user_ids.map((childId) => (
              <RewardChildRedeemCard
                childId={childId}
                key={childId}
                reward={rewardDetails}
              />
            ))}
          </FlexBox>

          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyBold">Analytics</Typography>

            <FlexBox direction="row" justify="space-between" wrap={false}>
              <FlexBox direction="column" gap={SPACING.space1}>
                <Typography
                  variant="bodyXSmall"
                  color={JoonUIColor.text.secondary}
                  style={{ fontWeight: 600 }}
                >
                  Total purchased (all time)
                </Typography>
                <Typography variant="caption" color={JoonUIColor.text.primary}>
                  {customRewardLogsStats?.purchased_all_time}
                </Typography>
              </FlexBox>
              <FlexBox direction="column" gap={SPACING.space1}>
                <Typography
                  variant="bodyXSmall"
                  color={JoonUIColor.text.secondary}
                  style={{ fontWeight: 600 }}
                >
                  Total purchased (this week)
                </Typography>
                <Typography variant="caption" color={JoonUIColor.text.primary}>
                  {customRewardLogsStats?.purchased_this_week}
                </Typography>
              </FlexBox>
            </FlexBox>

            <FlexBox direction="row" justify="space-between" wrap={false}>
              <FlexBox direction="column" gap={SPACING.space1}>
                <Typography
                  variant="bodyXSmall"
                  color={JoonUIColor.text.secondary}
                  style={{ fontWeight: 600 }}
                >
                  Total redeemed (all time)
                </Typography>
                <Typography variant="caption" color={JoonUIColor.text.primary}>
                  {customRewardLogsStats?.redeemed_all_time}
                </Typography>
              </FlexBox>
              <FlexBox direction="column" gap={SPACING.space1}>
                <Typography
                  variant="bodyXSmall"
                  color={JoonUIColor.text.secondary}
                  style={{ fontWeight: 600 }}
                >
                  Total redeemed (this week)
                </Typography>
                <Typography variant="caption" color={JoonUIColor.text.primary}>
                  {customRewardLogsStats?.redeemed_this_week}
                </Typography>
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox direction="column" gap={SPACING.space2}>
            <FlexBox direction="column" gap={SPACING.space1}>
              <FlexBox
                direction="row"
                justify="space-between"
                align="center"
                wrap={false}
              >
                <Typography variant="bodyBold">Transaction history</Typography>
                <TextButton onClick={onFilterClick}>
                  <FilterIcon
                    size={16}
                    color={
                      hasFilters
                        ? JoonUIColor.icon.accent
                        : JoonUIColor.icon.neutral
                    }
                  />
                </TextButton>
              </FlexBox>
              {hasFilters && (
                <FlexBox
                  direction="row"
                  justify="space-between"
                  align="flex-end"
                  wrap={false}
                >
                  <FlexBox direction="row" gap={SPACING.space1}>
                    {selectedUser && <FilterItem user={selectedUser} />}
                    {startDate && endDate ? (
                      <FilterItem startDate={startDate} endDate={endDate} />
                    ) : startDate ? (
                      <FilterItem startDate={startDate} />
                    ) : endDate ? (
                      <FilterItem endDate={endDate} />
                    ) : null}
                  </FlexBox>
                  <TextButton onClick={onClearClick} style={{ padding: 0 }}>
                    <Typography
                      variant="bodySmall"
                      color={JoonUIColor.text.primaryAccent}
                    >
                      clear
                    </Typography>
                  </TextButton>
                </FlexBox>
              )}
            </FlexBox>

            <RewardLogTableLabel />
            <hr />
            {isLoading ? (
              <Typography variant="bodyXSmall">Loading...</Typography>
            ) : customRewardLogs?.length ? (
              customRewardLogs?.map((log) => (
                <RewardLogRow log={log} key={log.id} />
              ))
            ) : (
              <Typography variant="bodyXSmall">No logs found</Typography>
            )}
          </FlexBox>
        </FlexBox>
      </PageContentWrapper>
    </PageWrapper>
  )
}
