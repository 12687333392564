import {
  Button,
  CreditCardIcon,
  JoonColorExpanded,
  JoonUIColor,
  ReceiptIcon,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"
import { useState } from "react"
import toast from "react-hot-toast"

import { COACH_PLANS } from "../../../../constants"
import useMobile from "../../../../hooks/useMobile"
import { useCoachSubscriptionPackagesQuery } from "../../../../networking/queries"
import { getCoachingStripeCheckoutUrl } from "../../../../networking/subscriptions"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { useCoachingStore } from "../../useCoachingStore"

type PlanPaywallCardProps = {
  index: number
  onSkipClick: () => void
}

const PlanPaywallCard = ({ index, onSkipClick }: PlanPaywallCardProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { selectedCoach } = useCoachingStore()
  const { data: subscriptionPackages } = useCoachSubscriptionPackagesQuery()

  const isMobile = useMobile()
  const { plan } = COACH_PLANS[index]
  const firstMonthPriceInCents = subscriptionPackages?.first_month_unit_amount
  const priceInCents = subscriptionPackages?.unit_amount ?? 0

  const formattedCentsToDollars = (cents: number) =>
    Number((cents / 100).toFixed(2))

  const priceForToday = formattedCentsToDollars(
    firstMonthPriceInCents ?? priceInCents
  )
  const fullPrice = formattedCentsToDollars(
    firstMonthPriceInCents ? priceInCents : priceInCents * 2
  )

  const infoCards = [
    {
      Icon: CreditCardIcon,
      text: "HSA/FSA eligible",
    },
    {
      Icon: ReceiptIcon,
      text: "Try it Risk-Free!\nLove it or get a full refund within 30 days",
    },
  ]

  const onContinueClick = async () => {
    if (!selectedCoach) return
    setIsLoading(true)
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_INITIATE_CHECKOUT)
    try {
      const checkoutUrl = await getCoachingStripeCheckoutUrl(selectedCoach.id) // must be profile id
      if (!checkoutUrl) {
        toast.error("Subscription already exists")
      } else {
        window.location.assign(checkoutUrl)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FlexBox
      direction="column"
      justify="center"
      align="center"
      gap={SPACING.space0}
      style={{
        width: "min(100%, 450px)",
        paddingBottom: SPACING.space2,
        opacity: subscriptionPackages ? 1 : 0,
        background: "#F9FAFC",
        border: `2px solid ${JoonUIColor.text.primaryAccent}`,
        borderRadius: "16px",
        marginBottom: SPACING.space4,
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
        transition: "opacity .3s ease-in-out",
      }}
    >
      <FlexBox
        direction="column"
        align="flex-end"
        gap={SPACING.space2}
        wrap={false}
        style={{ padding: SPACING.space4 }}
      >
        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          gap={SPACING.space1}
          wrap={false}
        >
          <FlexBox
            justify="center"
            align="center"
            gap={SPACING.space1}
            wrap={false}
            style={{ width: "fit-content" }}
          >
            <Typography
              variant={isMobile ? "h3" : "h2"}
              color={JoonUIColor.text.primary}
            >
              Joon
            </Typography>
            <Typography
              variant={isMobile ? "h3" : "h2"}
              color={JoonUIColor.text.primaryAccent}
              style={{ fontFamily: "Caveat" }}
            >
              {plan}
            </Typography>
            <Typography
              variant={isMobile ? "h3" : "h2"}
              color={JoonUIColor.text.primary}
            >
              Coach
            </Typography>
          </FlexBox>

          <FlexBox
            direction="row"
            justify="center"
            align="center"
            gap={SPACING.space2}
            wrap={false}
            style={{ width: "fit-content" }}
          >
            <Typography
              variant={isMobile ? "h3" : "h2"}
              color={JoonUIColor.background.disabled}
              style={{ textDecoration: "line-through" }}
            >
              ${fullPrice}
            </Typography>
            <Typography
              variant={isMobile ? "h3" : "h2"}
              color={JoonUIColor.text.primary}
            >
              ${priceForToday}
            </Typography>
          </FlexBox>
        </FlexBox>

        {firstMonthPriceInCents && (
          <FlexBox
            style={{
              padding: `${SPACING.space1} ${SPACING.space2}`,
              background: JoonColorExpanded.yellow300,
              borderRadius: SPACING.space1,
              width: "fit-content",
            }}
          >
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.primary}
              style={{
                fontWeight: 600,
                whiteSpace: "nowrap",
                width: "fit-content",
              }}
            >
              60% off Early Bird Special!
            </Typography>
          </FlexBox>
        )}
      </FlexBox>

      <FlexBox
        direction="column"
        align="flex-start"
        gap={SPACING.space2}
        wrap={false}
        style={{
          padding: `${SPACING.space4} ${SPACING.space2}`,
          background: JoonUIColor.background.primaryNeutral,
          borderTop: `1px solid ${JoonUIColor.icon.light}`,
          borderBottom: `1px solid ${JoonUIColor.icon.light}`,
        }}
      >
        {infoCards.map(({ Icon, text }) => (
          <FlexBox
            key={Icon.name}
            direction="row"
            justify="center"
            align="center"
            gap={SPACING.space2}
            wrap={false}
            style={{ width: "fit-content" }}
          >
            <div
              style={{
                minWidth: "16px",
                maxWidth: "16px",
                minHeight: "16px",
                maxHeight: "16px",
              }}
            >
              <Icon size={16} color={JoonUIColor.icon.neutral} />
            </div>
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.primary}
              style={{
                textAlign: "left",
                lineHeight: "1.3",
                whiteSpace: "pre-line",
              }}
            >
              {text}
            </Typography>
          </FlexBox>
        ))}
      </FlexBox>

      <FlexBox
        justify="center"
        align="center"
        gap={SPACING.space6}
        style={{ padding: SPACING.space4 }}
      >
        <Button
          fullWidth
          isLoading={isLoading}
          onClick={onContinueClick}
          style={{
            position: "relative",
            cursor: "pointer",
          }}
          text={
            <Typography
              variant={isMobile ? "caption" : "bodyBold"}
              color={JoonUIColor.text.inverted}
            >
              {`Get started with ${selectedCoach?.user.name || "your coach"}`}
            </Typography>
          }
        />
      </FlexBox>
      <TextButton onClick={onSkipClick}>
        <Typography
          variant={isMobile ? "caption" : "bodyBold"}
          color={JoonUIColor.text.primaryAccent}
        >
          No thanks, I don't want a coach after all
        </Typography>
      </TextButton>
    </FlexBox>
  )
}

export default PlanPaywallCard
