import { localStorageItems } from "../../../../util/storage"

const PRIMARY_GOAL_MAPPING: Record<number, string> = {
  785: "Strengthening my relationship with my child",
  786: "Expanding my parenting toolbox",
  787: "Reducing stress and conflict at home",
  788: "Improving my child's behavior",
  789: "Building a deeper connection with my child",
  790: "Becoming a more confident parent",
  791: "Fostering my child's development",
  896: "Strengthening relationships while reducing family stress",
}

const LIFE_AREAS_MAPPING: Record<number, string> = {
  764: "Lower daily stress and better peace of mind",
  765: "Consistent routines that run smoothly",
  766: "Better focus and effectiveness at work",
  767: "More harmony in your family relationships",
  768: "Better energy and personal well-being",
  769: "A balanced and fulfilling social life",
  770: "Confidence in your parenting decisions",
  771: "Positive and sustainable behavior patterns",
  772: "Lasting and impactful positive changes at home",
}

const BIGGEST_CHALLENGE_MAPPING: Record<number, string> = {
  732: "Creating structured & effective daily routines",
  733: "Managing difficult moments and big emotions",
  734: "Building stronger parent-child communication",
  735: "Finding harmony between work and family",
  736: "Establishing effective boundaries with confidence",
  737: "Developing your parental resilience toolkit",
  738: "Developing practical parenting strategies for daily life",
}

export const getQuestionnaireAnswers = () => {
  const rawAnswers = JSON.parse(
    localStorage.getItem(localStorageItems.coachQuestionnaireAnswers) || "{}"
  )

  const primaryGoal =
    PRIMARY_GOAL_MAPPING[rawAnswers[533]?.[0]] ||
    "Strengthening relationships while reducing family stress"

  const lifeAreasAffected: string[] = (rawAnswers[530] || []).map(
    (id: number) => LIFE_AREAS_MAPPING[id]
  )
  if (!lifeAreasAffected.length) {
    lifeAreasAffected.push("Lasting and impactful positive changes at home")
  }

  const biggestChallenge =
    BIGGEST_CHALLENGE_MAPPING[rawAnswers[498]?.[0]] ||
    "Developing practical parenting strategies for daily life"

  return {
    primaryGoal,
    lifeAreasAffected,
    biggestChallenge,
  }
}
