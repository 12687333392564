import {
  CaretRightIcon,
  CoachProfile,
  CoachTagCategory,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  StarIcon,
  Typography,
} from "@joonapp/web-shared"
import Skeleton from "react-loading-skeleton"

import { useRecommendedCoachModalStore } from "./useRecommendedCoachModalStore"
import useMediaQuery from "../../../../hooks/useMediaQuery"
import useMobile from "../../../../hooks/useMobile"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { useRouter } from "../../../../util/router"
import { useCoachingStore } from "../../useCoachingStore"

interface RecommendedCoachCardProps {
  coachCardContent: CoachProfile
}

const RecommendedCoachCard = ({
  coachCardContent,
}: RecommendedCoachCardProps) => {
  const {
    user: { id, name, last_name },
    tags,
    rating,
    num_ratings,
    profile_image_url,
    percent_match,
  } = coachCardContent
  const { selectedCoach, selectedCoachIndex, setPlayVideo } = useCoachingStore()
  const { onOpen } = useRecommendedCoachModalStore()
  const router = useRouter()

  const onCardClick = () => {
    if (router.query.from === "preview")
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_CARD_CLICK, {
        coach_id: id,
      })
    else
      trackAnalyticEvent(ANALYTIC_EVENTS.PERSONALIZED_COACH_CARD_CLICK, {
        coach_id: id,
      })
    setPlayVideo(true)
    if (selectedCoach?.user.id === id) onOpen()
  }

  const isMobile = useMobile()
  const isSmallMobile = useMediaQuery("(max-width: 350px)")

  const credentials = tags
    .filter((tag) => tag.category === CoachTagCategory.CREDENTIALS)
    .map((tag) => tag.name)

  const formattedPercentMatch = ((percent_match || 0) * 100).toFixed(0)

  if (!coachCardContent) return <Skeleton />

  return (
    <button
      onClick={onCardClick}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        width: "min(88%, 220px)",
        minHeight: "240px",
        maxHeight: "240px",
        borderRadius: SPACING.space3,
        cursor: selectedCoach?.user.id === id ? "pointer" : "default",
        userSelect: "none",
      }}
      disabled={selectedCoach?.user.id !== id}
    >
      {profile_image_url && (
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100%",
            maxHeight: "100%",
            minWidth: "100%",
            maxWidth: "100%",
          }}
        >
          <FlexBox
            direction="row"
            justify="center"
            align="center"
            wrap={false}
            gap={SPACING.space1}
            style={{
              width: "fit-content",
              position: "absolute",
              top: "8px",
              left: "8px",
              background: JoonUIColor.background.accent,
              borderRadius: SPACING.space4,
              padding: `${SPACING.space1} ${SPACING.space2}`,
              opacity: selectedCoach?.user.id === id ? 1 : 0,
              zIndex: 1,
              transition: "opacity 0.3s ease-in-out",
            }}
          >
            {selectedCoachIndex === 0 && (
              <div style={{ width: 14, height: 16 }}>
                <StarIcon size={14} color={JoonUIColor.icon.inverted} />
              </div>
            )}
            <Typography variant="bodyXSmall" color={JoonUIColor.text.inverted}>
              {formattedPercentMatch}%{" "}
              {selectedCoachIndex === 0 ? "Top Match!" : "Match"}
            </Typography>
          </FlexBox>
          <img
            src={profile_image_url ?? ""}
            style={{
              ...(selectedCoach?.user.id === id
                ? {
                    border: `3px solid ${JoonColorExpanded.yellow400}`,
                    opacity: 1,
                    transform: "scaleY(1) scaleX(1)",
                  }
                : {
                    border: "3px solid transparent",
                    opacity: 0.5,
                    transform: "scaleY(0.9) scaleX(0.9)",
                  }),
              height: "240px",
              width: "100%",
              objectFit: "cover",
              objectPosition: "center",
              borderRadius: SPACING.space4,
              transformOrigin: "center",
              transition: "all 0.3s ease-in-out",
            }}
            alt="Coach Profile"
          />
          <FlexBox
            justify="center"
            align="center"
            style={{
              position: "absolute",
              width: "50px",
              height: "50px",
              padding: SPACING.space4,
              paddingLeft: SPACING.space5,
              background: "rgba(27, 31, 37, 0.80)",
              borderRadius: "50%",
            }}
          >
            <CaretRightIcon size={16} color={"#5E65E9"} />
          </FlexBox>
        </div>
      )}

      <img
        src="/images/coach_profile_background.svg"
        alt="Coach Profile"
        style={{
          position: "absolute",
          bottom: isSmallMobile ? "-32px" : "-72px",
          left: "-5%",
          width: "110%",
          transform:
            selectedCoach?.user.id === id
              ? "scaleY(1) scaleX(1)"
              : "scaleY(0.9) scaleX(0.9)",
          transformOrigin: "top",
          transition: "all 0.3s ease-in-out",
        }}
      />

      <FlexBox
        direction="column"
        gap={isMobile ? SPACING.space1 : SPACING.space2}
        align="center"
        wrap={false}
        fullWidth
        style={{
          zIndex: 1,
          position: "absolute",
          bottom: isSmallMobile ? "-24px" : isMobile ? "-56px" : "-64px",
          opacity: selectedCoach?.user.id === id ? 1 : 0.5,
          transform:
            selectedCoach?.user.id === id
              ? "scaleY(1) scaleX(1)"
              : "scaleY(0.9) scaleX(0.9)",
          transformOrigin: "top",
          transition: "all 0.3s ease-in-out",
        }}
      >
        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          fullWidth
          wrap={false}
        >
          <Typography
            variant={isSmallMobile ? "bodySmall" : "h2"}
            style={{
              textAlign: "left",
              fontWeight: 700,
              whiteSpace: "nowrap",
            }}
          >
            {name} {last_name ? `${last_name[0]}.` : ""}
          </Typography>
          <FlexBox
            direction="row"
            align="center"
            gap={SPACING.space05}
            wrap={false}
            justify="flex-end"
            style={{
              width: "fit-content",
              textAlign: "left",
              whiteSpace: "nowrap",
            }}
          >
            <div style={{ width: 18, height: 21 }}>
              <StarIcon size={18} color={JoonColorExpanded.yellow400} />
            </div>
            <Typography
              variant={
                isSmallMobile ? "bodyXSmall" : isMobile ? "caption" : "bodyBold"
              }
              style={{ fontWeight: 700 }}
            >
              {rating.toFixed(1)} ({num_ratings})
            </Typography>
          </FlexBox>
        </FlexBox>

        <FlexBox
          direction="column"
          gap={isMobile ? SPACING.space05 : SPACING.space1}
        >
          {[...new Array(2)].map((_, i) => {
            const credential = credentials?.[i] || ""
            return (
              <Typography
                key={i}
                variant={isMobile ? "bodyXSmall" : "bodySmall"}
                color={JoonColorExpanded.purple600}
                textAlign="left"
                style={{ height: isMobile ? "1rem" : "1.25rem" }}
              >
                {credential}
              </Typography>
            )
          })}
        </FlexBox>
      </FlexBox>
    </button>
  )
}

export default RecommendedCoachCard
