import {
  FlexBox,
  JoonUIColor,
  SPACING,
  TherapistIcon,
  Typography,
} from "@joonapp/web-shared"

import { useDisplayParentTaskSection } from "./useDisplayParentTasksSection"
import AcademyActions from "../../academy/AcademyActions"

const ParentTasksSection = () => {
  const { displayParentTasksSection } = useDisplayParentTaskSection()

  if (!displayParentTasksSection) return <></>

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space4}
      align="center"
      style={{
        background:
          "linear-gradient(108deg, rgba(255, 255, 255, 0.60) -6.4%, rgba(255, 255, 255, 0.80) 20.05%, rgba(255, 255, 255, 0.70) 69.45%, rgba(255, 255, 255, 0.60) 111.48%)",
        borderRadius: SPACING.space4,
        padding: SPACING.space4,
      }}
    >
      <FlexBox direction="row" align="center" gap={SPACING.space2} wrap={false}>
        <TherapistIcon size={20} color={JoonUIColor.background.accent} />
        <Typography variant="bodyBold">
          Your tasks from your child's therapist
        </Typography>
      </FlexBox>
      <AcademyActions />
    </FlexBox>
  )
}

export default ParentTasksSection
