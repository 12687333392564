import {
  Checkbox,
  SegmentedControl,
  TextInput,
  Typography,
  FlexBox,
  SPACING,
  Switch,
  ChevronRightIcon,
  JoonUIColor,
  Button,
  QuestRoutine,
  Modal,
  Slider,
  SelectInput,
  ModalHeader,
  ModalFooter,
  ModalContent,
  InfoIcon,
  JoonColorExpanded,
} from "@joonapp/web-shared"
import { useEffect, useRef } from "react"
import { useShallow } from "zustand/react/shallow"

import SchedulePicker from "./SchedulePicker"
import { useAddEditQuestModal } from "./useAddEditQuest"
import { useAddEditQuestStore } from "./useAddEditQuestStore"
import InputSectionBox from "../../../components/inputSectionBox/InputSectionBox"
import TimeInput from "../../../components/timeInput/TimeInput"
import TimerInput from "../../../components/timerInput/TimerInput"
import { SKILL_DROPDOWN } from "../../../constants"
import { useFamilyQuery } from "../../../networking/queries"
import { FamilyQuerySelect } from "../../../types"

const AddEditQuestModal = () => {
  const {
    questName,
    description,
    coinReward,
    category,
    routine,
    routines,
    reminderTime,
    timerLength,
    assignedChildren,
    mandatory,
    reqReview,
    reqNotes,
    reqPhoto,
    saveTemplate,

    setQuestName,
    setDescription,
    setCoinReward,
    setCategory,
    toggleRoutine,
    setRoutine,
    setReminderTime,
    setTimerLength,
    toggleChild,
    setMandatory,
    setReqReview,
    setReqNotes,
    setReqPhoto,
    setSaveTemplate,

    setModalContentRef,
    resetQuestDetails,

    isOpen,
    onClose,

    errors,

    selectedQuest,

    isOpenAdditionalOptions,
    setIsOpenAdditionalOptions,
    setAdditionalOptionsRef,
  } = useAddEditQuestStore(
    useShallow((state) => ({
      questName: state.questName,
      description: state.description,
      coinReward: state.coinReward,
      category: state.category,
      routine: state.routine,
      routines: state.routines,
      reminderTime: state.reminderTime,
      timerLength: state.timerLength,
      assignedChildren: state.assignedChildren,
      mandatory: state.mandatory,
      reqReview: state.reqReview,
      reqNotes: state.reqNotes,
      reqPhoto: state.reqPhoto,
      saveTemplate: state.saveTemplate,
      setQuestName: state.setQuestName,
      setDescription: state.setDescription,
      setCoinReward: state.setCoinReward,
      setCategory: state.setCategory,
      toggleRoutine: state.toggleRoutine,
      setRoutine: state.setRoutine,
      setReminderTime: state.setReminderTime,
      setTimerLength: state.setTimerLength,
      toggleChild: state.toggleChild,
      setMandatory: state.setMandatory,
      setReqReview: state.setReqReview,
      setReqNotes: state.setReqNotes,
      setReqPhoto: state.setReqPhoto,
      setSaveTemplate: state.setSaveTemplate,
      setModalContentRef: state.setModalContentRef,
      resetQuestDetails: state.resetQuestDetails,
      isOpen: state.isOpen,
      onClose: state.onClose,
      errors: state.errors,
      selectedQuest: state.selectedQuest,
      isOpenAdditionalOptions: state.isOpenAdditionalOptions,
      setIsOpenAdditionalOptions: state.setIsOpenAdditionalOptions,
      setAdditionalOptionsRef: state.setAdditionalOptionsRef,
    }))
  )

  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const modalContentRef = useRef<any>(null)
  const additionalOptionsRef = useRef<any>(null)
  const { addQuestMutation, editQuestMutation } = useAddEditQuestModal()
  const isEditing = !!selectedQuest
  const hasFrequencyTarget = !!selectedQuest?.series.current_frequency_target

  useEffect(() => {
    setModalContentRef(modalContentRef)
    setAdditionalOptionsRef(additionalOptionsRef)
  }, [setModalContentRef, setAdditionalOptionsRef])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        resetQuestDetails()
      }}
      mobileFull={true}
      animate
    >
      <>
        <ModalHeader
          title={isEditing ? "Edit Quest" : "Create Quest"}
          onClose={() => {
            onClose()
            resetQuestDetails()
          }}
          style={{ borderBottom: `1px solid ${JoonUIColor.border.default}` }}
        />
        <ModalContent>
          <FlexBox
            direction="column"
            gap={SPACING.space6}
            style={{
              padding: `${SPACING.space4} ${SPACING.space6}`,
              width: "min(500px, 100vw)",
              margin: "0 auto",
            }}
          >
            {hasFrequencyTarget && (
              <FlexBox
                style={{
                  borderRadius: SPACING.space2,
                  border: `1px solid ${JoonUIColor.semantic.info}`,
                  padding: SPACING.space2,
                  background: JoonColorExpanded.blue100,
                }}
                align="center"
                gap={SPACING.space2}
              >
                <InfoIcon color={JoonUIColor.semantic.info} size={16} />
                <Typography variant="caption" color={JoonUIColor.semantic.info}>
                  * Some fields are not editable on therapist-modified quests.
                </Typography>
              </FlexBox>
            )}
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="bodyBold">Quest name</Typography>
              {isEditing && hasFrequencyTarget ? (
                <Typography variant="bodyBold">{questName}</Typography>
              ) : (
                <TextInput
                  name="Quest Name"
                  value={questName}
                  placeholder="Make your bed in the morning"
                  onChange={(e: any) => setQuestName(e.target.value)}
                  maxLength={100}
                  fullWidth
                />
              )}
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space2}>
              <Typography variant="bodyBold">
                How difficult is it to motivate your child to complete this
                Quest?
              </Typography>
              <Slider
                value={coinReward}
                options={[1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 17, 20, 23, 26, 30]}
                setValue={setCoinReward}
                minLabel="Not Difficult"
                maxLabel="Very Difficult"
              />
            </FlexBox>
            <InputSectionBox>
              <FlexBox
                direction="column"
                gap={SPACING.space2}
                align="center"
                justify="center"
              >
                <Typography variant="caption">
                  For completing this quest, your child will earn:
                </Typography>
                <CoinRewardBadge coinReward={coinReward} />
              </FlexBox>
            </InputSectionBox>
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="bodyBold">Category</Typography>
              <SelectInput
                name="Skill Category"
                options={SKILL_DROPDOWN}
                selectedOption={
                  SKILL_DROPDOWN.find(
                    (skill) => skill.value === category
                  ) as any
                }
                isMulti={false}
                setSelectedOption={(option: any) => setCategory(option.value)}
                style={{ fontWeight: "normal" }}
                fullWidth
                placeholder="Select category"
              />
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="bodyBold">Quest assignment</Typography>
              <FlexBox gap={SPACING.space1}>
                {children
                  ?.filter((child) =>
                    isEditing ? assignedChildren.includes(child.user.id) : true
                  )
                  .map((child) => (
                    <Checkbox
                      name={child.user.name}
                      label={child.user.name}
                      key={child.user.id}
                      onChange={() => toggleChild(child.user.id)}
                      selected={assignedChildren.includes(child.user.id)}
                    />
                  ))}
              </FlexBox>
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="bodyBold">
                When should your child do this Quest?
              </Typography>
              <FlexBox gap={SPACING.space1}>
                <Checkbox
                  name="Morning"
                  label="Morning"
                  onChange={() =>
                    isEditing
                      ? setRoutine(QuestRoutine.MORNING)
                      : toggleRoutine(QuestRoutine.MORNING)
                  }
                  selected={
                    isEditing
                      ? routine === QuestRoutine.MORNING
                      : routines.includes(QuestRoutine.MORNING)
                  }
                />
                <Checkbox
                  name="Afternoon"
                  label="Afternoon"
                  onChange={() =>
                    isEditing
                      ? setRoutine(QuestRoutine.AFTERNOON)
                      : toggleRoutine(QuestRoutine.AFTERNOON)
                  }
                  selected={
                    isEditing
                      ? routine === QuestRoutine.AFTERNOON
                      : routines.includes(QuestRoutine.AFTERNOON)
                  }
                />
                <Checkbox
                  name="Night"
                  label="Night"
                  onChange={() =>
                    isEditing
                      ? setRoutine(QuestRoutine.NIGHT)
                      : toggleRoutine(QuestRoutine.NIGHT)
                  }
                  selected={
                    isEditing
                      ? routine === QuestRoutine.NIGHT
                      : routines.includes(QuestRoutine.NIGHT)
                  }
                />
                <Checkbox
                  name="Anytime"
                  label="Anytime"
                  onChange={() =>
                    isEditing
                      ? setRoutine(QuestRoutine.ANYTIME)
                      : toggleRoutine(QuestRoutine.ANYTIME)
                  }
                  selected={
                    isEditing
                      ? routine === QuestRoutine.ANYTIME
                      : routines.includes(QuestRoutine.ANYTIME)
                  }
                />
              </FlexBox>
            </FlexBox>
            <SchedulePicker />
            <FlexBox direction="column" gap={SPACING.space2}>
              <FlexBox gap={SPACING.space2} wrap={false} align="center">
                <Switch
                  checked={reqReview}
                  name="requiresReview"
                  onChange={() => setReqReview(!reqReview)}
                />
                <Typography variant="bodyBold">
                  I would like to review this Quest
                </Typography>
              </FlexBox>
              {reqReview && (
                <InputSectionBox>
                  <FlexBox direction="column" gap={SPACING.space2}>
                    <FlexBox gap={SPACING.space2} wrap={false} align="center">
                      <Switch
                        disabled={!reqReview}
                        checked={reqPhoto}
                        name="requiresPhoto"
                        onChange={() => setReqPhoto(!reqPhoto)}
                      />
                      <Typography variant="bodyBold">
                        Require completion photo
                      </Typography>
                    </FlexBox>
                    <FlexBox gap={SPACING.space2} align="center">
                      <Switch
                        disabled={!reqReview}
                        checked={reqNotes}
                        name="requiresNotes"
                        onChange={() => setReqNotes(!reqNotes)}
                      />
                      <Typography variant="bodyBold">
                        Require completion notes
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                </InputSectionBox>
              )}
            </FlexBox>
            <hr />
            <button
              className={`toggle-dropdown ${
                isOpenAdditionalOptions ? "toggle-dropdown-open" : ""
              }`}
              onClick={() =>
                setIsOpenAdditionalOptions(!isOpenAdditionalOptions)
              }
            >
              <FlexBox gap={SPACING.space2} wrap={false} align="center">
                <ChevronRightIcon color={JoonUIColor.text.primary} />
                <Typography variant="bodyBold">Additional Options</Typography>
              </FlexBox>
            </button>
            {isOpenAdditionalOptions && (
              <FlexBox direction="column" gap={SPACING.space6}>
                <FlexBox direction="column" gap={SPACING.space1}>
                  <Typography variant="bodyBold">Quest Type</Typography>
                  <SegmentedControl
                    options={[
                      { label: "Mandatory", value: true },
                      { label: "Optional", value: false },
                    ]}
                    value={mandatory}
                    setValue={(value) => setMandatory(value as boolean)}
                    disabled={hasFrequencyTarget}
                    fullWidth
                  />
                  {mandatory && (
                    <Typography
                      variant="bodySmall"
                      color={JoonUIColor.semantic.primary}
                    >
                      Quest completion is required before your child is able to
                      access the game part of Joon.
                    </Typography>
                  )}
                </FlexBox>
                <FlexBox direction="column" gap={SPACING.space1}>
                  <Typography variant="bodyBold">
                    Set a specific time deadline
                  </Typography>
                  <Typography
                    variant="bodySmall"
                    color={JoonUIColor.semantic.primary}
                  >
                    Joon will send a reminder to your child automatically{" "}
                  </Typography>
                  <TimeInput
                    modalTitle="Time Due"
                    time={reminderTime}
                    setTime={setReminderTime}
                    placeholder="Set a time"
                    name="time"
                    fullWidth
                  />
                </FlexBox>
                <FlexBox direction="column" gap={SPACING.space1}>
                  <Typography variant="bodyBold">Add a timer</Typography>
                  <Typography
                    variant="bodySmall"
                    color={JoonUIColor.semantic.primary}
                  >
                    Your child will be able to set a timer to help them focus on
                    the task at hand
                  </Typography>

                  <TimerInput
                    modalTitle="Timer Length"
                    time={timerLength}
                    setTime={setTimerLength}
                    placeholder="Set a time"
                    name="time"
                    disabled={false}
                    fullWidth
                  />
                </FlexBox>
                <FlexBox direction="column" gap={SPACING.space2}>
                  <Typography variant="bodyBold">
                    Add a Quest description
                  </Typography>
                  <TextInput
                    placeholder="Add a description"
                    fullWidth
                    name="time"
                    value={description || ""}
                    onChange={(e: any) => setDescription(e.target.value)}
                  />
                </FlexBox>
                <div ref={additionalOptionsRef} />
                {!isEditing && (
                  <FlexBox gap={SPACING.space2} wrap={false} align="center">
                    <Switch
                      checked={saveTemplate}
                      name="saveTemplate"
                      onChange={() => setSaveTemplate(!saveTemplate)}
                    />
                    <Typography variant="bodyBold">
                      Save this Quest as a template
                    </Typography>
                  </FlexBox>
                )}
              </FlexBox>
            )}
          </FlexBox>
        </ModalContent>
        <ModalFooter>
          <div className="quest-modal-button-wrapper">
            <FlexBox
              direction="column"
              align="center"
              style={{ width: "fit-content" }}
            >
              {Object.values(errors).map((error) => (
                <Typography
                  variant="caption"
                  color={JoonUIColor.semantic.alert}
                >
                  {error}
                </Typography>
              ))}
            </FlexBox>
            <Button
              onClick={() =>
                isEditing
                  ? editQuestMutation.mutate()
                  : addQuestMutation.mutate()
              }
              isLoading={
                isEditing
                  ? editQuestMutation.isLoading
                  : addQuestMutation.isLoading
              }
              text={isEditing ? "Save" : "Create Quest"}
              fullWidth
            />
          </div>
        </ModalFooter>
      </>
    </Modal>
  )
}

export default AddEditQuestModal

const CoinRewardBadge = ({ coinReward }: { coinReward: number }) => {
  return (
    <div className="coin-reward-badge">
      <div>{coinReward}</div>
      <img src="/images/icons/coin-icon.png" alt="coin" />
    </div>
  )
}
