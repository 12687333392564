import { UserRole } from "@joonapp/web-shared"

export interface SessionBooking {
  id: number
  start_datetime: string // ISOstring format
  end_datetime: string // ISOstring format
  reschedule_link: string | null
  group_id: number
  cancel_link: string | null
  meeting_link: string | null
}

export interface SessionBillingPeriod {
  id: number
  start_datetime: string // ISOstring format
  end_datetime: string // ISOstring format
  bookings: SessionBooking[]
  max_bookings: number
  booking_link: string | null
}

export enum SessionBookingLinkType {
  SCHEDULE,
  RESCHEDULE,
  CANCEL,
}
export interface SessionNote {
  id: number
  note: string
  visibility_role: UserRole | null
}

export interface SessionBookingWithExtras extends SessionBooking {
  topic_to_discuss_notes: SessionNote
  session_summary_notes: SessionNote | null
  session_notes: SessionNote | null
}
