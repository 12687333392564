import { Button, JoonUIColor, SPACING } from "@joonapp/web-shared"

import { useRewardQuantityModal } from "./RewardQuantityModal"
import { useRewardsChildToggleHeaderStore } from "./RewardsChildToggleHeader"
import { RewardPurchase } from "../../../types/rewards"

type Props = {
  rewards: RewardPurchase[]
  childId?: number
}

const RewardRedeemButton = ({ rewards, childId }: Props) => {
  const { onOpen: onOpenQuantityModal } = useRewardQuantityModal()
  const { setSelectedChildId } = useRewardsChildToggleHeaderStore()

  const onRedeemClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    childId && setSelectedChildId(childId)
    onOpenQuantityModal(rewards)
  }

  return (
    <Button
      buttonType="secondary"
      size="small"
      onClick={onRedeemClick}
      text="Redeem"
      style={{
        color: JoonUIColor.text.primary,
        borderRadius: SPACING.space2,
        border: `1px solid ${JoonUIColor.border.default}`,
        padding: `${SPACING.space1} ${SPACING.space2}`,
        overflow: "visible",
        height: "auto",
      }}
    />
  )
}

export default RewardRedeemButton
