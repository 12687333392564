import "./pageWrapper.scss"
import React, { forwardRef } from "react"

// WILL BE MOVING THESE TO LIBRARY ONCE STABLE

interface PageWrapperProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
  style?: React.CSSProperties
}

const PageWrapper = forwardRef<HTMLDivElement, PageWrapperProps>(
  ({ children, style }, ref) => (
    <div className="jooniverse__page-wrapper" style={style} ref={ref}>
      {children}
    </div>
  )
)

PageWrapper.displayName = "PageWrapper"

export default PageWrapper
