import { useQuery } from "@tanstack/react-query"

import { getCoachingGroups } from "./api"
import { QUERY_KEYS } from "../../constants"

export const useCoachingGroupsQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.COACHING_GROUPS],
    queryFn: getCoachingGroups,
  })
}
