import {
  Button,
  Checkbox,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import { useDeleteRewardModalStore } from "./DeleteRewardModal"
import { RewardEmojiPicker, RewardEmojiPickerButton } from "./RewardEmojiPicker"
import { QUERY_KEYS } from "../../constants"
import { useFamilyQuery } from "../../networking/queries"
import { createCustomReward, editCustomReward } from "../../networking/rewards"
import { FamilyQuerySelect } from "../../types"
import { CustomReward } from "../../types/rewards"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

interface AddEditRewardModalStore {
  isOpen: boolean
  onOpen: (reward?: CustomReward) => void
  onClose: () => void

  rewardId: number | null
  templateId: number | null

  title: string
  setTitle: (title: string) => void
  cost: number | null
  setCost: (cost: number) => void
  emoji: string | null
  setEmoji: (emoji: string) => void
  selectedChildren: number[]
  toggleSelectedChild: (childId: number) => void
}

export const useAddEditRewardModal = create<AddEditRewardModalStore>(
  (set, get) => ({
    isOpen: false,
    onOpen: (reward) => {
      if (reward)
        set({
          rewardId: reward.user_ids ? reward.id : null,
          templateId: reward.user_ids ? null : reward.id,
          title: reward.title,
          cost: reward.cost,
          emoji: reward.emoji,
          selectedChildren: reward.user_ids || [],
        })
      set({ isOpen: true })
    },
    onClose: () =>
      set({
        isOpen: false,
        title: "",
        cost: null,
        emoji: null,
        selectedChildren: [],
        rewardId: null,
        templateId: null,
      }),

    rewardId: null,
    templateId: null,

    title: "",
    cost: null,
    emoji: null,
    selectedChildren: [],

    setTitle: (title: string) => set({ title: title }),
    setCost: (cost: number) => set({ cost: cost }),
    setEmoji: (emoji: string) => set({ emoji: emoji }),
    toggleSelectedChild: (childId: number) => {
      const { selectedChildren } = get()
      if (selectedChildren.includes(childId))
        set({
          selectedChildren: selectedChildren.filter((id) => id !== childId),
        })
      else set({ selectedChildren: [...selectedChildren, childId] })
    },
  })
)

const AddEditRewardModal = () => {
  const {
    isOpen,
    onClose,
    title,
    cost,
    emoji,
    rewardId,
    selectedChildren,
    setTitle,
    setCost,
    setEmoji,
    toggleSelectedChild,
  } = useAddEditRewardModal()
  const [errorText, setErrorText] = useState("")
  const { onOpen: openDeleteRewardModal } = useDeleteRewardModalStore()
  const queryClient = useQueryClient()

  const isEditing = !!rewardId
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)

  const onClickAddReward = () => {
    if (!title) return setErrorText("Please enter a title")
    if (!cost) return setErrorText("Please enter a price for your reward")
    if (!emoji) return setErrorText("Please select a reward icon")
    if (selectedChildren.length === 0)
      return setErrorText("Please select at least one child")
    if (isEditing && !rewardId) throw new Error("Reward ID not found")
    setErrorText("")

    if (isEditing) {
      trackAnalyticEvent(ANALYTIC_EVENTS.EDIT_REWARD)
      editRewardMutation.mutate({
        rewardInfo: {
          title: title,
          cost: cost,
          emoji: emoji,
          user_ids: selectedChildren,
        },
        rewardId: rewardId as number,
      })
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.ADD_REWARD)
      addRewardMutation.mutate({
        title: title,
        cost: cost,
        emoji: emoji,
        user_ids: selectedChildren,
      })
    }
  }

  const editRewardMutation = useMutation({
    mutationFn: editCustomReward,
    onSuccess: () => {
      onClose()
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
      queryClient.invalidateQueries([QUERY_KEYS.PURCHASED_REWARDS])
      toast.success("Reward updated successfully!")
    },
  })

  const addRewardMutation = useMutation({
    mutationFn: createCustomReward,
    onSuccess: () => {
      onClose()
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
      toast.success("Reward created successfully!")
    },
  })

  return (
    <>
      <RewardEmojiPicker setEmoji={setEmoji} />
      <Modal onClose={onClose} isOpen={isOpen} displayCloseIcon animate>
        <div className="quest-modal">
          <div
            className="quest-modal-content small"
            style={{
              padding: `${SPACING.space4} ${SPACING.space6}`,
            }}
          >
            <div
              className="quest-modal-header section-title"
              style={{ width: "fit-content", textAlign: "left" }}
            >
              {isEditing ? "Edit reward" : "Create new reward"}
            </div>
            <RewardEmojiPickerButton emoji={emoji} />
            <Typography
              variant="caption"
              style={{ marginBottom: SPACING.space1 }}
            >
              Reward Name
            </Typography>
            <TextInput
              name="Reward Title"
              maxLength={140}
              value={title}
              placeholder="Ice cream with mom!"
              onChange={(e) => setTitle(e.target.value)}
              style={{ marginBottom: "12px" }}
              fullWidth
            />
            <Typography
              variant="caption"
              style={{ marginBottom: SPACING.space1 }}
            >
              Price (in coins)
            </Typography>
            <TextInput
              name="Price"
              value={cost ? `${cost}` : ""}
              placeholder="100"
              maxLength={6}
              onChange={(e) => {
                if (isNaN(Number(e.target.value))) return
                setCost(e.target.value as any)
              }}
              style={{ marginBottom: "4px" }}
              fullWidth
            />
            <div className="body-text2 italic-text mb-12">
              * Coin value reminder: An easy Quest rewards 4 coins, medium 10
              coins, hard 20 coins.
            </div>

            <div className="quest-section-title">Available for</div>
            <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
              {children?.map((child) => (
                <Checkbox
                  name={child.user.name}
                  key={child.user.id}
                  label={child.user.name}
                  onChange={() => toggleSelectedChild(child.user.id)}
                  selected={selectedChildren.includes(child.user.id)}
                />
              ))}
            </div>
            <div
              className="error-text mt-24 mb-12"
              style={{ width: "100%", textAlign: "center" }}
            >
              {errorText}
            </div>
            <FlexBox
              direction="row"
              justify="space-between"
              align="center"
              fullWidth
            >
              {isEditing && (
                <TextButton
                  onClick={() => {
                    openDeleteRewardModal(rewardId as number)
                    onClose()
                  }}
                  style={{ width: "16%" }}
                >
                  <Typography
                    variant="caption"
                    color={JoonUIColor.semantic.alert}
                  >
                    Delete
                  </Typography>
                </TextButton>
              )}
              <Button
                text={isEditing ? "Save changes" : "Create"}
                isLoading={
                  addRewardMutation.isLoading || editRewardMutation.isLoading
                }
                onClick={onClickAddReward}
                style={{ width: isEditing ? "80%" : "100%" }}
              />
            </FlexBox>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AddEditRewardModal
