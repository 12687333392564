import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import { useRedeemedRewardsModal } from "./redeem/RedeemedRewardsModal"
import RewardRedeemCard from "./redeem/RewardRedeemCard"
import RewardsChildToggleHeader, {
  useRewardsChildToggleHeaderStore,
} from "./redeem/RewardsChildToggleHeader"
import TotalCoinsEarnedCard from "./redeem/TotalCoinsEarnedCard"
import {
  useCustomRewardsQuery,
  useFamilyQuery,
  usePurchasedRewardsQuery,
} from "../../networking/queries"
import { FamilyQuerySelect } from "../../types"
import { RewardPurchase } from "../../types/rewards"

interface GroupedPurchasedRewards {
  count: number
  rewardId: number
  rewards: RewardPurchase[]
}

const groupPurchasedRewardsByRewardId = (
  purchasedRewards: RewardPurchase[],
  selectedChildId: number
): GroupedPurchasedRewards[] => {
  const groupMap: Record<number, GroupedPurchasedRewards> = {}

  purchasedRewards.forEach((purchase) => {
    if (purchase.user_id !== selectedChildId) return

    const rewardId = purchase.reward.id
    if (!groupMap[rewardId]) {
      groupMap[rewardId] = {
        count: 1,
        rewardId,
        rewards: [purchase],
      }
    } else {
      groupMap[rewardId].rewards.push(purchase)
      groupMap[rewardId].count = groupMap[rewardId].rewards.length
    }
  })

  return Object.values(groupMap)
}

const RewardsRedeem = () => {
  const [groupedPurchasedRewards, setGroupedPurchasedRewards] = useState<
    GroupedPurchasedRewards[]
  >([])
  const { onOpen } = useRedeemedRewardsModal()
  const { selectedChildId } = useRewardsChildToggleHeaderStore()
  const { data: rewards, status: rewardsStatus } = useCustomRewardsQuery()
  const { data: purchasedRewards, status: purchasedRewardsStatus } =
    usePurchasedRewardsQuery({ hasRedeemed: false })
  const { data: redeemedRewards, status: redeemedRewardsStatus } =
    usePurchasedRewardsQuery({ hasRedeemed: true })
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)

  const selectedChild = children?.find(
    (child) => child.user.id === selectedChildId
  )

  const childrenHaveCoinBalance = children?.some(
    (child) => child.user.coins > 0
  )

  const hasPreviouslyRedeemedRewards =
    redeemedRewards &&
    redeemedRewards.filter((reward) => reward?.user_id === selectedChildId)
      .length > 0

  const hasPurchasedRewards =
    groupedPurchasedRewards.filter(
      (group) =>
        group.rewards.length > 0 &&
        group.rewards.find((reward) => reward.user_id === selectedChildId)
    ).length > 0

  const hasAvailableRewardsToPurchase =
    selectedChildId !== null &&
    rewards?.some((reward) => reward.user_ids.includes(selectedChildId))

  const isLoading =
    purchasedRewardsStatus === "loading" ||
    redeemedRewardsStatus === "loading" ||
    rewardsStatus === "loading"

  useEffect(() => {
    if (selectedChildId === null || purchasedRewards === undefined) return
    setGroupedPurchasedRewards(
      groupPurchasedRewardsByRewardId(purchasedRewards, selectedChildId)
    )
  }, [purchasedRewards, selectedChildId])

  return (
    <div style={{ paddingTop: SPACING.space3, paddingBottom: SPACING.space3 }}>
      {isLoading ? (
        <></>
      ) : childrenHaveCoinBalance || (purchasedRewards?.length ?? -1 > 0) ? (
        <FlexBox direction="column" gap={SPACING.space4}>
          <RewardsChildToggleHeader />
          {selectedChildId && (
            <TotalCoinsEarnedCard childId={selectedChildId} />
          )}
          <Typography variant="bodyBold">Owned Rewards</Typography>

          {hasPurchasedRewards ? (
            <FlexBox direction="column" gap={SPACING.space1}>
              {groupedPurchasedRewards
                ?.filter((group) => group.rewards.length > 0)
                ?.map((group: GroupedPurchasedRewards, i: number) => {
                  return (
                    <RewardRedeemCard
                      key={i}
                      purchaseCount={group.count}
                      rewards={group.rewards}
                      title={`${group.rewards[0].reward.emoji} ${group.rewards[0].reward.title}`}
                    />
                  )
                })}
            </FlexBox>
          ) : hasAvailableRewardsToPurchase ? (
            <FlexBox
              direction="column"
              justify="center"
              align="center"
              style={{
                padding: `${SPACING.space3} ${SPACING.space4}`,
                background: JoonUIColor.background.primaryNeutral,
                border: `1px solid ${JoonUIColor.border.default}`,
                borderRadius: SPACING.space2,
              }}
            >
              <Typography
                variant="bodyXSmall"
                style={{ fontWeight: 700, textAlign: "center" }}
              >
                {selectedChild?.user.name} has not purchased any rewards yet!
              </Typography>
              <Typography variant="bodyXSmall" style={{ textAlign: "center" }}>
                They can purchase some by clicking on the teleportation device
                under their doter and talking to your avatar at the shop
                counter!
              </Typography>
            </FlexBox>
          ) : (
            <FlexBox
              justify="center"
              align="center"
              style={{
                padding: SPACING.space3,
                background: JoonUIColor.background.primaryNeutral,
                border: `1px solid ${JoonUIColor.border.default}`,
                borderRadius: SPACING.space2,
              }}
            >
              <Typography variant="caption">
                No custom rewards set up yet!
              </Typography>
            </FlexBox>
          )}
        </FlexBox>
      ) : (
        <FlexBox justify="center" style={{ margin: "auto" }}>
          <Typography
            variant="caption"
            textAlign="center"
            style={{
              width: "min(380px, 95vw",
              background: "white",
              padding: SPACING.space6,
              borderRadius: SPACING.space2,
            }}
          >
            Your family hasn't purchased any rewards yet. They can purchase some
            by clicking on the teleportation device under their doter and
            talking to your avatar at the shop counter!
          </Typography>
        </FlexBox>
      )}
      {!isLoading && hasPreviouslyRedeemedRewards && (
        <button
          style={{ marginTop: SPACING.space6, width: "100%" }}
          onClick={onOpen}
        >
          <Typography
            variant="caption"
            textAlign="center"
            color={JoonUIColor.semantic.primary}
          >
            View previously redeemed rewards
          </Typography>
        </button>
      )}
    </div>
  )
}

export default RewardsRedeem
