import {
  CalendarDaysIcon,
  ChatIcon,
  JoonUIColor,
  ReceiptIcon,
  SPACING,
  Typography,
  VideoIcon,
  WandMagicSparklesIcon,
} from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"
import { useState } from "react"
import toast from "react-hot-toast"

import TextArrowButton from "../../../../components/coaching/TextArrowButton"
import { COACH_PLANS } from "../../../../constants"
import useMobile from "../../../../hooks/useMobile"
import { useCoachSubscriptionPackagesQuery } from "../../../../networking/queries"
import { getCoachingStripeCheckoutUrl } from "../../../../networking/subscriptions"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { useCoachingStore } from "../../useCoachingStore"

type PlanCarouselCardProps = {
  index: number
}

const PlanCarouselCard = ({ index }: PlanCarouselCardProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { selectedCoach } = useCoachingStore()
  const { data: subscriptionPackages } = useCoachSubscriptionPackagesQuery()
  const isMobile = useMobile()
  const { plan, frequency } = COACH_PLANS[index]
  const firstMonthPriceInCents = subscriptionPackages?.first_month_unit_amount
  const priceInCents = subscriptionPackages?.unit_amount ?? 0

  const formattedCentsToDollars = (cents: number) =>
    Number((cents / 100).toFixed(2))

  const priceForToday = formattedCentsToDollars(
    firstMonthPriceInCents ?? priceInCents
  )
  const fullPrice = formattedCentsToDollars(
    firstMonthPriceInCents ? priceInCents : priceInCents * 2
  )

  const priceLabel =
    firstMonthPriceInCents !== null
      ? "First month. Cancel any time."
      : "Early bird special!"

  const infoCards = [
    {
      Icon: CalendarDaysIcon,
      text:
        firstMonthPriceInCents !== null
          ? `$${fullPrice}/${subscriptionPackages?.interval || "month"}\nafter your first\n${subscriptionPackages?.interval || "month"}.`
          : `Get in early to\nlock your $${priceForToday}/\n${subscriptionPackages?.interval || "month"} forever!`,
    },
    {
      Icon: ReceiptIcon,
      text: "Love it or get\na full refund\nwithin 30 days",
    },
  ]

  const onContinueClick = async () => {
    if (!selectedCoach) return
    setIsLoading(true)
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_INITIATE_CHECKOUT)
    try {
      const checkoutUrl = await getCoachingStripeCheckoutUrl(selectedCoach.id) // must be profile id
      if (!checkoutUrl) {
        toast.error("Subscription already exists")
      } else {
        window.location.assign(checkoutUrl)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FlexBox
      direction="column"
      justify="center"
      align="center"
      gap={SPACING.space0}
      style={{
        width: "min(100%, 400px)",
        opacity: subscriptionPackages ? 1 : 0,
        background: "#F9FAFC",
        border: `2px solid ${JoonUIColor.text.primaryAccent}`,
        borderRadius: "16px",
        marginBottom: SPACING.space4,
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
        transition: "opacity .3s ease-in-out",
      }}
    >
      <FlexBox
        justify="center"
        align="center"
        gap={SPACING.space1}
        style={{
          padding: SPACING.space4,
        }}
      >
        <FlexBox justify="space-between" align="center" fullWidth>
          <FlexBox justify="center" align="center" gap={SPACING.space1}>
            <Typography variant="h2" color={JoonUIColor.text.primary}>
              Joon
            </Typography>
            <Typography
              variant="h2"
              color={JoonUIColor.text.primaryAccent}
              style={{ fontFamily: "Caveat" }}
            >
              {plan}
            </Typography>
            <Typography variant="h2" color={JoonUIColor.text.primary}>
              Coach
            </Typography>
          </FlexBox>
        </FlexBox>

        <FlexBox justify="center" align="center" gap={SPACING.space2}>
          <Typography
            variant="h2"
            color={JoonUIColor.background.disabled}
            style={{ textDecoration: "line-through" }}
          >
            ${fullPrice}
          </Typography>
          <Typography variant="h2" color={JoonUIColor.text.primary}>
            ${priceForToday}
          </Typography>
        </FlexBox>

        <Typography variant="bodyBold" color={JoonUIColor.text.secondary}>
          {priceLabel}
        </Typography>
      </FlexBox>

      <FlexBox
        direction="row"
        justify="space-between"
        align="center"
        gap={SPACING.space0}
        wrap={false}
        style={{
          padding: `${SPACING.space4} ${SPACING.space2}`,
          background: JoonUIColor.background.primaryNeutral,
          borderTop: `1px solid ${JoonUIColor.icon.light}`,
          borderBottom: `1px solid ${JoonUIColor.icon.light}`,
        }}
      >
        {infoCards.map(({ Icon, text }) => (
          <FlexBox
            key={Icon.name}
            direction="column"
            justify="center"
            align="center"
            gap={SPACING.space2}
          >
            <Icon size={24} color={JoonUIColor.icon.neutral} />
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.primary}
              style={{ lineHeight: "1.3", whiteSpace: "pre-line" }}
            >
              {text}
            </Typography>
          </FlexBox>
        ))}
      </FlexBox>

      <FlexBox
        justify="center"
        align="center"
        gap={SPACING.space6}
        style={{
          padding: SPACING.space2,
          paddingBottom: SPACING.space4,
        }}
      >
        <FlexBox direction="column" align="flex-start" gap={SPACING.space0}>
          <FlexBox
            direction="row"
            justify="flex-start"
            align="center"
            gap={isMobile ? SPACING.space1 : SPACING.space2}
            wrap={false}
            style={{ padding: SPACING.space2 }}
          >
            <FlexBox
              justify="center"
              align="center"
              style={{
                minWidth: isMobile ? "12px" : "16px",
                maxWidth: isMobile ? "12px" : "16px",
              }}
            >
              <WandMagicSparklesIcon
                size={isMobile ? 12 : 16}
                color={JoonUIColor.icon.neutral}
              />
            </FlexBox>
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.primary}
              style={{ fontWeight: "700", textAlign: "left" }}
            >
              What you'll get:
            </Typography>
          </FlexBox>

          <FlexBox
            direction="row"
            justify="flex-start"
            align="center"
            gap={isMobile ? SPACING.space1 : SPACING.space2}
            wrap={false}
            style={{ padding: SPACING.space2 }}
          >
            <FlexBox
              justify="center"
              align="center"
              style={{
                minWidth: isMobile ? "12px" : "16px",
                maxWidth: isMobile ? "12px" : "16px",
              }}
            >
              <ChatIcon
                size={isMobile ? 12 : 16}
                color={JoonUIColor.icon.accent}
              />
            </FlexBox>
            <Typography
              variant={isMobile ? "bodyXSmall" : "bodySmall"}
              color={JoonUIColor.text.primary}
              style={{ fontWeight: "700", textAlign: "left" }}
            >
              Anytime direct messaging with your coach
            </Typography>
          </FlexBox>

          <FlexBox
            direction="row"
            justify="flex-start"
            align="center"
            gap={isMobile ? SPACING.space1 : SPACING.space2}
            wrap={false}
            style={{
              padding: SPACING.space2,
              paddingRight: SPACING.space0,
              borderRadius: SPACING.space2,
            }}
          >
            <FlexBox
              justify="center"
              align="center"
              style={{
                minWidth: isMobile ? "12px" : "16px",
                maxWidth: isMobile ? "12px" : "16px",
              }}
            >
              <VideoIcon
                size={isMobile ? 12 : 16}
                color={JoonUIColor.icon.accent}
              />
            </FlexBox>
            <Typography
              variant={isMobile ? "bodyXSmall" : "bodySmall"}
              color={JoonUIColor.text.primary}
              style={{ fontWeight: "700", textAlign: "left" }}
            >
              {frequency}
            </Typography>
          </FlexBox>
        </FlexBox>

        <div
          style={{ width: "100%" }}
          onPointerDown={(e) => {
            e.currentTarget.dataset.pressed = "true"
            e.currentTarget.dataset.pressX = e.clientX.toString()
            e.currentTarget.dataset.pressY = e.clientY.toString()
          }}
          onPointerUp={async (e) => {
            if (e.currentTarget.dataset.pressed === "true") {
              const startX = Number(e.currentTarget.dataset.pressX || 0)
              const startY = Number(e.currentTarget.dataset.pressY || 0)
              const deltaX = Math.abs(e.clientX - startX)
              const deltaY = Math.abs(e.clientY - startY)
              if (deltaX < 10 && deltaY < 10) onContinueClick()
            }
            e.currentTarget.dataset.pressed = "false"
          }}
          onPointerLeave={(e) => {
            e.currentTarget.dataset.pressed = "false"
          }}
        >
          <TextArrowButton
            text={`Get started with ${selectedCoach?.user.name || "your coach"}`}
            isLoading={isLoading}
            onClick={() => {}}
            style={{
              position: "relative",
              width: "100%",
              cursor: "pointer",
            }}
          />
        </div>
      </FlexBox>
    </FlexBox>
  )
}

export default PlanCarouselCard
