import { Modal, Button } from "@joonapp/web-shared"
import { useState } from "react"
import { isChrome, isMobile, isAndroid } from "react-device-detect"
import { create } from "zustand"

import { useAddToHomescreenModal } from "./AddToHomescreenModal"
import { IOSDownloadSection } from "./IOSModal"
import doterGroup2 from "../../images/doter-group-2.png"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { localStorageItems } from "../../util/storage"

interface SubModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useSubModal = create<SubModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

const SubscriptionModal = () => {
  const { isOpen, onClose } = useSubModal()
  const [showiOSView, setShowiOSView] = useState(false)
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()

  const showAddToHomescreen = isChrome && isMobile && isAndroid

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon animate>
      <div className="subscription-modal">
        {showiOSView ? (
          <IOSDownloadSection />
        ) : (
          <>
            <img src={doterGroup2} alt="" style={{ width: "100%" }} />
            <div className="subscription-modal__content">
              <div
                style={{
                  fontSize: "30px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                Your trial is active!
              </div>
              <div
                style={{
                  fontSize: "18px",
                  lineHeight: "30px",
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                To make the most of your trial, we recommend you download our
                iOS app if you have an iPhone.
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                  marginTop: "25px",
                }}
              >
                <Button
                  style={{
                    width: "250px",
                    padding: "0 20px",
                  }}
                  onClick={() => {
                    trackAnalyticEvent(ANALYTIC_EVENTS.IOS_DOWNLOAD_PROMPT, {
                      action: "download",
                      subscribed: true,
                    })
                    setShowiOSView(true)
                  }}
                  text="Download iOS App"
                />

                <Button
                  buttonType="secondary"
                  style={{ width: "250px" }}
                  onClick={() => {
                    trackAnalyticEvent(ANALYTIC_EVENTS.IOS_DOWNLOAD_PROMPT, {
                      action: "no_iphone",
                      subscribed: true,
                    })

                    onClose()
                    if (showAddToHomescreen) {
                      openAddToHomescreenModal()
                    } else {
                      localStorage.removeItem(
                        localStorageItems.showDownloadAppBanner
                      )
                    }
                  }}
                  text="I don't have an iPhone"
                />
                <button
                  className="notnow-button"
                  onClick={() => {
                    trackAnalyticEvent(ANALYTIC_EVENTS.IOS_DOWNLOAD_PROMPT, {
                      action: "not_now",
                      subscribed: true,
                    })
                    onClose()
                  }}
                >
                  Not now
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default SubscriptionModal
