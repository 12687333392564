import { useEffect, useMemo } from "react"
import { create } from "zustand"

import ToggleHeader from "../../../components/toggleHeader/ToggleHeader"
import { useFamilyQuery } from "../../../networking/queries"
import { FamilyQuerySelect } from "../../../types"
import { getChildImage } from "../../../util/util"

interface RewardsChildToggleHeaderStore {
  selectedChildId: number | null
  setSelectedChildId: (childId: number | null) => void
}

export const useRewardsChildToggleHeaderStore =
  create<RewardsChildToggleHeaderStore>((set) => ({
    selectedChildId: null,
    setSelectedChildId: (childId) => set({ selectedChildId: childId }),
  }))

const RewardsChildToggleHeader = () => {
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const { selectedChildId, setSelectedChildId } =
    useRewardsChildToggleHeaderStore()

  const childOptions = useMemo(
    () =>
      children?.map((child) => ({
        label: child.user.name,
        value: child.user.id,
        imageUrl: getChildImage(child.user),
      })) || [],
    [children]
  )

  const goToNextChild = () => {
    const currentIndex = childOptions.findIndex(
      (option) => option.value === selectedChildId
    )
    const nextIndex = (currentIndex + 1) % childOptions.length
    setSelectedChildId(childOptions[nextIndex].value || null)
  }

  const goToPreviousChild = () => {
    const currentIndex = childOptions.findIndex(
      (option) => option.value === selectedChildId
    )
    const previousIndex =
      (currentIndex - 1 + childOptions.length) % childOptions.length
    setSelectedChildId(childOptions[previousIndex].value || null)
  }

  useEffect(() => {
    if (selectedChildId !== null || childOptions.length === 0) return
    setSelectedChildId(childOptions[0].value || null)
  }, [childOptions, selectedChildId, setSelectedChildId])

  if (childOptions.length === 0) return null

  return (
    <ToggleHeader
      options={childOptions}
      selectedOption={
        childOptions.find((option) => option.value === selectedChildId) || {
          label: "Loading...",
          value: null,
          imageUrl: null,
        }
      }
      onClickNext={goToNextChild}
      onClickPrevious={goToPreviousChild}
    />
  )
}

export default RewardsChildToggleHeader
