import { CalloutBox, Typography } from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import { useCheckoutRedirect } from "./paywall/useCheckoutRedirect"
import PageLoader from "../components/loading/PageLoader"
import useDocumentTitle from "../hooks/useDocumentTitle"
import { useFamilyQuery } from "../networking/queries"
import { AlertInterface } from "../types"
import { requireAuth } from "../util/auth"

function CheckoutPage() {
  useDocumentTitle("Joon - Subscription")
  const { data: family } = useFamilyQuery()
  const [formAlert, setFormAlert] = useState<AlertInterface>()
  const { redirectToFamilyCheckout } = useCheckoutRedirect()

  useEffect(() => {
    if (!family) return

    redirectToFamilyCheckout().catch(() =>
      setFormAlert({
        type: "error",
        message: "Failed to redirect to checkout.",
      })
    )
  }, [family, redirectToFamilyCheckout])

  return (
    <PageLoader>
      {formAlert && (
        <div style={{ maxWidth: "500px" }}>
          <CalloutBox type="error" fullWidth>
            <Typography variant="bodySmall">{formAlert.message}</Typography>
          </CalloutBox>
        </div>
      )}
    </PageLoader>
  )
}

const CheckoutPageWithAuth = requireAuth(CheckoutPage)
export default CheckoutPageWithAuth
