import { CoachProfile, FlexBox, JoonUIColor } from "@joonapp/web-shared"
import { useEffect } from "react"

import CoachInfoModal from "./CoachInfoModal"
import CoachSB from "./CoachSB"
import CoachSBHeader from "./CoachSBHeader"
import { CoachTab } from "./CoachTabNav"
import SBStyles from "./SBStyles"
import SessionsTab from "./sessions/SessionsTab"
import PageLoader from "../../components/loading/PageLoader"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import { useCareTeamQuery, useFamilyQuery } from "../../networking/queries"
import { FamilyQuerySelect } from "../../types"
import { useRouter } from "../../util/router"
import useCareTeam from "../care/useCareTeam"

const CoachPage = () => {
  useDocumentTitle("Joon - Care")
  const { isLoading } = useCareTeamQuery()
  const { isLoading: isLoadingChildren } = useFamilyQuery(
    FamilyQuerySelect.CHILDREN
  )
  const { careTeam, selectedProfile } = useCareTeam()
  const router = useRouter()
  const coachTab = router.query.coachTab as CoachTab | undefined
  const careTeamUserId = router.query.careTeamUserId as string | undefined

  useEffect(() => {
    if (coachTab === undefined) {
      router.push(`/coach/${careTeamUserId}/${CoachTab.MESSAGES}`)
    }
  }, [coachTab, careTeamUserId, router])

  if (isLoading || !selectedProfile) return <></>

  return (
    <PageWrapper style={{ paddingBottom: "0px" }}>
      <PageContentWrapper
        style={{ background: JoonUIColor.background.xlightGray }}
      >
        <CoachSBHeader
          selectedProfile={selectedProfile as CoachProfile}
          careTeamLength={careTeam?.length || 0}
        />
        {isLoadingChildren && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: JoonUIColor.background.primaryNeutral,
            }}
          >
            <PageLoader />
          </div>
        )}
        <FlexBox
          direction="column"
          style={{
            width: `min(100%, 600px)`,
            margin: "0 auto",
            borderLeft: `1px solid ${JoonUIColor.border.default}`,
            borderRight: `1px solid ${JoonUIColor.border.default}`,
            height: "calc(100vh - 100px)",
            overflowY: "auto",
            background: JoonUIColor.background.primaryNeutral,
          }}
        >
          {coachTab === CoachTab.SESSIONS && <SessionsTab />}
          {coachTab === CoachTab.MESSAGES && <CoachSB />}
        </FlexBox>
      </PageContentWrapper>
      <CoachInfoModal />
      <SBStyles />
    </PageWrapper>
  )
}

export default CoachPage
