import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CoachProfile,
  FlexBox,
  JoonUIColor,
  SPACING,
} from "@joonapp/web-shared"
import { useEffect, useRef } from "react"
import { Navigation, Pagination, Scrollbar } from "swiper/modules"
import { A11y } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { Swiper as SwiperType } from "swiper/types"

import RecommendedCoachCard from "./RecommendedCoachCard"
import useMediaQuery from "../../../../hooks/useMediaQuery"
import useMobile from "../../../../hooks/useMobile"
import { useCoachingStore } from "../../useCoachingStore"

export function RecommendedCoachCarousel({
  recommendedCoaches,
}: {
  recommendedCoaches: CoachProfile[]
}) {
  const {
    selectedCoach,
    setSelectedCoach,
    selectedCoachIndex,
    setSelectedCoachIndex,
  } = useCoachingStore()
  const swiperRef = useRef<SwiperType | null>(null)
  const isMobile = useMobile()
  const isSmallMobile = useMediaQuery("(max-width: 350px)")
  const showLessSlides = useMediaQuery("(max-width: 450px)")
  const handleSlideChange = () => {
    if (swiperRef.current) {
      const newIndex = swiperRef.current.realIndex
      setSelectedCoach(recommendedCoaches[newIndex])
      setSelectedCoachIndex(newIndex)
    }
  }

  useEffect(() => {
    if (selectedCoach) {
      const newIndex = recommendedCoaches.findIndex(
        (coach) => coach.user.id === selectedCoach.user.id
      )
      setSelectedCoachIndex(newIndex)
      swiperRef.current?.slideTo(newIndex)
    }
  }, [selectedCoach, recommendedCoaches, setSelectedCoachIndex])

  const PrevButton = ({ swiperRef }: { swiperRef: any }) => (
    <button
      style={{
        position: "absolute",
        left: isMobile ? "-10px" : "20px",
        padding: "0 8px",
        width: "40px",
        height: "100%",
        background: "transparent",
        zIndex: 10,
        opacity: selectedCoachIndex === 0 ? 0 : 1,
        pointerEvents: selectedCoachIndex === 0 ? "none" : "auto",
        transition: "opacity .3s ease",
      }}
      onClick={() => swiperRef.current.slidePrev()}
    >
      <div
        style={{
          border: `1px solid ${JoonUIColor.border.accent}`,
          maxWidth: "24px",
          maxHeight: "100%",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: JoonUIColor.background.accent,
          padding: 0,
          paddingRight: "2px",
        }}
      >
        <ChevronLeftIcon size={20} color={JoonUIColor.text.inverted} />
      </div>
    </button>
  )

  const NextButton = ({ swiperRef }: { swiperRef: any }) => (
    <button
      style={{
        position: "absolute",
        right: isMobile ? "-10px" : "20px",
        padding: "0 8px",
        width: "40px",
        height: "100%",
        background: "transparent",
        zIndex: 10,
        opacity: selectedCoachIndex === recommendedCoaches.length - 1 ? 0 : 1,
        pointerEvents:
          selectedCoachIndex === recommendedCoaches.length - 1
            ? "none"
            : "auto",
        transition: "opacity .3s ease",
      }}
      onClick={() => swiperRef.current.slideNext()}
    >
      <div
        style={{
          border: `1px solid ${JoonUIColor.border.accent}`,
          maxWidth: "24px",
          maxHeight: "100%",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: JoonUIColor.background.accent,
          padding: 0,
          paddingLeft: "2px",
        }}
      >
        <ChevronRightIcon size={20} color={JoonUIColor.text.inverted} />
      </div>
    </button>
  )

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="center"
      style={{
        position: "relative",
        width: "90vw",
        maxWidth: "450px",
        marginBottom: isSmallMobile ? "68px" : "100px",
      }}
    >
      <PrevButton swiperRef={swiperRef} />
      <style>{`.swiper-pagination { bottom: ${isSmallMobile ? "-80px" : "-112px"} !important; }`}</style>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        pagination={true}
        slidesPerView={showLessSlides ? 1.5 : 1.7}
        centeredSlides={true}
        onInit={(swiper: any) => (swiperRef.current = swiper)}
        observer={true}
        observeParents={true}
        onSlideChange={handleSlideChange}
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          overflowY: "visible",
        }}
      >
        {recommendedCoaches.map((coach, i) => (
          <SwiperSlide
            key={i}
            style={{
              display: "flex",
              borderRadius: SPACING.space3,
              overflow: "visible",
              border: "none",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <RecommendedCoachCard coachCardContent={coach} />
          </SwiperSlide>
        ))}
      </Swiper>
      <NextButton swiperRef={swiperRef} />
    </FlexBox>
  )
}
